import React,{useState} from 'react';
import {Row,Col,Input} from 'reactstrap';
import config from "react-global-configuration";
import '../../../../config';
import {connect} from 'react-redux';
import axios from 'axios';
const authApi = config.get('apiDomain');
function AddEditBatch(props){
 const [batchnumber,updatebatchnumber]=useState(props.batchdetails === "" ? "":props.batchdetails.batchno);
 const [batchdescription,updatebatchdescription]=useState(props.batchdetails === "" ? "":props.batchdetails.description);
 const [id,updateid]=useState(props.batchdetails === "" ? "":props.batchdetails.id);
 const [batchnumbervalidation,validatebatchnumber]=useState("1px solid #dee2e6");
 const [batchdescriptionvalidation,validatebatchdescription]=useState("1px solid #dee2e6");
const changeBatchNumber=(event)=>{
    updatebatchnumber(event.target.value);
}

const changeDescription=(event)=>{
    updatebatchdescription(event.target.value);
}

const StoreChanges=async()=>{
   if(batchnumber !==""){
        if(batchdescription !== ""){
            storeAndUpdateData();
        }
        else{
            validatebatchnumber("1px solid #dee2e6");
            validatebatchdescription("1px solid #fb6340");
        }
   }
   else{
    validatebatchnumber('1px solid #fb6340');
    validatebatchdescription("1px solid #dee2e6");
   }
}

const storeAndUpdateData=async()=>{
    if(id === ""){
        axios({
            method: "post",
            url: `${authApi}batch/`,
            headers: { 'content-type': "application/json",
            'Authorization': `token ${props.token}`, },
          data: {
            batchno:batchnumber,
            description:batchdescription,
            }
        })
        .then(res=>{
            if(res.status===201){
                updateid(id);
                props.referesh();
                props.closebtn();
            }
        })
         .catch(err=>console.log("Task move to readytobill error", err.response.request.response))
    }
    else{
        axios({
            method: "put",
            url: `${authApi}batch/${id}/`,
            headers: { 'content-type': "application/json",
            'Authorization': `token ${props.token}`, },
          data: {
            batchno:batchnumber,
            description:batchdescription,
            }
        })
        .then(res=>{
            if(res.status===200){
                updateid(id);
                props.referesh();
                props.closebtn();
            }
        })
         .catch(err=>console.log("Task move to readytobill error", err.response.request.response))
    }
}


return(
    <React.Fragment>
         <button className="x" onClick={props.closebtn}>
            x
            </button>
            <div className="header"> 
             Add/Edit Batch
            </div>
            <div className="Scroll-Content">
            <Row>
                <Col md="6">
                    <label style={{color:"black",paddingLeft:10}}>Batch Number</label>
                    <Input 
                    type="text"
                    className="form-control-alternative"
                    style={{border:batchnumbervalidation}}
                    value={batchnumber}
                    onChange={changeBatchNumber}
                    />
                    {batchnumbervalidation==='1px solid #fb6340'?<h5 style={{color:"#fb6340"}}>Please enter batchno</h5>:null}
                </Col>
                <Col md="6">
                    <label style={{color:"black",paddingLeft:10}}>Description</label>
                    <Input 
                    type="text"
                    className="form-control-alternative"
                    style={{border:batchdescriptionvalidation}}
                    value={batchdescription}
                    onChange={changeDescription}
                    />
                    {batchdescriptionvalidation==='1px solid #fb6340'?<h5 style={{color:"#fb6340"}}>Please enter description</h5>:null}
                </Col>
            </Row>
            <br />
            <Row>
                <Col md="10">
                </Col>
                <Col md="1">
                <button type="button" class="btn btn-dark" onClick={StoreChanges}>Submit</button>
                </Col>
                <Col md="1">
                </Col>
            </Row>
            </div>
    </React.Fragment>
)
}

const mapStateToProps = state => {
    return {
      token:state.token, 
    }
  }
  
  export default connect(mapStateToProps, null)(AddEditBatch);