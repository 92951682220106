import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Invoice from "./Invoice";
import { Row, Card } from "reactstrap";
import "../../../../config";
import config from "react-global-configuration";
import { connect } from "react-redux";
import moment from "moment";

const authApi = config.get("apiDomain");
let client = "allclient",
  totalcompanies = [],
  invoicestatus = ["Unpaid", "Partially%20Paid"],
  selectedcompany = 0;
let clientgroupId = 0;
class InvoiceAppDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceData: this.props.invoiceData,
      OpeningBalance: this.props.invoiceData.OpeningBlance,
      JsonObject: "",
      startDate: "",
      endDate: "",
      startdate: this.props.invoiceData.Startdate,
      enddate: this.props.invoiceData.Enddate,
      clientid: this.props.invoiceData.client_id,
      companyid: this.props.invoiceData.companydetails,
      clientGroup_Id: "",
      invoiceClientDet: this.props.invoiceClientData,
      loading: true,
      PresentDay: new Date(),
    };
  }

  componentDidMount() {
    this.state.companyid.forEach(
      (d) => (totalcompanies = [...totalcompanies, d.id])
    );
    this.reportFunction();

  }

  reportFunction = () => {
    invoicestatus = ["Unpaid", "Partially%20Paid", "Paid"];
    selectedcompany = totalcompanies;
    client = "allclient";
    console.log(client)
    if (this.state.clientGroup_Id === "") {
      clientgroupId = "NoClientGroupid";
    } else {
      clientgroupId = this.state.clientGroup_Id;
    }
    fetch(
      `${authApi}getDraftingandPrinting/?clientid=${this.state.clientid}&startdate=${this.state.startdate}&enddate=${this.state.enddate}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}
  `,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          JsonObject: json,
          loading: false,
        });
      });
  };

  datefunction = () => {
    const a = moment(this.props.dateStart).format("YYYY-MM-DD");
    const b = moment(this.props.dateEnd).format("YYYY-MM-DD");
    this.setState({
      startDate: a,
      endDate: b,
    });
  };

  downloadFunction = () => {
    this.setState({
      JsonObject: "",
    });
    this.GetTaskData(
      this.state.invoiceData.filter((i) => i.taskid !== null && i.taskid !== "")
    );
  };

  GetTaskData = (data) => {
    data.forEach((d) => {
      this.updateclientname(d.client);
      fetch(`${authApi}tasklistfilter/?id=${d.taskid}&isdeleted=0`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.length !== 0) {
            this.updateJsonObject(json, d);
          }
        });
    });
  };

  updateclientname = (c) => {
    this.state.invoiceClientDet.forEach((d) => {
      if (d.id === parseInt(c)) {
        this.setState({
          jsonclientname: d.engineer_name,
          jsonclientCompany: d.company_name,
          jsonclientphoneno: d.phoneno,
          jsonclientemail: d.emailid,
        });
      }
    });
  };

  updateJsonObject = (json, data) => {
    let taskList = [];
    const taskPromises = json.map((element) => this.GetTaskDetail(element));
    Promise.all(taskPromises)
      .then((result) => {
        result.forEach((element) => {
          taskList = [...taskList, element];
        });
        this.setState({
          JsonObject: [
            ...this.state.JsonObject,
            {
              id: data.id,
              invoiceno: `${data.companycode}-${data.deliverynoteid}`,
              GenerateDate: moment(this.state.PresentDay).format("DD-MM-YYYY"),
              invoicedate: moment(data.date).format("DD-MM-YYYY"),
              client: data.company_name,
              clientCompany: this.state.jsonclientCompany,
              clientname: this.state.jsonclientname,
              emailId: this.state.jsonclientemail,
              Mobilenumber: this.state.jsonclientphoneno,
              amount_received: data.amount_received,
              balancedue: data.balancedue,
              discount: data.discount,
              duedate: data.duedate,
              invoice_amount: data.invoice_amount,
              invoicestatus: data.invoicestatus,
              subtotal: data.subtotal,
              TaskDetails: taskList,
              companyname: data.companyname,
            },
          ],
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  GetTaskDetail = async (task) => {
    const dc = await this.GetDraftingCharge(task.id);
    let charge = 0;
    dc.forEach((x) => {
      charge = charge + parseInt(x.amount);
    });

    const pc = await this.GetPrintingCharge(task.id);

    const newtask = {
      ID: task.id,
      Name: task.task,
      DraftingCharge: charge,
      PrintingCharge: pc,
      DrawingCharge: dc,
    };

    return newtask;
  };

  GetDraftingCharge = (taskID) => {
    return fetch(`${authApi}invoicefilter/?tasklistrowid=${taskID}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  GetPrintingCharge = (taskID) => {
    return fetch(`${authApi}stockinvoicefilter/?tasklistrow=${taskID}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">Invoice</div>
          <Row>
            <div className=" col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <Invoice
                      GenerateData={this.state.JsonObject}
                      OpeningBalance={this.state.OpeningBalance}
                      sD={this.state.startdate}
                      eD={this.state.enddate}
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(InvoiceAppDF);
