export const googleLogin = "Google_Login";
export const userLogin = "Normal_Login";
export const refreshLogin = "Refresh_Login";
export const opencloseSideBar = "Close_SideBar";
export const Usertoken = "User_Token";
export const FilteredData = "Filter_Data";
export const ClientTransactData = "Client_TransactData";
export const TeamTaskClientname = "Teamtask_Clientname";
export const TeamTaskCompanyname = "Teamtask_Companyname";
export const TeamTaskAssignto = "Teamtask_Assignto";
export const TeamTaskPlantype = "Teamtask_Plantype";
export const TeamTaskStatus = "Teamtask_Status";
export const AllClientDetails = "AllClient_Details";
export const AllUsers = "All_Users";

const initialState = {
  name: null,
  email: null,
  username: localStorage.getItem("username"),
  profilepic: null,
  sideToggle: true,
  token: localStorage.getItem("token"),
  filterData: "",
  clientdata: "",
  clientdetails: JSON.parse(localStorage.getItem("clientdetails")),
  teamtask_client: "",
  teamtask_company: "",
  teamtask_assignto: "",
  teamtask_plantype: "",
  teamtask_status: "",
  allusers: JSON.parse(localStorage.getItem("userdetails")),
};

const Userreducers = (state = initialState, action) => {
  switch (action.type) {
    case googleLogin:
      return {
        ...state,
        name: action.detail.displayName,
        email: action.detail.email,
        profilepic: action.detail.photoURL,
      };
    case userLogin:
      return {
        ...state,
        name: action.detail.displayName,
        email: action.detail.email,
        profilepic: action.detail.photoURL,
      };
    case refreshLogin:
      return {
        ...state,
        username: action.detail.displayName,
        email: action.detail.email,
        profilepic: action.detail.photoURL,
      };
    case opencloseSideBar:
      return {
        ...state,
        sideToggle: action.detail,
      };
    case Usertoken:
      return {
        ...state,
        token: action.detail,
      };
    case FilteredData:
      return {
        ...state,
        filterData: action.datas,
      };
    case ClientTransactData:
      return {
        ...state,
        clientdata: action.data,
      };
    case TeamTaskClientname:
      return {
        ...state,
        teamtask_client: action.data,
      };
    case TeamTaskCompanyname:
      return {
        ...state,
        teamtask_company: action.data,
      };
    case TeamTaskAssignto:
      return {
        ...state,
        teamtask_assignto: action.data,
      };
    case TeamTaskPlantype:
      return {
        ...state,
        teamtask_plantype: action.data,
      };
    case TeamTaskStatus:
      return {
        ...state,
        teamtask_status: action.data,
      };
    case AllClientDetails:
      return {
        ...state,
        clientdetails: action.data,
      };
    case AllUsers:
      return {
        ...state,
        allusers: action.detail,
      };

    default:
      return state;
  }
};

export default Userreducers;
