import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Input,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import axios from "axios";
import TableContainer from "../../Filters/TableContainer";
import moment from "moment";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import "../../Teamtask/Todotask.css";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import ReactTooltip from "react-tooltip";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Header from "components/Headers/SimpleHeader";

const authApi = config.get("apiDomain");
let sdToday = new Date();
let Date1 = new Date(sdToday.getFullYear(), sdToday.getMonth(), 1);
let Date2 = new Date(sdToday.getFullYear(), sdToday.getMonth() + 1, 0);
let totalreceived = 0,
  openingbalance = 0,
  closingbalance = 0,
  totalinvoiced = 0;
let max_data = 0,
  max_id = 0;

function Cashbook(props) {
  const [data, setData] = useState([]);
  const [Filterdata, setFilterData] = useState([]);
  const [companyname, setCompanyname] = useState("");
  const [accountname, setAccountname] = useState("");
  const [clientname, setClientname] = useState("");
  const [CompanyFilterdata, setCompanyFilterData] = useState([]);
  const [ClientFilterdata, setClientFilterData] = useState([]);
  const [AccountFilterdata, setAccountFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [dateRangeSt, setDateRangeSt] = useState(Date1);
  const [dateRangeEd, setDateRangeEd] = useState(Date2);

  useEffect(
    () => {
  
      const doFetch = async () => {
        getTaxyear();
        setChecked(true)
        const response = await axios({
          method: "post",
          url: `${authApi}sptransaction2/`,

          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
          data: {
            startdate: moment(Date1).format("YYYY-MM-DD"),
            enddate: moment(Date2).format("YYYY-MM-DD"),
          },
        });
       
        const body = await response.data;
      
        const cashdata = body.filter((i) => {
          return (
            i.transactiontype === "received" || i.transactiontype === "invoice"
          );
        });
        CompanyFilter(cashdata);
        Clientfilter(cashdata);
        AccountFilter(cashdata);
        defaultrangepicker(cashdata);
        setData(cashdata);
      
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  const getTaxyear = async () => {
    const response = await fetch(`${authApi}taxyear/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
    });

    const Taxdate = await response.json();
    Taxdate.forEach((currentyear) => {
      if (currentyear.id > max_id) {
        max_id = currentyear.id;
        max_data = currentyear;
      }
    });
  };

  function defaultrangepicker(body) {
    let PresentDay = new Date();
    let firstDay = new Date(PresentDay.getFullYear(), PresentDay.getMonth(), 1);
    let lastDay = new Date(
      PresentDay.getFullYear(),
      PresentDay.getMonth() + 1,
      0
    );
    setDateRangeSt(firstDay);
    setDateRangeEd(lastDay);
    let D1 = moment(firstDay).format("YYYY-MM-DD");
    let D2 = moment(lastDay).format("YYYY-MM-DD");
    let defaultVal = body.filter(function (d) {
      return d.date >= D1 && d.date <= D2;
    });
    if (defaultVal.length === 0) {
      setFilterData(defaultVal);
      totalreceived = 0;
      totalinvoiced = 0;
      openingbalance = 0;
      closingbalance = 0;
      setLoading(false);
    } else {
      CalculateDateTime(defaultVal);
      CalculateOpeningbalance(body, D1, accountname, clientname, companyname);
      CalculateClosingbalance(body, D2, accountname, clientname, companyname);
      callback(defaultVal);
    }
  }

  function CalculateDateTime(body) {
    body.forEach((e) => {
      e.time = calculateTime(e);
    });
  }

  function calculateTime(t) {
    let num = moment(t.time, "h:mm:ss A").format("HH:mm:ss A");
    return num;
  }

  function callback(body) {
    let tr = body
      .filter((f) => f.transactiontype === "received")
      .reduce(function (i, record) {
        return i + parseInt(record.Amount, 0);
      }, 0);
    let ti = body
      .filter((f) => f.transactiontype === "invoice")
      .reduce(function (i, record) {
        return i + parseInt(record.Amount, 0);
      }, 0);
    totalreceived = tr.toLocaleString(navigator.language);
    totalinvoiced = ti.toLocaleString(navigator.language);
    const data = body.filter((f) => f.transactiontype === "received");
    setFilterData(data.reverse());
    setLoading(false);
  }

  function handleDateRangeCallback(event, picker) {
    Date1 = picker.startDate._d.toISOString();
    Date2 = picker.endDate._d.toISOString();
    setDateRangeSt(picker.startDate);
    setDateRangeEd(picker.endDate);
  }

 
  function CompanyFilter(fil) {
    const a = [...new Map(fil.map((o) => [o.companyid, o.companyid])).values()];
    setCompanyFilterData(a);
  }

  function AccountFilter(fil) {
    const a = [
      ...new Map(fil.map((o) => [o.Paymentmode, o.Paymentmode])).values(),
    ];
    const b = a.filter((x) => x !== null && x !== "");
    setAccountFilterData(b);
  }

  function Clientfilter(fil) {
    const a = [
      ...new Map(fil.map((o) => [o.ClientName, o.ClientName])).values(),
    ];
    setClientFilterData(a);
  }

  function selectCompany(event) {
    setCompanyname(event.target.value);
  }

  function selectAccounts(event) {
    setAccountname(event.target.value);
  }

  function selectClient(event) {
    setClientname(event.target.value);
  }

  async function handleSearch() {
    const response = await axios({
      method: "post",
      url: `${authApi}sptransaction2/`,

      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
      data: {
        startdate: moment(dateRangeSt).format("YYYY-MM-DD"),
        enddate: moment(dateRangeEd).format("YYYY-MM-DD"),
      },
    });
    const body = await response.data;
    const cashdata = body.filter((i) => {
      return (
        i.transactiontype === "received" || i.transactiontype === "invoice"
      );
    });
    CompanyFilter(cashdata);
    Clientfilter(cashdata);
    AccountFilter(cashdata);
    setData(cashdata);
    let a_sd = moment(dateRangeSt).format("YYYY-MM-DD");
    let b_ed = moment(dateRangeEd).format("YYYY-MM-DD");
    let options = [];
    let data1 = cashdata.filter(function (d) {
      return d.date >= a_sd && d.date <= b_ed;
    });
    const data2 = data1.filter(
      (r) =>
        r.companyid
          .toString()
          .toLowerCase()
          .indexOf(companyname.toLowerCase()) > -1
    );
    const data3 = data2.filter(
      (r) =>
        (r.Paymentmode || "").toLowerCase().indexOf(accountname.toLowerCase()) >
        -1
    );
    if (clientname === "") {
      options = data3.filter(
        (r) =>
          r.ClientName.toString()
            .toLowerCase()
            .indexOf(clientname.toLowerCase()) > -1
      );
    } else {
      const searchPattern = new RegExp(`(?=.*${clientname})`, "i");
      options = data3.filter((option) => {
        if (option.ClientName === clientname) {
          return option.ClientName.match(searchPattern);
        }
      });
    }
    if (options.length === 0) {
      alert("No transactions available for the selected filter.!");
      totalreceived = 0;
      totalinvoiced = 0;
      openingbalance = 0;
      closingbalance = 0;
      setFilterData([]);
      setLoading(false);
    } else {
      CalculateDateTime(options);
      CalculateOpeningbalance(data, a_sd, accountname, clientname, companyname);
      CalculateClosingbalance(data, b_ed, accountname, clientname, companyname);
      callback(options);
    }
  }

  function CalculateOpeningbalance(
    data,
    startDate,
    accountname,
    clientname,
    companyname
  ) {
    if (accountname !== "" || clientname !== "" || companyname !== "") {
      let data1 = data.filter(function (d) {
        return d.date >= max_data.startdate && d.date < startDate;
      });
      const cols1 = data1.filter(
        (r) =>
          r.ClientName.toString()
            .toLowerCase()
            .indexOf(clientname.toLowerCase()) > -1
      );
      const cols2 = cols1.filter(
        (r) =>
          (r.Paymentmode || "")
            .toLowerCase()
            .indexOf(accountname.toLowerCase()) > -1
      );
      const cols3 = cols2.filter(
        (r) =>
          r.companyid
            .toString()
            .toLowerCase()
            .indexOf(companyname.toLowerCase()) > -1
      );
      let ob = cols3.reduce(function (i, record) {
        return i + parseInt(record.Amount, 0);
      }, 0);
      openingbalance = ob.toLocaleString(navigator.language);
    } else {
      let data1 = data.filter(function (d) {
        return d.date >= max_data.startdate && d.date < startDate;
      });
      let ob = data1.reduce(function (i, record) {
        return i + parseInt(record.Amount, 0);
      }, 0);
      openingbalance = ob.toLocaleString(navigator.language);
    }
  }

  function CalculateClosingbalance(
    data,
    endDate,
    accountname,
    clientname,
    companyname
  ) {
    if (accountname !== "" || clientname !== "" || companyname !== "") {
      let data1 = data.filter(function (d) {
        return d.date >= max_data.startdate && d.date <= endDate;
      });
      const cols1 = data1.filter(
        (r) =>
          r.ClientName.toString()
            .toLowerCase()
            .indexOf(clientname.toLowerCase()) > -1
      );
      const cols2 = cols1.filter(
        (r) =>
          (r.Paymentmode || "")
            .toLowerCase()
            .indexOf(accountname.toLowerCase()) > -1
      );
      const cols3 = cols2.filter(
        (r) =>
          r.companyid
            .toString()
            .toLowerCase()
            .indexOf(companyname.toLowerCase()) > -1
      );
      let cb = cols3.reduce(function (i, record) {
        return i + parseInt(record.Amount, 0);
      }, 0);
      closingbalance = cb.toLocaleString(navigator.language);
    } else {
      let data1 = data.filter(function (d) {
        return d.date >= max_data.startdate && d.date <= endDate;
      });
      let cb = data1.reduce(function (i, record) {
        return i + parseInt(record.Amount, 0);
      }, 0);
      closingbalance = cb.toLocaleString(navigator.language);
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Time",
        accessor: "time",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Paymentmode",
        accessor: "Paymentmode",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Client",
        accessor: "ClientName",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Company",
        accessor: "companyid",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Task",
        accessor: "Task",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "SalesorderId",
        accessor: "Invoice",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Type",
        accessor: "transactiontype",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Credit",
        accessor: "Credit",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Reference No.",
        accessor: "ReferenceNo",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--7" fluid>
        <div
          role="tabpanel"
          className="tab-pane"
          style={{ marginTop: -110, marginLeft: -45 }}
        >
          <div className="tab-para">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="single-tab-select-box">
                  <h5 style={{ color: "black", fontWeight: 700, fontSize: 13 }}>
                    Company
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                        backgroundColor: "white",
                        border: "none",
                      }}
                      type="select"
                      className="form-control-alternative"
                      value={companyname}
                      onChange={selectCompany}
                    >
                      <option value="">All Companies</option>
                      {CompanyFilterdata.sort((a, b) => a.localeCompare(b)).map(
                        (item, key) => (
                          <option key={key} value={item}>
                            {item}
                          </option>
                        )
                      )}
                    </Input>
                  </h5>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="single-tab-select-box">
                  <h5 style={{ color: "black", fontWeight: 700, fontSize: 13 }}>
                    Paymentmode
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                        backgroundColor: "white",
                        border: "none",
                      }}
                      type="select"
                      className="form-control-alternative"
                      value={accountname}
                      onChange={selectAccounts}
                    >
                      <option value="">All Paymentmodes</option>
                      {AccountFilterdata.sort((a, b) => a.localeCompare(b)).map(
                        (item, key) => (
                          <option key={key} value={item}>
                           
                            {item}
                          </option>
                        )
                      )}
                    </Input>
                  </h5>
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="single-tab-select-box">
                  <h5 style={{ color: "black", fontWeight: 700, fontSize: 13 }}>
                   
                    Clients
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                        backgroundColor: "white",
                        border: "none",
                      }}
                      type="select"
                      className="form-control-alternative"
                      value={clientname}
                      onChange={selectClient}
                    >
                      <option value="">All Clients</option>
                      {ClientFilterdata.sort((a, b) => a.localeCompare(b)).map(
                        (item, key) => (
                          <option key={key} value={item}>
                            
                            {item}
                          </option>
                        )
                      )}
                    </Input>
                  </h5>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="single-tab-select-box">
                  <h5 style={{ color: "black", fontWeight: 700, fontSize: 13 }}>
                    Date
                    <DateRangePicker
                      autoUpdateInput={false}
                      onApply={handleDateRangeCallback}
                      initialSettings={{
                        locale: {
                          format: 'DD/MM/YYYY'
                        }
                      }}
                    >
                      <button
                        className="form-control-alternative"
                        style={{
                          height: "35px",
                          width: "100%",
                          overflow: "hidden",
                          padding: "2px 2px 2px",
                          backgroundColor: "white",
                          border: "none",
                        }}
                      >
                        <i
                          className="fa fa-calendar-o"
                          style={{ paddingRight: 15 }}
                          aria-hidden="true"
                        ></i>
                        {moment(Date1).format("DD/MM/YYYY")} -
                        {moment(Date2).format("DD/MM/YYYY")}
                      </button>
                    </DateRangePicker>
                  </h5>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="single-tab-select-box">
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ width: "35%", height: 35, marginTop: 17 }}
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          role="tabpanel"
          className="tab-pane"
          style={{ marginTop: -50, marginLeft: -50 }}
        >
          <div className="tab-para">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="single-tab-select-box">
                  <Card className="card-stats">
                    <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Opening Balance
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {openingbalance}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-balance-scale-right"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="single-tab-select-box">
                  <Card className="card-stats">
                    <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Invoiced
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalinvoiced}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-balance-scale-right"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="single-tab-select-box">
                  <Card className="card-stats">
                    <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Received
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {totalreceived}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="fa fa-arrow-down" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="single-tab-select-box">
                  <Card className="card-stats">
                    <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Closing Balance
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {closingbalance}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-balance-scale-left"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading === true ? (
          <div>
            
            <ConPreLoader />
          </div>
        ) : (
          <Row>
            <div className="col" style={{ marginTop: -45, marginLeft: -7 }}>
              <ReactTooltip place="top" type="dark" effect="float" />
              <TableContainer
                columns={columns}
                data={Filterdata}
                checkedToggle={checked}
              />
            </div>
          </Row>
        )}
        <br />
        <br />
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(Cashbook);
