import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTask from "./InvoiceFolder/InvoiceTaskName";

const borderColor = "#bff0fd";
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "90%",
    textAlign: "right",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingRight: 8,
    paddingTop: 4,
  },
  total: {
    width: "10%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const InvoiceTable = ({ manageData}) => {
  const detailsInvoice = manageData
    .filter((i) => i.invoicestatus !== "Paid")
    .map((d) => (
      <InvoiceTask
        task={d.TaskDetails}
        invoiceid={d.invoiceno}
        invoicedate={d.invoicedate}
        discount={d.discount}
        invoice_amount={d.invoice_amount}
      />
    ));

  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      {detailsInvoice}
    </View>
  );
};

export default InvoiceTable;
