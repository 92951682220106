import React from 'react';
import {connect} from 'react-redux';
import config from "react-global-configuration";
import '../../../../config';
import ReactTooltip from "react-tooltip";
import axios from 'axios';
import {Row,Col,Card,Table,CardHeader} from "reactstrap";
const authApi = config.get('apiDomain');
class RequestedStock extends React.Component{
    constructor(props){
        super(props);
        this.state={
           Requeststocks:[],
           Userdetails:[],
        }
    }
async componentDidMount(){
         await this.getUserDetails();
        fetch(`${authApi}purchaserequestfilter/?status=Requested&isdeleted=0`, {
            method: 'GET',
            headers: {
            'content-type': "application/json",
            'Authorization': `token ${this.props.token}`,
            }
            })
            .then(res=>res.json())
            .then(json=>{
              if(this.props.Requestedstocks.length !== 0){
                this.props.Requestedstocks.forEach(request => {
                  let data=json.filter(item=>item.stockname !== request.itemname);
                  json=data;
                });
              }
              this.setState({
                Requeststocks:json.reverse(),
              })
            })
}

getUserDetails=async()=>{
  const response = await fetch(`${authApi}userfilter/?isapproved=1`, {
    method: 'GET',
    headers: {
      'content-type': "application/json", 
      'Authorization': `token ${this.props.token}`,
    }
  })
  .then(res=>res.json())
  console.log(response);
  console.log(this.props.name);
  this.setState({
    Userdetails:response,
  })
}

removepurchaseRequest=(Stock)=>{
  let rowdata=this.state.Requeststocks.filter(function(item){
    return (item.id !== Stock.id); 
   }); 
   let userid=0;
   this.state.Userdetails.forEach(user => {
     if(user.id === parseInt(Stock.username)){
        userid=user.id;
     }
   });
   this.setState({
    Requeststocks:rowdata,
  })
  axios({
    method: "put",
    url: `${authApi}purchaserequest/${Stock.id}/`,
    headers: { 'content-type': "application/json",
    'Authorization': `token ${this.props.token}`, },
    data: {
        date:Stock.date,
        time: Stock.time,
        username:userid,
        stockname:Stock.stockname,
        productcode: Stock.productcode,
        quantity: Stock.quantity,
        status:"Rejected",
        description:Stock.description,
        purchaseorderid: Stock.purchaseorderid,
        isdeleted: true
    }
  })
  .then(res=>{
    if(res.status === 200){
    console.log(res);
    }
   })
   .catch(err=>{
     console.log(err);
   })
  
}

addPurchaseStock=(pruchaserequesteddata)=>{
  this.state.Requeststocks.forEach(request => {
    if(request.id===pruchaserequesteddata.id){
     request.status="Added";
    }
  });
this.props.purchasestockdetails(pruchaserequesteddata);
console.log(this.state.Requeststocks);
}


render(){
        return(
            <React.Fragment>
              <Row style={{ marginBottom: -35 }}>
              <Col md="6">
                <CardHeader style={{ backgroundColor: 'transparent', padding: 10, marginTop: 20 }}>
                  <h3 className="mb-0"><i style={{ marginRight: 5 }} class="fas fa-cart-plus"></i> Purchase Requests</h3>
                </CardHeader>
             </Col>
             <Col md="5">
             </Col>
             <Col md="0.5" style={{marginTop:25}}>
              <ReactTooltip id="teamTask-Tip" place="right" type="dark" effect="solid">
                Back to EditPurchase
              </ReactTooltip>
              <button className="btn btn-primary btn-sm" style={{ width: "135%", height: 40 }} 
                data-tip data-for="teamTask-Tip" onClick={()=>this.props.backtoAddpurchase()}
              >
                Back 
              </button>
            </Col>
            </Row>
            <br/>
            <Row>
            <div className=" col"><br />
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive-sm>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">DATE</th>
                      <th className="text-center">USERNAME</th>
                      <th className="text-center">STOCKNAME</th>
                      <th className="text-center">PRODUCTCODE</th>
                      <th className="text-center">QUANTITY</th>
                      <th className="text-center">STATUS</th>
                    </tr>
                  </thead>
                 <tbody>
                     {this.state.Requeststocks.map((request)=>(
                         <tr>
                             <td>
                               <button className = "btn btn-primary btn-sm" style={{marginRight: '5px'}}>
                               Cancel
                               </button>
                               <button className = {request.status === "Requested" ? "btn btn-primary btn-sm":"btn btn-success btn-sm"} style={{marginRight: '5px'}} onClick={()=>this.addPurchaseStock(request)} disabled={request.status==="Requested" ?false:true}>
                               {request.status==="Requested" ?"AddPurchase":"Added"}
                               </button>
                             </td>
                             <td>{request.date}</td>
                             <td>{request.userid}</td>
                             <td>{request.stockname}</td>
                             <td>{request.productcode}</td>
                             <td>{request.quantity}</td>
                             <td>{request.status}</td>
                         </tr>
                     ))}
                     </tbody>
                </Table>
              </Card>
            </div>
          </Row>
          </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
      token:state.token, 
      name:state.name,
    }
  }
  export default connect(mapStateToProps, null)(RequestedStock);