import React from "react";
import {
  Input,
  Row,
  Col
} from "reactstrap";
import axios from 'axios';
import { connect } from "react-redux";
import consoleFunction from "../../../Helpers";
import config from "react-global-configuration";

import '../../../config';

const authApi = config.get('apiDomain');

class EditClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CompanyName: this.props.EditRowSend.companyname,
      isactive: this.props.EditRowSend.isactive,
      isgst: this.props.EditRowSend.isgst,
      ChangeIsdeleted: 0,
      error: null,
      clientborder: "1px solid #dee2e6",
      companyborder: "1px solid #dee2e6",
      companycodeborder: "1px solid #dee2e6",
      isButtonDisabled: false,
      Companycode: this.props.EditRowSend.companycode,
    }
  }


  FormValidation = () => {
    if (this.state.CompanyName !== "") {
      this.setState({
        isButtonDisabled: true
      })
      this.UpdateEditCompanydetails();
    }
    else {
      this.setState({
        companyborder: "1px solid #fb6340",
        clientborder: "1px solid #dee2e6"
      })
    }
  }

  UpdateEditCompanydetails = () => {
    axios({
      method: "put",
      url: `${authApi}company/${this.props.EditRowSend.id}/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
      data: {
        companyname: this.state.CompanyName,
        isactive: this.state.isactive,
        isgst: this.state.isgst,
        isvisible: true,
        isdeleted: false,
        companycode: this.state.Companycode,
      }
    })
      .then(res => {
        if (res.status === 200) {
          this.props.closebtn();
          this.props.callbackfunction();
        }
      })
      .catch((err) => {
        consoleFunction("Company details updation error", err)
        this.setState({ error: err.message });
      });
  }

  getCompanyname = (event) => {
    this.setState({
      CompanyName: event.target.value,
    })
  }

  deleteCompany = () => {
    if (this.state.isactive === true) {
      this.setState({
        isactive: false,
      })
    }
    else {
      this.setState({
        isactive: true,
      })
    }
  }
  deleteGst = () => {
    if (this.state.isgst === true) {
      this.setState({
        isgst: false,
      })
    }
    else {
      this.setState({
        isgst: true,
      })
    }
  }

  editCompanyCode = (event) => {
    this.setState({
      Companycode: event.target.value,
      companycodeborder: "1px solid #fb6340",
    })

  }

  render() {
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">
          Edit Company
        </div>
        <div className="Scroll-Content">
          <Row>
            <Col md="3">
              <label className="form-control-label">
                Company Name
              </label>
              <Input
                type="text"
                className="form-control-alternative"
                defaultValue={this.state.CompanyName}
                style={{ border: this.state.companyborder }}
                onChange={this.getCompanyname}
              />
              {this.state.companyborder !== "1px solid #dee2e6" ? <h5 style={{ color: "#fb6340" }}>Please enter companyname</h5> : null}
            </Col>
           
            <Col md="3">
              <label className="form-control-label">
                Company Code
              </label>
              <Input
                type="text"
                className="form-control-alternative"
                defaultValue={this.state.Companycode}
                style={{ border: this.state.companycodeborder }}
                onChange={this.editCompanyCode}
              />
              {this.state.companycodeborder !== "1px solid #dee2e6" ? <h5 style={{ color: "#fb6340" }}>Please check the companycode</h5> : null}
            </Col>
            <Col md="2">
              <label className="form-control-label">
                IsActive
              </label><br />
              <label style={{ padding: "10px", paddingLeft: "30px" }}>
                <Input
                  type="checkbox"
                  style={{ width: "20px", height: "20px" }}
                  checked={this.state.isactive}
                  onChange={this.deleteCompany}
                />
              </label>
            </Col>
            <Col md="2">
              <label className="form-control-label">
                Gst
              </label><br />
              <label style={{ padding: "10px", paddingLeft: "30px" }}>
                <Input
                  type="checkbox"
                  style={{ width: "20px", height: "20px" }}
                  checked={this.state.isgst}
                  onChange={this.deleteGst}
                />
              </label>
            </Col>
            <Col md="2">
              <div className="button">
                <button id="buttonsave" className="btn btn-dark" onClick={this.FormValidation} disabled={this.state.isButtonDisabled}>
                  Done
                </button>
              </div>
            </Col>
           
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    token: state.token,
  }
}
export default connect(mapStateToProps, null)(EditClient);