import React from 'react';
import { connect } from 'react-redux';
import config from "react-global-configuration";
import '../../../../../config';
import ReactTooltip from "react-tooltip";
import axios from 'axios';
import { Row, Col, Button, Card, Table, Input } from "reactstrap";
const authApi = config.get('apiDomain');
class RequestedStocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Requeststocks: [],
      Userdetails: this.props.allusers,
    }
  }
  async componentDidMount() {
    fetch(`${authApi}purchaserequestfilter/?status=Requested&isdeleted=0`, {
      method: 'GET',
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      }
    })
      .then(res => res.json())
      .then(json => {
        json.forEach(stock => {
          this.state.Userdetails.forEach(user => {
            if (parseInt(stock.username) === user.id) {
              stock.username = user.name;
            }
          });
        });
        this.setState({
          Requeststocks: json.reverse(),
        })
      })
  }

  removepurchaseRequest = (Stock) => {
    let rowdata = this.state.Requeststocks.filter(function (item) {
      return (item.id !== Stock.id);
    });
    let userid = 0;
    this.state.Userdetails.forEach(user => {
      if (user.id === parseInt(Stock.username)) {
        userid = user.id;
      }
    });
    this.setState({
      Requeststocks: rowdata,
    })
    axios({
      method: "put",
      url: `${authApi}purchaserequest/${Stock.id}/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
      data: {
        date: Stock.date,
        time: Stock.time,
        username: userid,
        stockname: Stock.stockname,
        productcode: Stock.productcode,
        quantity: Stock.quantity,
        status: "Rejected",
        description: Stock.description,
        purchaseorderid: Stock.purchaseorderid,
        isdeleted: true
      }
    })
      .then(res => {
        if (res.status === 200) {
          console.log(res);
        }
      })
      .catch(err => {
        console.log(err);
      })

  }

  addpurchaseorder = (Stock) => {
    axios({
      method: "post",
      url: `${authApi}sp_createpurchasestock/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
      data: {
        purchaseorder_id: this.props.purchaseorderid,
        purchase_userrequestedid: Stock.id
      }
    })
      .then(res => res.data)
      .then(json => {
        console.log(json);
      })
      .then(err => console.log(err))
    this.state.Requeststocks.forEach(item => {
      if (item.id === Stock.id) {
        item.status = "Added";
      }
    });
    let data = this.state.Requeststocks;
    this.setState({
      Requeststocks: data,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col md="6">
            <label className="form-control-label" style={{ marginTop: 20, }}>
              <h1><i class="fas fa-cart-plus"></i>Purchase Requests</h1>
            </label>
          </Col>
          <Col md="5" >
            <Input
              style={{ marginTop: 20 }}
              typer="text"
              value={this.props.Suppliername}
            />
          </Col>
          <Col md="0.5" style={{ marginTop: 20 }}>
            <ReactTooltip id="teamTask-Tip" place="right" type="dark" effect="solid">
              Back to EditPurchase
            </ReactTooltip>
            <Button data-tip data-for="teamTask-Tip"
              onClick={() => this.props.backtoEditPurchase()}>
              <i className="fa fa-arrow-left"></i></Button>
          </Col>
        </Row>
        <br />
        <Row>
          <div className=" col"><br />
            <Card className="shadow">
              <Table className="align-items-center table-flush" responsive-sm>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">ACTION</th>
                    <th className="text-center">DATE</th>
                    <th className="text-center">USERNAME</th>
                    <th className="text-center">STOCKNAME</th>
                    <th className="text-center">PRODUCTCODE</th>
                    <th className="text-center">QUANTITY</th>
                    <th className="text-center">STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.Requeststocks.map((request) => (
                    <tr>
                      <td>
                        <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }} onClick={() => this.removepurchaseRequest(request)}>
                          Cancel
                        </button>
                        <button className={request.status === "Requested" ? "btn btn-primary btn-sm" : "btn btn-success btn-sm"} style={{ marginRight: '5px' }} onClick={() => this.addpurchaseorder(request)} disabled={request.status === "Requested" ? false : true}>
                          {request.status === "Requested" ? "AddPurchase" : "Added"}
                        </button>
                      </td>
                      <td>{request.date}</td>
                      <td>{request.username}</td>
                      <td>{request.stockname}</td>
                      <td>{request.productcode}</td>
                      <td>{request.quantity}</td>
                      <td>{request.status}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    name: state.name,
    allusers:state.allusers
  }
}
export default connect(mapStateToProps, null)(RequestedStocks);