import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Invoice from "./Invoice";
import { Row, Card } from "reactstrap";
import "../../../../../config";
import config from "react-global-configuration";
import { connect } from "react-redux";
import moment from "moment";

const authApi = config.get("apiDomain");
let group_data = "";

class ConInvoiceGroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceData: this.props.invoiceData,
      clientGroupname: this.props.clientGroupName,
      JsonObject: "",
      startDate: "",
      endDate: "",
      invoiceClientDet: this.props.invoiceClientData,
      loading: true,
      PresentDay: new Date(),
    };
  }

  componentDidMount() {
    this.datefunction();
    this.downloadFunction();
  }

  datefunction = () => {
    const a = moment(this.props.dateStart).format("YYYY-MM-DD");
    const b = moment(this.props.dateEnd).format("YYYY-MM-DD");
    this.setState({
      startDate: a,
      endDate: b,
    });
  };

  downloadFunction = () => {
    this.setState({
      JsonObject: "",
    });
    this.GetTaskData(this.state.invoiceData.filter((i) => i.taskid !== null));
  };

  GetTaskData = (data) => {
    const filterData = data.filter((item) => {
      return item.invoicestatus !== "Cancelled";
    });
    filterData.forEach((d) => {
      fetch(`${authApi}tasklistfilter/?id=${d.taskid}&isdeleted=0`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.length !== 0) {
            this.updateJsonObject(json, d);
          }
        });
    });
  };

  updateclientname = async (c) => {
    let cli = "";
    this.state.invoiceClientDet.forEach((d) => {
      if (d.id === parseInt(c)) {
        cli = d;
      }
    });
    return cli;
  };

  updateJsonObject = (json, data) => {
    let taskList = [];
    const taskPromises = json.map((element) => this.GetTaskDetail(element));
    Promise.all(taskPromises)
      .then((result) => {
        result.forEach((element) => {
          taskList = [...taskList, element];
        });
        this.setState(
          {
            JsonObject: [
              ...this.state.JsonObject,
              {
                id: data.id,
                invoiceno: `${data.companycode}-${data.deliverynoteid}`,
                GenerateDate: moment(this.state.PresentDay).format(
                  "DD-MM-YYYY"
                ),
                invoicedate: moment(data.date).format("DD-MM-YYYY"),
                client: data.client,
                clientCompany: taskList[0].clientdetails.company_name,
                clientname: taskList[0].clientdetails.engineer_name,
                emailId: taskList[0].clientdetails.emailid,
                Mobilenumber: taskList[0].clientdetails.phoneno,
                amount_received: data.amount_received,
                balancedue: data.balancedue,
                discount: data.discount,
                duedate: data.duedate,
                invoice_amount: data.invoice_amount,
                invoicestatus: data.invoicestatus,
                subtotal: data.subtotal,
                TaskDetails: taskList,
                companyname: data.companyname,
              },
            ],
          },
          function () {
            setTimeout(() => {
              this.setState({ loading: true });
            }, 1);
            this.getGroupdata();
          }
        );
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  getGroupdata = () => {
    group_data = this.multipleGroupByArray(
      this.state.JsonObject,
      (property) => [property.client]
    );
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1);
  };

  multipleGroupByArray = (dataArray, groupPropertyArray) => {
    const groups = {};
    dataArray.forEach((item) => {
      const group = JSON.stringify(groupPropertyArray(item));
      groups[group] = groups[group] || [];
      groups[group].push(item);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  };

  GetTaskDetail = async (task) => {
    const cli = await this.updateclientname(task.client);
    const dc = await this.GetDraftingCharge(task.id);
    let charge = 0;
    dc.forEach((x) => {
      charge = charge + parseInt(x.amount);
    });

    const pc = await this.GetPrintingCharge(task.id);

    const newtask = {
      ID: task.id,
      Name: task.task,
      DraftingCharge: charge,
      PrintingCharge: pc,
      clientdetails: cli,
    };

    return newtask;
  };

  GetDraftingCharge = (taskID) => {
    return fetch(`${authApi}invoicefilter/?tasklistrowid=${taskID}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  GetPrintingCharge = (taskID) => {
    return fetch(`${authApi}stockinvoicefilter/?tasklistrow=${taskID}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">Clientgroup Consolidated Report</div>
          <Row>
            <div className=" col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <Invoice
                      sD={this.state.startDate}
                      eD={this.state.endDate}
                      groupData={group_data}
                      client_groupname={this.state.clientGroupname}
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(ConInvoiceGroupForm);
