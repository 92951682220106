import React from "react";
import {
  Input,
  Row,
  Col
} from "reactstrap";
import axios from 'axios';
import { connect } from "react-redux";
import config from "react-global-configuration";
import consoleFunction from "../../../Helpers";
import '../../../config';

const authApi = config.get('apiDomain');

class AddCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      CompanyName: "",
      IsActive: "",
      IsVisible: "",
      ChangeIsdeleted: 0,
      error: null,
      companyborder: "1px solid #dee2e6",
      companycodeborder: "1px solid #dee2e6",
      isButtonDisabled: false,
      CompanyCode: "",
      isactive: false,
      isgst: false,
    }
  }

  Formvalidation = () => {
    if (this.state.CompanyName !== "") {
      if (this.state.CompanyCode !== "") {
        this.addNewCompany();
      }
      else {
        this.setState({
          companycodeborder: "1px solid #fb6340",
        })
      }
    }
    else {
      this.setState({
        companyborder: "1px solid #fb6340",
      })
    }
  }

  addNewCompany = () => {
    axios({
      method: "post",
      url: `${authApi}company/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
      data: {
        companyname: this.state.CompanyName,
        isactive: this.state.isactive,
        isvisible: true,
        isdeleted: false,
        isgst: this.state.isgst,
        companycode: this.state.CompanyCode,
      }
    })
      .then(res => {
        if (res.status) {
          this.addNewBankaccount(res.data.id);
        }
      })
      .catch((err) => {
        consoleFunction("Add Client details creation error", err.response.request.response)
        this.setState({ error: err.message });
      });
  }

  addNewBankaccount = (companyid) => {
    axios({
      method: "post",
      url: `${authApi}bank/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
      data: {
        bankname: "Cash",
        accountno: "",
        upi: "",
        comments: "",
        ifsc: "",
        companydetails: companyid,
      }
    })
      .then(res => {
        if (res.status === 201) {
          this.props.closebtn();
          this.props.callbackfunction();
        }
      })
      .catch((err) => {
        consoleFunction("Bankaccount Creation error", err.response.request.response)
        this.setState({ error: err.message });
      });
  }



  getCompanyName = (event) => {
    this.setState({
      CompanyName: event.target.value,
    })
  }

  getCompanyCode = (event) => {
    this.setState({
      CompanyCode: event.target.value,
    })
  }

  checkitsactive = () => {
    if (this.state.isactive === false) {
      this.setState({
        isactive: true,
      })
    }
    else {
      this.setState({
        isactive: false,
      })
    }
  }
  checkgst = () => {
    if (this.state.isgst === false) {
      this.setState({
        isgst: true,
      })
    }
    else {
      this.setState({
        isgst: false,
      })
    }
  }

  keyEventOperation = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    }
    else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  render() {
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">
          Add Company
        </div>
        <div className="Scroll-Content">
          <form>
            <Row>
              <Col md="3">
                <label className="form-control-label">
                  Company Name
                </label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  defaultValue={this.state.CompanyName}
                  style={{ border: this.state.companyborder }}
                  onChange={this.getCompanyName}
                  autoFocus
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.companyborder !== "1px solid #dee2e6" ? <h5 style={{ color: "#fb6340" }}>Please enter companyname</h5> : null}
              </Col>
              <Col md="3">
                <label className="form-control-label">
                  Company Code
                </label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  value={this.state.CompanyCode}
                  style={{ border: this.state.companycodeborder }}
                  onChange={this.getCompanyCode}
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.companycodeborder !== "1px solid #dee2e6" ? <h5 style={{ color: "#fb6340" }}>Please enter company code</h5> : null}
              </Col>
              <Col md="2">
                <label className="form-control-label">
                  IsActive
                </label><br />
                <label style={{ padding: "10px", paddingLeft: "30px" }}>
                  <Input
                    type="checkbox"
                    style={{ width: "20px", height: "20px" }}
                    checked={this.state.isactive}
                    onChange={this.checkitsactive}
                    onKeyDown={this.keyEventOperation}
                  />
                </label>
              </Col>
              <Col md="2">
                <label className="form-control-label">
                  Gst
                </label><br />
                <label style={{ padding: "10px", paddingLeft: "30px" }}>
                  <Input
                    type="checkbox" style={{ width: "20px", height: "20px" }}
                    checked={this.state.isgst}
                    onChange={this.checkgst}
                    onKeyDown={this.keyEventOperation}
                  />
                </label>
              </Col>
              <Col md="2">
                <div className="button">
                  <button id="buttonsave" className="btn btn-dark" style={{ float: "right", marginRight: 15 }} onClick={this.Formvalidation} disabled={this.state.isButtonDisabled}>
                    Add
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    token: state.token,
  }
}
export default connect(mapStateToProps, null)(AddCompany);