import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Form, FormGroup, NavLink } from "reactstrap";
import TableContainerExpand from "../../Filters/TableContainerExpand";
import TableContainerExpandTeam from "../../Filters/TableContainerExpandTeam";
import { SelectColumnFilter } from "../../Filters/filters";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import { Link } from "react-router-dom";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import EditProjectInvoice from ".././ProjectInvoice/EditProjectInvoice";
import ReactTooltip from "react-tooltip";
import Editprojects from ".././EditProject/EditProjects";
import Header from "components/Headers/SimpleHeader";

const authApi = config.get("apiDomain");
function CompletedProject(props) {
  const [Purchagesdata, storePurchagesData] = useState([]);
  const [q, setQ] = useState("");
  const [Editdata, getEditdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm");
  const [showdetails, setShowdetails] = useState(false);
  const [invoicedetails, setInvoicedetails] = useState(false);

  useEffect(
    () => {
      const getPurchagesDetails = async () => {
        const response = await fetch(
          `${authApi}projectfilter/?status=Completed`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        let body = await response.json();
        if (body.length !== 0) {
          let companyfulldetails = await getCompanyName();
          body.forEach((oreder) => {
            companyfulldetails.forEach((company) => {
              if (parseInt(oreder.company) === company.id) {
                oreder.companyid = company.companyname;
              }
            });
          });
          setLoading(false);
          storePurchagesData(body.reverse());
        } else {
          setLoading(false);
        }
      };
      getPurchagesDetails();
    },
    // eslint-disable-next-line
    []
  );

  function getFilterButton(event) {
    if (event === true) {
      setChecked(false);
      setBtnClass("btn btn-success btn-sm");
    } else {
      setChecked(true);
      setBtnClass("btn btn-danger btn-sm");
    }
  }


  const getCompanyName = async () => {
    const response = await fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
    });
    const body = await response.json();
    return body;
  };

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : null
      )
    );
  }


  const callBackFunction = async () => {
    let companyfulldetails = await getCompanyName();
    setLoading(true);
    const response = await fetch(
      `${authApi}projectfilter/`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${props.token}`,
        },
      }
    );
    let body = await response.json();

    if (body.length !== 0) {
      body.forEach((oreder) => {
        companyfulldetails.forEach((company) => {
          if (parseInt(oreder.company) === company.id) {
            oreder.companyid = company.companyname;
          }
        });
      });

      body.forEach((order) => {
        props.clientdetails.forEach((client) => {
          if (parseInt(order.supplier) === client.id) {
            order.supplier = client.company_name;
          }
        });
      });
      setLoading(false);
      storePurchagesData(body.reverse());
    } else {
      setLoading(false);
    }
  };

  const showEditDetails = (data) => {
    setShowdetails(true);
    getEditdata(data);
  }


  const backtoProject = () => {
    setShowdetails(false);
    setInvoicedetails(false);
    getEditdata("");
    callBackFunction();
  }


  const showInvoiceDetails = (data) => {
    setInvoicedetails(true);
    getEditdata(data);
  }


  const columnSecondLayer = useMemo(
    () => [

      {
        Header: "plantype",
        accessor: "plantype",
      },
      {
        Header: "DrawintTitle",
        accessor: "drawingtitle",
      },
      {
        Header: "taskname",
        accessor: "task",
      },
      {
        Header: "assignto",
        accessor: "assignto",
      },
      {
        Header: "status",
        accessor: "status",
      },
      {
        Header: "totalamount",
        accessor: "totalamount",
      },
      {
        Header: "InvoiceNo",
        accessor: "invoiceidno",
      },
      {
        Header: "Completeddate",
        accessor: "completed_date",
      },
    ],
    // eslint-disable-next-line
    []
  );

  const renderRowSubComponent = (row) => {
    const rowdata = row.original.taskproject.filter(
      (item) => item.isdeleted !== "1"
    );
    return (
      <React.Fragment>
        <br />
        <h5 className="thead-light">Project Tasks Details :</h5>
        <TableContainerExpandTeam columns={columnSecondLayer} data={rowdata} />
        <br />
      </React.Fragment>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Actions",
        Cell: (row) => {
          const row_data = row.row.original;
          return (
            <React.Fragment>
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
                onClick={() => showEditDetails(row_data)}
              >
                <i className="fa fa-edit" />
              </button>
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
                onClick={() => showInvoiceDetails(row_data)}
              >
                <i className="fa fa-credit-card text-dark" />
              </button>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Company Name",
        accessor: "companyid",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "clientname",
        accessor: "clientname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "ProjectName",
        accessor: "projectname",
        disableSortBy: true,
      },
      {
        Header: "totalamount",
        accessor: "totalamount",
        disableSortBy: true,
      },
      {
        Header: "InvoiceNo",
        accessor: "invoiceidno",
      },
      {
        Header: "status",
        accessor: "status",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Header />
      {showdetails === false && invoicedetails === false ? <Container className="mt--7" fluid>
        <br />
        <Row>
          <div
            className="addRow"
            style={{ position: "absolute" }}
          >
            <NavLink
              style={{ marginTop: 8 }}
              to="/admin/projects"
              tag={Link}
            >
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                data-tip
                data-for="back-Tip"
              >
                Back
              </button>
            </NavLink>
            <ReactTooltip
              id="back-Tip"
              place="right"
              type="dark"
              effect="solid"
            >
              Go to projects
            </ReactTooltip>
          </div>
        </Row>
        <Row>
          <Form
            className="searchForm"
            style={{ marginTop: 14, right: "145px" }}
          >
            <FormGroup className="mb-0">
              <input
                className="searchBox "
                type="search"
                placeholder="Search"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </FormGroup>
          </Form>
          <div
            className="addRow"
            style={{ position: "absolute", right: "90px", marginTop: 16 }}
          >

            <NavLink
              style={{ marginTop: -5 }}
              to="/admin/management/addprojects"
              tag={Link}
            >
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                data-tip
                data-for="completed-Tip"
              >
                
                <i className="fas fa-plus-circle fa-1x"></i>
              </button>
            </NavLink>
            <ReactTooltip
              id="completed-Tip"
              place="left"
              type="dark"
              effect="solid"
            >
              Add Projects
            </ReactTooltip>
          </div>
          <div
            className="invoicegenerate"
            style={{ position: "absolute", right: "15px", marginTop: 16 }}
          >
            <button
              className={btnClass}
              style={{ width: "135%", height: 40 }}
              onClick={(e) => getFilterButton(checked)}
            >
              <i className="fa fa-filter" aria-hidden="true"></i>
            </button>
          </div>
        </Row>
        {loading === true ? (
          <div>
            
            <ConPreLoader />
          </div>
        ) : (
          <Row style={{ marginTop: 80 }}>
            <div className=" col">
              <ReactTooltip place="top" type="dark" effect="float" />
              <TableContainerExpand
                columns={columns}
                checkedToggle={checked}
                data={search(Purchagesdata)}
                renderRowSubComponent={renderRowSubComponent}
              />
            </div>
          </Row>
        )}
        <br />
        <br />
        <br />
      </Container> : null}
      {showdetails === true && invoicedetails === false ?
        <Container className="mt--7" fluid>
          <Editprojects
            Editdata={Editdata}
            callbackfunction={backtoProject}
          />
        </Container> : null
      }
      {invoicedetails === true ?
        <EditProjectInvoice
          Editdata={Editdata}
          backtoProjects={backtoProject} 
        /> : null}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails:state.clientdetails
  };
};
export default connect(mapStateToProps, null)(CompletedProject);
