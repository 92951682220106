import React, { Component } from "react";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  NavLink,
  Table,
  Container,
  Row,
  Col,
  CardTitle,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import {connect} from "react-redux";
import {
  chartOptions,
  parseOptions,
} from "../../../variables/charts.js";
import config from "react-global-configuration";
import Header from "../../../components/Headers/SimpleHeader";
import axios from "axios";
import ClientpanelInvoicerow from "./ClientpanelInvoicerow";
import ClientpanelTransaction from "./ClientpanelTransaction";
import ClientpannelActivetaskrow from "./ClientpannelActivetaskrow";
import "./Clientpanel.css";
import moment  from "moment";
import '../../../config';
const authApi = config.get('apiDomain');
let details=0,s=0,linedata="",barchart="";


class Clientpanel extends Component {
    constructor(props){
        super(props);
        this.state={
            Clientname:this.props.clientdetails,
            Clientbalance:[],
            Invoice:[],
            Transactions:[],
            Activetask:[],
            linechartdata:[],
            monthlyInvocieTotal:[],
            getdate:"",
            SelectClientname:"",
            EngName:"",
            PhoneNo:"",
            Email:"",
            Invoiceamount:"0",
            Receivedamount:"0",
            BalanceDue:"0",
            PendingTask:"",
            Totaltask:"",
            CompletedTask:"",
           
        }
    }

async componentDidMount(){
  let monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
  let d = new Date();
  d.setDate(1);
  let count=0;
  for (let i=0; i<=11; i++) {
    let getmonth=monthName[d.getMonth()];
    let startdate=d.getDate() +'-'+monthName[d.getMonth()] + '-' + d.getFullYear();
    let getstartdate=moment(startdate).format("YYYY-MM-DD");
    let enddate=new Date(d.getFullYear(), d.getMonth() + 1, 0);
    let getenddate=moment(enddate).format("YYYY-MM-DD");
    count=count+1;
    let data={
      id:count,
      month:getmonth,
      startdate:getstartdate,
      enddate:getenddate,
    }
    this.state.linechartdata.push(data);
      d.setMonth(d.getMonth() - 1);
  }
  linedata = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "First dataset",
        data: [1000, 2000, 500, 3000, 5000, 2200],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      }
    ]
  };

  barchart={
    labels: [ "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct",],
    datasets: [
      {
        label: "First dataset",
        data: [100, 200, 500, 300, 500, 220],
        fill: true,
        backgroundColor: "rgba(75,192,300,200)",
        borderColor: "rgb(255,127,80,200)"
      }
    ]
  }


 if (window.Chart) {
    parseOptions(Chart, chartOptions());
     }
 const getbalancedetailsresp = await this.getBalanceDetails();
 this.setState({
    Clientbalance:getbalancedetailsresp
 },function(){
  let details = this.props.clientdetails.map((q) => q.company_name);
  let companynames = details.filter((q, idx) => details.indexOf(q) === idx);
  let firstcompany = companynames.sort((a, b) => a.localeCompare(b))
  this.updateClientallDetails(firstcompany[0]);
 })
    }

getBalanceDetails=async()=>{
  let response= await axios({
    method: "get",
    url: `${authApi}summarizedbalance/`,
    headers: { 'content-type': "application/json",
    'Authorization': `token ${this.props.token}`, },
  })
  return response.data;
}

selectClient=async(event)=>{
await this.updateClientallDetails(event.target.value);
}

updateClientallDetails=async(ClientCompanyname)=>{

let clientdetails = this.state.Clientname.filter(function(e){
    return (e.company_name===ClientCompanyname);
  }); 
this.setState({
    SelectClientname:ClientCompanyname,
    EngName:clientdetails[0].engineer_name,
    PhoneNo:clientdetails[0].phoneno,
    Email:clientdetails[0].emailid, 
    monthlyInvocieTotal:[],
})
await this.clientBalanceDetails(ClientCompanyname);
await this.invoicedetails(clientdetails[0].id);
await this.transactionDetails(clientdetails[0].id);
await this.activeTasks(clientdetails[0].id);
await this.totalTaskDetails(clientdetails[0].id);
await this.monthlyInvoiceDetails(clientdetails[0].id);
}

clientBalanceDetails=(clientname)=>{
    let amountdetails =this.state.Clientbalance.filter(function(e){
        return (e.clientname=== clientname);
      }); 
      if(amountdetails.length===0){
         this.setState({
             Invoiceamount:"0",
             Receivedamount:"0",
             BalanceDue:"0",
         })         
      }
      else{
        this.setState({
            Invoiceamount:amountdetails[0].invoice_amount,
            Receivedamount:amountdetails[0].amount_received,
            BalanceDue:amountdetails[0].balancedue,      
        })   
      }
}

invoicedetails=(id)=>{
    axios(`${authApi}summaryfilter/?invoicesummaryclient=${id}&invoicestatus__in=Unpaid%2CPartially+Paid`, {
        method: 'GET',
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      }
    })
    .then(res => {
      let count=0,data=res.data.reverse();
    let latestData = data.filter(function(e){
          count++;
        return (count <= 5);
      })
     this.setState({
         Invoice:latestData,
     })
    });
}

checkingUpdate=(Invociedata)=>{
  this.state.linechartdata.forEach(dates=>{
    let data=0;
    let monthTotal=0,Totalmonthdata = Invociedata.filter(function(invoice) {
      return dates.enddate >= invoice.date && dates.startdate <= invoice.date
  });
  Totalmonthdata.forEach(invoice => {
    monthTotal=parseInt(invoice.invoice_amount)+monthTotal;
  });
  data={
    id:dates.id,
    Total:monthTotal,
    month:dates.month,
  }
  this.state.monthlyInvocieTotal.push(data);
  })
  this.Updatelinegraphdetails();
} 


Updatelinegraphdetails=()=>{
  linedata = {
    labels: [this.state.monthlyInvocieTotal[11].month,this.state.monthlyInvocieTotal[10].month,this.state.monthlyInvocieTotal[9].month, this.state.monthlyInvocieTotal[8].month, this.state.monthlyInvocieTotal[7].month, this.state.monthlyInvocieTotal[6].month, this.state.monthlyInvocieTotal[5].month,
    this.state.monthlyInvocieTotal[4].month,this.state.monthlyInvocieTotal[3].month,this.state.monthlyInvocieTotal[2].month,this.state.monthlyInvocieTotal[1].month,this.state.monthlyInvocieTotal[0].month],
    datasets: [
      {
        label: "First dataset",
        data: [this.state.monthlyInvocieTotal[11].Total,this.state.monthlyInvocieTotal[10].Total,this.state.monthlyInvocieTotal[9].Total, this.state.monthlyInvocieTotal[8].Total, this.state.monthlyInvocieTotal[7].Total, 
        this.state.monthlyInvocieTotal[6].Total, this.state.monthlyInvocieTotal[5].Total,
        this.state.monthlyInvocieTotal[4].Total,this.state.monthlyInvocieTotal[3].Total,this.state.monthlyInvocieTotal[2].Total,this.state.monthlyInvocieTotal[1].Total,this.state.monthlyInvocieTotal[0].Total],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      }
    ]
  };
  barchart={
    labels: [this.state.monthlyInvocieTotal[5].month,this.state.monthlyInvocieTotal[4].month,this.state.monthlyInvocieTotal[3].month,
    this.state.monthlyInvocieTotal[2].month,this.state.monthlyInvocieTotal[1].month,this.state.monthlyInvocieTotal[0].month],
    datasets: [
      {
        label: "First dataset",
        data: [this.state.monthlyInvocieTotal[5].Total,this.state.monthlyInvocieTotal[4].Total,this.state.monthlyInvocieTotal[3].Total,this.state.monthlyInvocieTotal[2].Total,this.state.monthlyInvocieTotal[1].Total,this.state.monthlyInvocieTotal[0].Total],
        fill: true,
        backgroundColor: "rgba(75,192,300,200)",
        borderColor: "rgb(255,127,80,200)"
      }
    ]
  }
}

transactionDetails=(id)=>{
  axios(`${authApi}transactionfilter/?transaction_client=${id}`, {
    method: 'GET',
  headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
  }
})
.then(res => {
  let count=0,data=res.data.reverse();
let latestData = data.filter(function(e){
      count++;
    return (count <= 5);
  }); 
 this.setState({
  Transactions:latestData,
 })
});
}

activeTasks=(id)=>{
axios(`${authApi}tasklistfilter/?status__in=ToDo%2CPending%2CInProgress&client=${id}`, {
    method: 'GET',
  headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
  }
})
.then(res => {
  let count=0,data=res.data.reverse();
let latestData = data.filter(function(e){
      count++;
    return (count <= 5);
  }); 

 this.setState({
  Activetask:latestData,
  PendingTask:res.data.length,
 })
});
}

totalTaskDetails=(id)=>{
  axios(`${authApi}tasklistfilter/?client=${id}`, {
    method: 'GET',
  headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
  }
})
.then(res => {
  let FilterCompletedTask=res.data.filter(function(data){
 return(data.status === "Completed" && data.isdeleted ==="0");
  });
  let Totaltask=res.data.filter(function(data){
    return(data.isdeleted ==="0");
     });
 this.setState({
  Totaltask:Totaltask.length,
  CompletedTask:FilterCompletedTask.length,
 })
});
}

monthlyInvoiceDetails=(id)=>{
  axios(`${authApi}summaryfilter/?invoicesummaryclient=${id}&invoicestatus__in=Paid%2CUnpaid%2CPartially+Paid`, {
    method: 'GET',
  headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
  }
})
.then(res => {
  this.checkingUpdate(res.data);
});
}


render(){
    const Invoicedata = [...this.state.Invoice].map((item) => (
        <ClientpanelInvoicerow
          key={item.id}
          invoice={item}
          clientname={this.state.SelectClientname}
        />
      )); 
      const Transactionsdata = [...this.state.Transactions].map((item) => (
        <ClientpanelTransaction
          key={item.id}
          transaction={item}
          clientname={this.state.SelectClientname}
        />
      )); 
      const Activetask = [...this.state.Activetask].map((item) => (
        <ClientpannelActivetaskrow
          key={item.id}
          task={item}
          clientname={this.state.SelectClientname}
        />
      )); 
    details = this.state.Clientname.map(q => q.company_name);
    s = details.filter((q, idx) => details.indexOf(q) === idx);
  return (
    <React.Fragment>
      <Header />
     
      <Container className="mt--6" fluid>
       <Row>
        <Col md="8">
           </Col>
       <Col md="4">
            <Form style = {{ marginTop : 15, marginLeft: 10, paddingRight:5}}>
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-filter" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    style = {{ width: 100,
                      height: '40px',
                      overflow: 'hidden',
                      padding: '2px 2px 2px',
                      backgroundColor: 'white',
                      border: 'none' }}
                    type="select"
                    className="form-control-alternative"
                    value={this.state.SelectClientname}
                    onChange={this.selectClient}
                    
                  >
                     {
                       s.sort((a,b) => a.localeCompare(b)).map((item, key) =>
                       (<option key={key} value={item}> {item} </option>))
                     }
                  </Input>
                </InputGroup>
              </FormGroup>
            </Form>
            </Col>
       </Row>
       <br />
          <Row>
            <Col lg="6" xl="3">
                <Card className="card-stats">
                  <CardBody style={{ padding: '0.5rem 0.75rem' }}>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                        Invoice Balance
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {parseInt(this.state.Invoiceamount).toLocaleString(navigator.language)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fa fa-money" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats">
                  <CardBody style={{ padding: '0.5rem 0.75rem' }}>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                         Received
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{parseInt(this.state.Receivedamount).toLocaleString(navigator.language)}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i className="fa fa-arrow-down" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats">
                  <CardBody style={{ padding: '0.5rem 0.75rem' }}>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Balance Due
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{parseInt(this.state.BalanceDue).toLocaleString(navigator.language)}</span><br />
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fa fa-arrow-up" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats">
                  <CardBody style={{ padding: '0.5rem 0.75rem' }}>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {this.state.EngName !== "" ? this.state.EngName:"Clientdetails"}
                        </CardTitle>
                        <span className="h5 font-weight-bold mb-0">{this.state.PhoneNo}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fa fa-user" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: -15 }}>
            <Col lg="6" xl="3">
                <Card className="card-stats">
                  <CardBody style={{ padding: '0.5rem 0.75rem' }}>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Task
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {this.state.Totaltask}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fa fa-tasks"/>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats">
                  <CardBody style={{ padding: '0.5rem 0.75rem' }}>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                         Pending Task
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{this.state.PendingTask}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fa fa-list-ul" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats">
                  <CardBody style={{ padding: '0.5rem 0.75rem' }}>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Completed Task
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{this.state.CompletedTask}</span><br />
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fa fa-list-alt" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats">
                  <CardBody style={{ padding: '0.5rem 0.75rem' }}>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h3"
                          className="text-lowercase text-muted mb-0"
                        >
                        <span id="email" className="h5 font-weight-bold mb-0">{this.state.Email}</span>
                        </CardTitle>                        
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-envelope-square" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: -12 }}>
              <Col md="6">
                <Card>
                  <CardHeader  style={{ backgroundColor: 'transparent', padding: 5 }}>
                    <Row>
                      <Col md="10">
                        <h3 className="mb-0" style={{ marginTop: 5, marginLeft: 10 }}> Active Tasks </h3>
                      </Col>
                      <Col md="2">
                        <NavLink
                          to="/admin/teamtask"
                          tag={Link}
                        >
                          <button className="btn btn-primary btn-sm" style={{ width: 65, height: 30 , right: 25 }}>See all</button>
                        </NavLink>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row style={{ marginTop: -25, marginBottom: -25 }}>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Task</th>
                            <th scope="col">AssignTo</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        {Activetask}
                      </Table>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card>
                  <CardHeader  style={{ backgroundColor: 'transparent', padding: 5 }}>
                    <Row>
                      <Col md="10">
                        <h3 className="mb-0" style={{ marginTop: 5, marginLeft: 10 }}> Latest Invoice </h3>
                      </Col>
                      <Col md="2">
                        <NavLink
                          to="/admin/invoice/invoice-management"
                          tag={Link}
                        >
                          <button className="btn btn-primary btn-sm" style={{ width: 65, height: 30 , right: 25 }}>See all</button>
                        </NavLink>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row style={{ marginTop: -25, marginBottom: -25 }}>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Invoiceid</th>
                            <th scope="col">Balance</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        {Invoicedata}
                      </Table>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: -12 }}>
              <Col md="12">
                <Card>
                  <CardHeader  style={{ backgroundColor: 'transparent', padding: 5 }}>
                    <Row>
                      <Col md="10">
                        <h3 className="mb-0" style={{ marginTop: 5, marginLeft: 10 }}> Latest Transaction </h3>
                      </Col>
                      <Col md="2">
                        <NavLink
                          to="filter/transactions"
                          tag={Link}
                        >
                          <button className="btn btn-primary btn-sm" style={{ width: 65, height: 30 , right: -75 }}>See all</button>
                        </NavLink>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row style={{ marginTop: -25, marginBottom: -25 }}>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">SalesorderId</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Type</th>
                            <th scope="col">ClientName</th>
                          </tr>
                        </thead>
                        {Transactionsdata}
                      </Table>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: -12 }}>
              <Col md="7">
                <Card className="bg-gradient-default">
                  <CardHeader className="bg-transparent" style={{ backgroundColor: 'transparent', padding: 5 }}>
                    <Row>
                      <Col md="12">
                        <h3 className="text-white mb-0" style={{ marginTop: 5, marginLeft: 10 }}> Overview </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Line data={linedata} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="5">
                <Card>
                  <CardHeader style={{ backgroundColor: 'transparent', padding: 5 }}>
                    <Row>
                      <Col md="12">
                        <h3 className="mb-0" style={{ marginTop: 5, marginLeft: 10 }}> Total Orders </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Bar data={barchart} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </Container>
    </React.Fragment>
  );
}
};

const mapStateToProps = state => {
    return {
      token:state.token,
      clientdetails:state.clientdetails
    }
  }
  export default connect(mapStateToProps, null)(Clientpanel);

