import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Input } from "reactstrap";
import config from "react-global-configuration";
import "../../../config";
import { connect } from "react-redux";
import consoleFunction from "../../../Helpers";
import ReactBSAlert from "react-bootstrap-sweetalert";

const authApi = config.get("apiDomain");
let s = 0;
let x = 0;

class AddStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockname: "",
      defaultrate: "",
      id: "",
      unit: "",
      productcode: "",
      hsncode: "",
      subunit: "",
      error: "",
      stocklength: 0,
      stockliquid: 0,
      Stockgroupname: "",
      Unittypename: "",
      Stockgroupdetails: [],
      Unittype: [],
      Stockgroupid: "",
      unitid: "",
      stockborder: "1px solid #dee2e6",
      unitborder: "1px solid #dee2e6",
      defaultrateborder: "1px solid #dee2e6",
      isButtonDisabled: false,
      alert: false,
      sgstpercentage: 0,
      cgstpercentage: 0,
    };
  }

  async componentDidMount() {
    this.stockgroupdetails();
    this.unittypedetails();
  }

  stockgroupdetails = () => {
    fetch(`${authApi}stockgroupfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          Stockgroupdetails: json,
        });
      });
  };

  unittypedetails = () => {
    fetch(`${authApi}unittypefilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json())
      .then((json) => {
        this.setState({ Unittype: json });
      });
  };

  FormValidate = () => {
    if (this.state.stockname !== "") {
      if (this.state.defaultrate !== "") {
        if (this.state.unit !== "") {
          this.setState({
            isButtonDisabled: true,
          });
          this.UpdationRow();
        } else {
          this.setState({
            unitborder: "1px solid #fb6340",
            stockborder: "1px solid #dee2e6",
            defaultrateborder: "1px solid #dee2e6",
          });
        }
      } else {
        this.setState({
          defaultrateborder: "1px solid #fb6340",
          stockborder: "1px solid #dee2e6",
        });
      }
    } else {
      this.setState({
        stockborder: "1px solid #fb6340",
      });
    }
  };

  successAlert = () => {
    const getAlert = () => (
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success!"
        onConfirm={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Your Stock successfully updated
      </ReactBSAlert>
    );

    this.setState({
      alert: getAlert(),
    });
  };

  hideAlert() {
    this.setState({
      alert: false,
    });
    this.props.closebtn();
    this.props.callbackfunction();
  }

  UpdationRow = () => {
    const link = `${authApi}stocks/`;
    axios({
      method: "post",
      url: link,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        stockname: this.state.stockname,
        productcode: this.state.productcode,
        hsncode: this.state.hsncode,
        defaultrate: this.state.defaultrate,
        unit: this.state.unit,
        subunit: this.state.subunit,
        cgstpercentage: this.state.cgstpercentage,
        sgstpercentage: this.state.sgstpercentage,
        stockgroup: this.state.Stockgroupid,
        unitid: this.state.unitid,
        liquid: this.state.stockliquid,
        length: this.state.stocklength,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          this.successAlert();
        }
      })
      .catch((err) => {
        consoleFunction(
          "Stock details creation error",
          err.response.request.response
        );
        this.setState({ error: err.message });
      });
  };

  ChangeStockname = (event) => {
    this.setState({
      stockname: event.target.value,
    });
  };

  ChangeDefatultrate = (event) => {
    this.setState({
      defaultrate: event.target.value,
    });
  };

  ChangeUnit = (event) => {
    this.setState({
      unit: event.target.value,
    });
  };

  ChangeProductCode = (event) => {
    this.setState({
      productcode: event.target.value,
    });
  };
  ChangeHsnCode = (event) => {
    this.setState({
      hsncode: event.target.value,
    });
  };

  handleStockgroupname = (event) => {
    this.setState({
      Stockgroupname: event.target.value,
    });
    const value1 = event.target.value.replace(/ /g, "%20");
    fetch(`${authApi}stockgroupfilter/?name=${value1}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          Stockgroupid: json[0].id,
        });
      });
  };

  handleUnittype = (event) => {
    this.setState({
      unit: event.target.value,
    });
    const value1 = event.target.value.replace(/ /g, "%20");
    fetch(`${authApi}unittype/?unitname=${value1}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          unitid: json[0].id,
        });
      });
  };

  getcgstpercentage = (event) => {
    this.setState({
      cgstpercentage: event.target.value,
    });
  };

  getsgstpercentage = (event) => {
    this.setState({
      sgstpercentage: event.target.value,
    });
  };

  getStocklength = (event) => {
    this.setState({
      stocklength: event.target.value,
    });
  };

  getStockliquid = (event) => {
    this.setState({
      stockliquid: event.target.value,
    });
  };

  keyEventOperation = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    }
    else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  render() {
    const details = this.state.Stockgroupdetails.map((q) => q.name);
    s = details.filter((q, idx) => details.indexOf(q) === idx);
    const unit = this.state.Unittype.map((q) => q.unitname);
    x = unit.filter((q, idx) => unit.indexOf(q) === idx);
    return (
      <React.Fragment>
        {this.state.alert}
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Add Stock</div>
        <div className="Scroll-Content">
          <form>
            <Row>
              <Col md="6">
                <label className="form-control-label">Name</label>
                <Input
                  type="option"
                  list="id_value-list"
                  className="form-control-alternative"
                  style={{ border: this.state.stockborder }}
                  value={this.state.stockname}
                  onChange={this.ChangeStockname}
                  autoFocus
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.stockborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please enter stockname</h5>
                ) : null}
              </Col>
              <Col md="6">
                <label className="form-control-label">Stockgroup</label>
                <Input
                  type="select"
                  className="form-control-alternative"
                  style={{ border: "1px solid #dee2e6" }}
                  defaultValue={this.state.Stockgroupname}
                  onChange={this.handleStockgroupname}
                  onKeyDown={this.keyEventOperation}
                >
                  <option value="">Select Stockgroup</option>
                  {s
                    .sort((a, b) => a.localeCompare(b))
                    .map((item, key) => (
                      <option key={key} value={item}>
                        
                        {item}
                      </option>
                    ))}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label className="form-control-label">Product Code</label>
                <Input
                  type="option"
                  list="id_value-list"
                  style={{ border: "1px solid #dee2e6" }}
                  className="form-control-alternative"
                  value={this.state.productcode}
                  onChange={this.ChangeProductCode}
                  onKeyDown={this.keyEventOperation}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">HSN Code</label>
                <Input
                  type="option"
                  style={{ border: "1px solid #dee2e6" }}
                  list="id_value-list"
                  className="form-control-alternative"
                  value={this.state.hsncode}
                  onChange={this.ChangeHsnCode}
                  onKeyDown={this.keyEventOperation}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">Unit</label>
                <Input
                  type="select"
                  className="form-control-alternative"
                  defaultValue={this.state.unit}
                  style={{ border: this.state.unitborder }}
                  onChange={this.handleUnittype}
                  onKeyDown={this.keyEventOperation}
                >
                  <option value="">Select Unit Type</option>
                  {x
                    .sort((a, b) => a.localeCompare(b))
                    .map((item, key) => (
                      <option key={key} value={item}>
                        
                        {item}
                      </option>
                    ))}
                </Input>
                {this.state.unitborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please enter unit</h5>
                ) : null}
              </Col>
              <Col md="3">
                <label className="form-control-label">DefaultRate</label>
                <Input
                  type="option"
                  list="nickname-list"
                  className="form-control-alternative"
                  style={{ border: this.state.defaultrateborder }}
                  defaultValue={this.state.defaultrate}
                  onChange={this.ChangeDefatultrate}
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.defaultrateborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please enter defaultrate</h5>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label className="form-control-label">Length (mm)</label>
                <Input
                  type="number"
                  className="form-control-alternative"
                  style={{ border: "1px solid #dee2e6" }}
                  defaultValue={this.state.stocklength}
                  onChange={this.getStocklength}
                  onKeyDown={this.keyEventOperation}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">Liquid (ml)</label>
                <Input
                  type="number"
                  className="form-control-alternative"
                  style={{ border: "1px solid #dee2e6" }}
                  defaultValue={this.state.stockliquid}
                  onChange={this.getStockliquid}
                  onKeyDown={this.keyEventOperation}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">CGSTPercentage</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  value={this.state.cgstpercentage}
               
                  onChange={this.getcgstpercentage}
                  onKeyDown={this.keyEventOperation}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">SGSTPercentage</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  
                  value={this.state.sgstpercentage}
                  onChange={this.getsgstpercentage}
                  onKeyDown={this.keyEventOperation}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6"></Col>
              <Col md="6" style={{ textAlign: "right" }}>
                <label className="form-control-label">
                  &nbsp;&nbsp;
                  <button
                    id="buttonsave"
                    className="btn btn-dark"
                    onClick={this.FormValidate}
                    disabled={this.state.isButtonDisabled}
                  >
                    Add
                  </button>
                </label>
              </Col>
            </Row>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(AddStock);
