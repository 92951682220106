import React,{useEffect, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ReactTooltip from "react-tooltip";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import Addclientgrp from "./AddClientgroup";
import EditClientgrp from "./EditClientgroup";
import {connect} from 'react-redux';
import config from "react-global-configuration";
import '../../../../config';
import { Link } from "react-router-dom";
import classnames from 'classnames';
import {
  Card,
  Row,Nav,NavLink,NavItem,Container
} from "reactstrap";

const authApi = config.get('apiDomain');
let currentActiveTab = '1'

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 50,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

function ClientGroups(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
      const doFetch = async () => {
        const response = await fetch(`${authApi}clientgroupfilter/`, {
          method: 'GET',
          headers: {
          'content-type': "application/json",
          'Authorization': `token ${props.token}`,
          }
        }) 
        const myTaskData = await response.json();
        if(myTaskData.length === 0){
          alert("Client groups are not available.!");
          setLoading(false);
        }
        else {
          const details = myTaskData.sort((a, b) => (a.clientid - b.clientid));
          setData(details);
          setLoading(false);
        }}
      doFetch();
    },
// eslint-disable-next-line  
  []
);

function clientupdate(){
  const doFetch = async () => {
    const response = await fetch(`${authApi}clientgroupfilter/`, {
      method: 'GET',
      headers: {
      'content-type': "application/json",
      'Authorization': `token ${props.token}`,
      }
    }) 
    const myTaskData = await response.json();
    if(myTaskData.length === 0){
      alert("Client groups are not available.!");
      setLoading(false);
    }
    else {
      const details = myTaskData.sort((a, b) => (a.clientid - b.clientid));
      setData(details);
      setLoading(false);
    }}
  doFetch();
}

const expandRow = {
  renderer: row => (
    <div>
      <ToolkitProvider
                data={row.clientgroup}
                keyField="name"
                columns={[
                    {
                        dataField: "clientid",
                        text: "Client Id",
                      },
                      {
                        dataField: "company_name",
                        text: "Company Name",
                      },
                      {
                        dataField: "engineer_name",
                        text: "Engineer name",
                      },
                      {
                        dataField: "emailid",
                        text: "Email Id",
                      },
                      {
                        dataField: "phoneno",
                        text: "Phone no.",
                      }
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      keyField='id'
                      bootstrap4={true}
                      bordered={false}
                      filter={ filterFactory() }
                      striped
                      hover
                    />
                  </div>
                )}
              </ToolkitProvider>
    </div>
  ),
  showExpandColumn: true,
  expandByColumnOnly: true
};

function clientgroupActionFormatter(cell, row, rowIndex, formatExtraData) { 
  return ( 
    <React.Fragment>
    <Popup
      modal
      trigger={
        <button className="btn btn-primary btn-sm" style={{marginRight: '5px'}}>
          <i className="fa fa-edit" />
        </button>
      }
      position="center"
    >
      {(close) => (
        <EditClientgrp
          EditRowSend={row}
          closebtn={close}
          callbackfunction={clientupdate}
        />
      )}
    </Popup>
  </React.Fragment> 
); } 

  return (
    <>
      <div style={{ marginTop: 0}}>
			  <Nav tabs style={{ padding: "25px 20px 0", fontSize: 20, backgroundColor: '#11cdef' }}>
			  	<NavItem>
			  		<NavLink style={{ color: 'white' }}
			  			className={classnames({
			  				active:
			  					currentActiveTab === '2'
			  			})}
			  			to="/admin/management/clients"
                  		tag={Link}
			  		>
			  			Clients
			  		</NavLink>
			  	</NavItem>
          <NavItem>
			  		<NavLink
			  			className={classnames({
			  				active:
			  					currentActiveTab === '1'
			  			})}
			  			to="/admin/management/clientgroups"
                  		tag={Link}
			  		>
			  			Client Groups
			  		</NavLink>
			  	</NavItem>
			  </Nav>
        <Container className="mt--6" fluid>
        <br />
        <Row>
          <div className="addRow" style={{position:"absolute", right:"65px"}}>
            <ReactTooltip id="addClients-Tip" place="left" type="dark" effect="solid">
              Add New Clientgroup
            </ReactTooltip>
            <Popup 
              modal
              position="center"
              trigger={
              <button className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }} data-tip data-for="addClients-Tip">
                <i className="ni ni-single-02"></i>
              </button>
              }
              >
              {
                (close) => (
                  <Addclientgrp
                    closebtn={close}
                    callbackfunction={clientupdate}
                  />
                )}
              </Popup>
              </div>
        </Row>
        <br /><br /><br />
        <Row>
        { loading === true ? <div> <ConPreLoader /></div> :
          <div className="col">
            <Card>
              <ToolkitProvider
                data={data}
                keyField="name"
                columns={[
                  { dataField: "edit", 
                    text: "Edit",
                    sort: false,
                    formatter: clientgroupActionFormatter,
                  },
                  {
                    dataField: "id",
                    text: "Id",
                    sort: true,
                  },
                  {
                    dataField: "name",
                    text: "ClientGroup Name",
                    sort: true,
                    filter: textFilter()
                  }
                ]}
                search
              >
                {(props) => (
                  <div className="table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      keyField='id'
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      filter={ filterFactory() }
                      expandRow={ expandRow }
                      striped
                      hover
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
          }
        </Row>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    token:state.token, 
  }
}

export default connect(mapStateToProps, null)(ClientGroups);