import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Row,
  Table,
  Col,
  Container,
  Button,
  NavLink,
  Modal,
  Input,
} from "reactstrap";
import config from "react-global-configuration";
import "../../../../config";
import Header from "components/Headers/SimpleHeader";
import { DragDropContext, DropTarget, DragSource } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import { connect } from "react-redux";

const authApi = config.get("apiDomain");

const labels = ["ToDo", "InProgress", "Pending", "ReadyToBill"];
const labelsMap = {
  ToDo: "ToDo",
  InProgress: "InProgress",
  Pending: "Pending",
  ReadyToBill: "ReadyToBill",
};

const classes = {
  board: {
    display: "flex",
    margin: 2,
    color: "#525f7f",
    fontFamily: 'Arial, "Helvetica Neue", sans-serif',
  },
  column: {
    width: "20vw",
    marginBottom: 5,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: -15,
    marginRight: -15,
  },
  columnHead: {
    textAlign: "center",
    padding: 10,
    margin: 3,
    fontSize: "1.2em",
    backgroundColor: "#f6f9fc",
    color: "#525f7f",
  },
  item: {
    padding: 10,
    margin: 10,
    fontSize: "0.8em",
    cursor: "pointer",
    backgroundColor: "#fff",
  },
};

class TeamtaskKanbanBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      assignee: "",
      modalDetails: "",
      showModal: false,
      loading: true,
    };
  }

  componentDidMount() {
    fetch(
      `${authApi}tasklistfilter/?isdeleted=0&status__in=ToDo%2CPending%2CInProgress%2CReadyToBill`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.getClientname(json);
        this.CalculateUserColor(json);
      });
  }

  getClientname = (body) => {
    let count = body.length;
    let processed = 0;

    body.forEach((ele) => {
      let id = ele.company;
      fetch(`${authApi}companyfilter/?isactive=1&id=${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.length !== 0) {
            ele.companyname = json[0].companyname;
          }
          processed++;
          if (count === processed) {
            console.log(body);
          }
        });
    });
  };

  CalculateUserColor = (body) => {
    let count = body.length;
    let processed = 0;

    body.forEach((ele) => {
      let user = ele.assignto;
      fetch(`${authApi}userfilter/?name=${user}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          let ui = "";
          let uc = "#5e72e4";
          if (json.length !== 0) {
            ui = json[0].initial;
            uc = json[0].colour;
          }
          processed++;
          ele.userinitial = ui;
          ele.usercolor = uc;
          if (count === processed) {
            this.callback(body);
          }
        });
    });
  };

  callback = (body) => {
    const details = body.sort((a, b) => b.status.localeCompare(a.status));
    this.setState({
      items: details,
      loading: false,
    });
  };

  getModal = (data) => {
    this.setState({ showModal: true, modalDetails: data }, function () {
    });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  update = (id, status) => {
    const task = this.state.items.find((task) => task.id === id);
    task.status = status;
    const taskIndex = this.state.items.indexOf(task);
    const newTasks = update(this.state.items, {
      [taskIndex]: { $set: task },
    });

    this.setState({ items: newTasks });
  };

  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <br />
          <Row>
            <Col md="0.5" style={{ marginTop: 25 }}>
              <NavLink className="text-dark" to="/admin/teamtask" tag={Link}>
                <Button className="mr-4" color="white" size="sm">
                  
                  Details View
                </Button>
              </NavLink>
            </Col>
            <Col md="11.5"></Col>
          </Row>
          <br />
          <Row>
            <div className="col">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <section style={classes.board}>
                    {labels.map((channel) => (
                      <Col
                        md={12 / parseInt(labels.length)}
                        style={{ backgroundColor: "#ebecf0", margin: 3 }}
                      >
                        <KanbanColumn status={channel}>
                          <div style={classes.column}>
                            <div style={classes.columnHead}>
                              {labelsMap[channel]}
                            </div>
                            <div>
                              {this.state.items
                                .filter((item) => item.status === channel)
                                .map((item) => (
                                  <KanbanItem id={item.id} onDrop={this.update}>
                                    <div
                                      style={classes.item}
                                      onClick={() => this.getModal(item)}
                                    >
                                      <p>{item.task}</p>
                                      <Col
                                        className="col-auto"
                                        style={{
                                          paddingRight: 0,
                                          paddingLeft: 0,
                                        }}
                                      >
                                        <div
                                          className="icon icon-shape text-white rounded-circle shadow"
                                          style={{
                                            backgroundColor:
                                              item.usercolor === "" ||
                                              item.usercolor === null ||
                                              item.usercolor === undefined
                                                ? "#5e72e4"
                                                : item.usercolor,
                                          }}
                                        >
                                          {item.assignto === "" ||
                                          item.assignto === null ||
                                          item.assignto === undefined
                                            ? "G"
                                            : item.assignto
                                                .charAt(0)
                                                .toUpperCase()}
                                          {item.userinitial === "" ||
                                          item.userinitial === null ||
                                          item.userinitial === undefined
                                            ? ""
                                            : item.userinitial}
                                        </div>
                                      </Col>
                                    </div>
                                  </KanbanItem>
                                ))}
                            </div>
                          </div>
                          <Modal
                            className="modal-dialog-centered"
                            isOpen={this.state.showModal}
                            toggle={() => this.hideModal(false)}
                          >
                            <div className="modal-header">
                              <h6
                                className="modal-title"
                                id="modal-title-default"
                              >
                                Task details
                              </h6>
                              <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.hideModal(false)}
                              >
                                <span aria-hidden={true}>×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <Row>
                                <Col md="4">
                                  <label className="form-control-label">
                                    ClientId
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="text"
                                    defaultValue={
                                      this.state.modalDetails.client
                                    }
                                    value={this.state.modalDetails.client}
                                  />
                                </Col>
                                <Col md="4">
                                  <label className="form-control-label">
                                    ClientName
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="text"
                                    defaultValue={
                                      this.state.modalDetails.clientname
                                    }
                                    value={this.state.modalDetails.clientname}
                                  />
                                </Col>
                                <Col md="4">
                                  <label className="form-control-label">
                                    AssignTo
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="text"
                                    defaultValue={
                                      this.state.modalDetails.assignto
                                    }
                                    value={this.state.modalDetails.assignto}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="4">
                                  <label className="form-control-label">
                                    PlanType
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="text"
                                    defaultValue={
                                      this.state.modalDetails.plantype
                                    }
                                    value={this.state.modalDetails.plantype}
                                  />
                                </Col>
                                <Col md="4">
                                  <label className="form-control-label">
                                    SubClient
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="text"
                                    defaultValue={
                                      this.state.modalDetails.subclient
                                    }
                                    value={this.state.modalDetails.subclient}
                                  />
                                </Col>
                                <Col md="4">
                                  <label className="form-control-label">
                                    PhoneNumber
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="text"
                                    defaultValue={
                                      this.state.modalDetails.phonenumber
                                    }
                                    value={this.state.modalDetails.phonenumber}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="4">
                                  <label className="form-control-label">
                                    Priority
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="text"
                                    defaultValue={
                                      this.state.modalDetails.priority
                                    }
                                    value={this.state.modalDetails.priority}
                                  />
                                </Col>
                                <Col md="4">
                                  <label className="form-control-label">
                                    StartDate
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="date"
                                    defaultValue={
                                      this.state.modalDetails.startdate
                                    }
                                    value={this.state.modalDetails.startdate}
                                  />
                                </Col>
                                <Col md="4">
                                  <label className="form-control-label">
                                    EndDate
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="date"
                                    defaultValue={
                                      this.state.modalDetails.enddate
                                    }
                                    value={this.state.modalDetails.enddate}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="3">
                                  <label className="form-control-label">
                                    Status
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="text"
                                    defaultValue={
                                      this.state.modalDetails.status
                                    }
                                    value={this.state.modalDetails.status}
                                  />
                                </Col>
                                <Col md="3">
                                  <label className="form-control-label">
                                    Company
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="text"
                                    defaultValue={
                                      this.state.modalDetails.companyname
                                    }
                                    value={this.state.modalDetails.companyname}
                                  />
                                </Col>
                                <Col md="6">
                                  <label className="form-control-label">
                                    TaskName
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    style={{ border: "1px solid #dee2e6" }}
                                    type="text"
                                    defaultValue={this.state.modalDetails.task}
                                    value={this.state.modalDetails.task}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="modal-footer">
                              <Button
                                className="ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.hideModal()}
                              >
                                Close
                              </Button>
                            </div>
                          </Modal>
                        </KanbanColumn>
                      </Col>
                    ))}
                  </section>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default DragDropContext(HTML5Backend)(
  connect(mapStateToProps, null)(TeamtaskKanbanBoard)
);

const boxTarget = {
  drop(props) {
    return { name: props.status };
  },
};

class KanbanColumn extends React.Component {
  render() {
    return this.props.connectDropTarget(<div>{this.props.children}</div>);
  }
}

KanbanColumn = DropTarget("kanbanItem", boxTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(KanbanColumn);



const boxSource = {
  beginDrag(props) {
    return {
      name: props.id,
    };
  },

  endDrag(props, monitor) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
    if (dropResult) {
      props.onDrop(item.name, dropResult.name);
    }
  },
};

class KanbanItem extends React.Component {
  render() {
    return this.props.connectDragSource(<div>{this.props.children}</div>);
  }
}

KanbanItem = DragSource("kanbanItem", boxSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(KanbanItem);
