import React from 'react';
import { CustomInput } from 'reactstrap';
import {FilteredData, ClientTransactData,TeamTaskClientname,TeamTaskCompanyname,
  TeamTaskAssignto, TeamTaskPlantype, TeamTaskStatus } from "../../../components/loginRedux/loginReducers";
import store from "../../../components/loginRedux/Store";
let PreData = "";

export const Filter = ({ column: {preFilteredRows}, column}) => {
  if(column.Header === "Client"){
    store.dispatch({ type: ClientTransactData, data: column.filterValue });
  }
  if(column.Header === "ClientName" && ( column.filterValue !== undefined || column.filterValue !== '' || column.filterValue !== null))
  {
    store.dispatch({ type: TeamTaskClientname, data: column.filterValue });
  }
  if(column.Header === "CompanyName" && ( column.filterValue !== undefined || column.filterValue !== '' || column.filterValue !== null))
  {
    store.dispatch({ type: TeamTaskCompanyname, data: column.filterValue });
  }
  if(column.Header === "AssignTo" && ( column.filterValue !== undefined || column.filterValue !== '' || column.filterValue !== null))
  {
    store.dispatch({ type: TeamTaskAssignto, data: column.filterValue });
  }
  if(column.Header === "PlanType" && ( column.filterValue !== undefined || column.filterValue !== '' || column.filterValue !== null))
  {
    store.dispatch({ type: TeamTaskPlantype, data: column.filterValue });
  }
  if(column.Header === "Status" && ( column.filterValue !== undefined || column.filterValue !== '' || column.filterValue !== null))
  {
    store.dispatch({ type: TeamTaskStatus, data: column.filterValue });
  }
  PreData = preFilteredRows;
  store.dispatch({ type: FilteredData, datas: PreData });
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render('Filter')}
    </div>
  );
};

export const DefaultColumnFilter = ({
  column: {
    preFilteredRows: { length },
  },
}) => {
  return (
    <React.Fragment></React.Fragment>
  );
};

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id }}) => 
{  
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  const Options1 = options.sort((a, b) => {
    a = a || '';
    b = b || '';
    return (a?.lastname ?? "").localeCompare(b?.lastname ?? "");
  })
  
  return (
    <CustomInput
      id='custom-select'
      type='select'
      style = {{ width: 130 }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value=''>All</option>
      {Options1.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </CustomInput>
  );
};
