import React, { useState, useEffect, useMemo } from 'react';
import { Container,Row, Input,Form,Col,NavLink ,Card, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Header from "../../../components/Headers/SimpleHeader";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import Popup from "reactjs-popup";
import config from "react-global-configuration";
import '../../../config';
import { connect } from "react-redux";
import TotalInvoiceTask from "./TotalInvoiceTask";
const authApi = config.get('apiDomain');
const preSelectedRow = () => false;
function InvoiceGenerate(props) {
const [data, setData] = useState([]);
const [loading, setLoading] = useState(true);
const [taskClientname, settaskClientname] = useState("");
const [selectedRows, setSelectedRows] = useState([]);
const [unselect, unselectfunction] = useState(true);
useEffect(() => {
  const doFetch = async () => {
    const response = await  fetch(`${authApi}tasklistfilter/?isdeleted=0`, {
      method: 'GET',
      headers: {
      'content-type': "application/json",
      'Authorization': `token ${props.token}`,
      }
    });
    let body = await response.json();
    body = body.filter(function(item){
      return (item.status === "ReadyToInvoice");
    });
    if(body.length === 0){
      alert("No Task available.!");
      setLoading(false);
    }
    else {
      body = body.filter(function(item){
        return (item.status === "ReadyToInvoice");
      });
      setData(body);
      setLoading(false); 
    }
};
doFetch();
},
// eslint-disable-next-line
  []
);
function callbackfunction(){
  unselectfunction(false);
  const doFetch = async () => {
    const response = await  fetch(`${authApi}tasklistfilter/?status=ReadyToInvoice&isdeleted=0`, {
      method: 'GET',
      headers: {
      'content-type': "application/json",
      'Authorization': `token ${props.token}`,
      }
    });
    let body = await response.json();
    body = body.filter(function(item){
      return (item.status === "ReadyToInvoice");
    });
    if(body.length === 0){
      alert("No Task available.!");
      setData(body);
      setLoading(false);
    }
    else {
      setData(body);
      setLoading(false);
    }
};
doFetch();
}
const columns = useMemo(
    () => [
      {
       name:"CLIENT NAME",
       selector: "clientname",
       grow:2,
       center: true,
      },
      {
        name: "TASK",
        selector: "task",
        grow: 2,
        left: true
      },
      {
        name: "ASSIGNTO",
        selector: "assignto",
        center: true,
      },
      {
        name: "Completed Date",
        selector: "completed_date",
        grow: 1.5,
        center: true
      },
      {
        name: "STATUS",
        selector: "status",
        grow: 1.5,
        center: true,
      },
    ],[]
  );
  const selectTaskClientName = (event) => {
    settaskClientname(event.target.value);
    if(event.target.value === "")
    {
      const doFetch = async () => {
          setLoading(true);
          const response = await  fetch(`${authApi}tasklistfilter/?isdeleted=0`, {
            method: 'GET',
            headers: {
            'content-type': "application/json",
            'Authorization': `token ${props.token}`,
            }
          });
          let body = await response.json();
          if(body === 0){
            alert("No Task available for the Selected Filter.!");
            setLoading(false);
          }
          else{
            body = body.filter(function(item){
              return (item.status === "ReadyToInvoice");
            });
            setData(body);
            setLoading(false);
          }
      };
      doFetch();
    }
    else{
      getTaskClientname(event.target.value);
    }
  }
  const getTaskClientname = (a) => {
      setLoading(true);
      setLoading(true);
      let currentclient = props.clientdetails.filter((clientdata)=>{
        return clientdata.company_name === a
      })
      getTask(currentclient[0].id);
  }
  const getTask = (client_id)=>{
      const doFetch = async () => {
        const response = await  fetch(`${authApi}tasklistfilter/?isdeleted=0&client=${client_id}&status=ReadyToInvoice`, {
          method: 'GET',
          headers: {
          'content-type': "application/json",
          'Authorization': `token ${props.token}`,
          }
        });
        let body = await response.json();
        if(body.length === 0){
          alert("No Task available for the Selected Filter.!");
          setLoading(false);
        }
        else {
          setData(body);
          setLoading(false);
        }
      };
      doFetch();
    }
    
const handleRowSelected = (state) => {
  unselectfunction(true);
  if(state.selectedCount !== 0 ){
   let clientid= state.selectedRows[0].client;
   let isdublicate=false;
   if(state.selectedRows.length !== 1){
   state.selectedRows.forEach(element => {
      if(element.client !== clientid){
        isdublicate=true;
        unselectfunction(false);
        alert("Please Select Same Client Tasks");
        setSelectedRows([])
      }
    })
  }
  if(isdublicate === false){
    setSelectedRows(state.selectedRows);
  }
}
 };
const details = data.map(q => q.clientname);
const s = details.filter((q, idx) => details.indexOf(q) === idx);
return (
    <React.Fragment>
        <Header />
        <Container className="mt--7" fluid><br />
          <div className="mydayaddtask">
            { selectedRows.length === 0 ? null :
              <React.Fragment>
                <Popup
                modal
                data-backdrop="static"
                trigger={
                  <button className="btn btn-secondary">
                  <i className="fas fa-plus-circle fa-1x"></i>
                </button>
                }
                >
                {
                  (close) => (
                    <TotalInvoiceTask
                    TotalTask={selectedRows}
                    closebtn={close}
                    reloadfunction={callbackfunction}
                    />
                  )}
                </Popup>
              </React.Fragment>
            }
            </div>
            <Row>
              <NavLink
                to="/admin/invoice/invoice-management"
                tag={Link}
              >
                <Button><i className="fa fa-arrow-left"></i></Button>
              </NavLink>
              <Col md="4">
                <Form>
                  <div className="worktimeassign">
                        <Input
                          type="select"
                          className="form-control-alternative"
                          value={taskClientname}
                          onChange={selectTaskClientName}
                        >
                         <option value="">Select All</option>
                           {
                             s.map((item, key) =>
                             (<option key={key} value={item}> {item} </option>))
                           }
                        </Input>
                  </div>
                </Form>
              </Col>
            </Row>
            <br />
            { loading === true ? <div> <ConPreLoader /></div> :
                <Row>
                    <div className=" col">
                      <Card className="shadow">
                        <DataTable
                            noHeader
                            columns = {columns}
                            data = {data}
                            defaultSortField = "title"
                            striped
                            highlightOnHover
                            pagination
                            selectableRows
                            selectableRowsHighlight
                            onSelectedRowsChange = {handleRowSelected}
                            selectableRowSelected = {preSelectedRow}
                            clearSelectedRows = {unselect}
                        />
                      </Card>
                    </div>
                </Row>
               }
               <br />
          </Container>
      <br/>
    </React.Fragment>
 );
}
const mapStateToProps = state => {
  return {
    token:state.token,
    clientdetails:state.clientdetails
  }
}
export default connect(mapStateToProps, null)(InvoiceGenerate);