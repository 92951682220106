import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  NavLink,
  Form,
  FormGroup,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "components/Headers/SimpleHeader";
import TableContainer from "../../Filters/TableContainer";
import { SelectColumnFilter } from "../../Filters/filters";
import moment from "moment";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import "../../Teamtask/Todotask.css";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import ReactTooltip from "react-tooltip";
import { Input } from "reactstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Popup from "reactjs-popup";
import OverviewConsolidateReportsApp from "./ConsolidatedForm/OverviewReportsApp";
import OverviewDetailReportsApp from "./DetailedForm/OverviewReportsApp";
import CGConsolidateReportsApp from "./TransactionGroupReports/CGConsolidatedReports/OverviewReportsApp";
import CGDetailedReportsApp from "./TransactionGroupReports/CGDetailedReports/OverviewReportsApp";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const authApi = config.get("apiDomain");
let sdToday = new Date();
let Date1 = new Date(sdToday.getFullYear(), sdToday.getMonth(), 1);
let Date2 = new Date(sdToday.getFullYear(), sdToday.getMonth() + 1, 0);
let date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();
let firstDay = new Date(y, m, 1);
let lastDay = new Date(y, m + 1, 0);

function TransactionOverview(props) {
  const [data, setData] = useState([]);
  const [Filterdata, setFilterData] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionData, setSelectedOptionData] = useState([]);
  const [clientgroupdetails, setClientgroupdetails] = useState([]);
  const [clientgroup_name, setclientgroup_name] = useState("");
  const [clientgroup_id, setclientgroup_id] = useState("");
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [clientdatas, setClientdatas] = useState(props.clientdetails);
  const [dateRangeSt, setDateRangeSt] = useState(Date1);
  const [dateRangeEd, setDateRangeEd] = useState(Date2);

  useEffect(
    () => {
      const doFetch = async () => {
        setClientdatas(props.clientdetails);
        makeMultiselectFormat(props.clientdetails);
        const response = await axios({
          method: "post",
          url: `${authApi}sptransaction2/`,

          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
          data: {
            startdate: moment(firstDay).format("YYYY-MM-DD"),
            enddate: moment(lastDay).format("YYYY-MM-DD"),
          },
        });
        const body = await response.data;
        defaultrangepicker(body);
        getClientgroupdetails();
        setData(body);
        setChecked(true);
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  const getClientgroupdetails = () => {
    fetch(`${authApi}clientgroupfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setClientgroupdetails(json);
      });
  };

  function makeMultiselectFormat(clits) {
    const optionData = clits.map(function (row) {
      return {
        id: row.id,
        value: row.company_name,
        label: row.company_name,
        client: row.engineer_name,
        clientid: row.clientid,
        clientgroup: row.clientgroup,
      };
    });
    setSelectedOptionData(optionData);
  }

  function defaultrangepicker(body) {
    let PresentDay = new Date();
    let firstDay = new Date(PresentDay.getFullYear(), PresentDay.getMonth(), 1);
    let lastDay = new Date(
      PresentDay.getFullYear(),
      PresentDay.getMonth() + 1,
      0
    );
    let D1 = moment(firstDay).format("YYYY-MM-DD");
    let D2 = moment(lastDay).format("YYYY-MM-DD");
    let defaultVal = body.filter(function (d) {
      return d.date >= D1 && d.date <= D2;
    });
    if (defaultVal.length === 0) {
      setFilterData(defaultVal);
      setLoading(false);
    } else {
      CalculateDateTime(defaultVal);
      callback(defaultVal);
    }
  }

  function CalculateDateTime(body) {
    body.forEach((e) => {
      e.time = calculateTime(e);
    });
  }

  function calculateTime(t) {
    let num = moment(t.time, "h:mm:ss A").format("HH:mm:ss A");
    return num;
  }

  function callback(body) {
    setFilterData(body);
    setLoading(false);
  }

  async function handleDateRangeCallback(event, picker) {
    setclientgroup_id("");
    setclientgroup_name("");
    setSelectedOptions([]);
    Date1 = picker.startDate._d.toISOString();
    Date2 = picker.endDate._d.toISOString();
    setLoading(true);
    const sd = picker.startDate;
    const ed = picker.endDate;
    setDateRangeSt(picker.startDate);
    setDateRangeEd(picker.endDate);
    let a_sd = moment(sd).format("YYYY-MM-DD");
    let b_ed = moment(ed).format("YYYY-MM-DD");
    const response = await axios({
      method: "post",
      url: `${authApi}sptransaction2/`,

      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
      data: {
        startdate: a_sd,
        enddate: b_ed,
      },
    });
    let filter_val = response.data;
    if (filter_val.length === 0) {
      alert("No transactions available for the selected filter.!");
      setFilterData([]);
      setLoading(false);
    } else {
      CalculateDateTime(filter_val);
      callback(filter_val);
      setData(filter_val);
    }
  }

  const selectClientgroupName = (event) => {
    setLoading(true);
    setclientgroup_name(event.target.value);
    setSelectedOptions([]);
    const value1 = event.target.value.replace(/ /g, "%20");
    fetch(`${authApi}clientgroupfilter/?name=${value1}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.length !== 0 && event.target.value !== "") {
          setclientgroup_id(json[0].id);
          getFitersData(json[0].id);
        } else {
          setclientgroup_id("");
          getFitersData("");
        }
      });
  };

  function getFitersData(cg_id) {
    setLoading(true);
    let a_sd = moment(dateRangeSt).format("YYYY-MM-DD");
    let b_ed = moment(dateRangeEd).format("YYYY-MM-DD");
    let options = [];
    let data1 = data.filter(function (d) {
      return d.date >= a_sd && d.date <= b_ed;
    });
    if (cg_id === "") {
      options = data1;
    } else {
      options = data1.filter((element) => element.clientgroupid === cg_id);
    }
    if (options.length === 0) {
      alert("No transactions available for the selected filter.!");
      setFilterData([]);
      setLoading(false);
    } else {
      CalculateDateTime(options);
      callback(options);
    }
  }

  const onChangeHandler = (value, event) => {
    setSelectedOptions(value);
    setLoading(true);
    let a_sd = moment(dateRangeSt).format("YYYY-MM-DD");
    let b_ed = moment(dateRangeEd).format("YYYY-MM-DD");
    let options = [];
    if (value.length !== 0) {
      let data1 = data.filter(function (d) {
        return d.date >= a_sd && d.date <= b_ed;
      });
      if (clientgroup_id === "") {
        options = data1;
      } else {
        options = data1.filter(
          (element) => element.clientgroupid === clientgroup_id
        );
      }
      let a = [];
      value.map((v) => {
        const searchPattern = new RegExp(`(?=.*${v.value})`, "i");
        let options1 = options.filter((opt) => {
          if (opt.ClientName === v.value) {
            return opt.ClientName.match(searchPattern);
          }
        });
        a = [...a, ...options1];
        return a;
      });
      if (a.length === 0) {
        alert("No transactions available for the selected filter.!");
        setFilterData([]);
        setLoading(false);
      } else {
        CalculateDateTime(a);
        callback(a);
      }
    } else {
      let data1 = data.filter(function (d) {
        return d.date >= a_sd && d.date <= b_ed;
      });
      if (clientgroup_id === "") {
        options = data1;
      } else {
        options = data1.filter(
          (element) => element.clientgroupid === clientgroup_id
        );
      }
      if (options.length === 0) {
        alert("No transactions available for the selected filter.!");
        setFilterData([]);
        setLoading(false);
      } else {
        CalculateDateTime(options);
        callback(options);
      }
    }
  };

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(searchPhrase.toLowerCase()) >
            -1
          : null
      )
    );
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Time",
        accessor: "time",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "User",
        accessor: "name",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Paymentmode",
        accessor: "Paymentmode",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Client",
        accessor: "ClientName",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Company",
        accessor: "companyid",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Task",
        accessor: "Task",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "SalesorderId",
        accessor: "Invoice",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Type",
        accessor: "transactiontype",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Amount",
        accessor: "Amount",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Credit",
        accessor: "Credit",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Debit",
        accessor: "Debit",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Reference No.",
        accessor: "ReferenceNo",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "VoucherId",
        accessor: "voucher_id",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "VoucherType",
        accessor: "vouchertype",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Referencetype",
        accessor: "voucherreferencetype",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
    ],
    // eslint-disable-next-line
    []
  );

  function DownloadData(json) {
    const items = json;
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]).filter(
      (field) =>
        field !== "id" &&
        field !== "openingbalance" &&
        field !== "closingbalance"
    );
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "Overviewtransactions.csv";
    document.body.appendChild(element);
    element.click();
  }

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--7" fluid>
        <br />
        <div className="invoicegenerate" style={{ marginTop: 8 }}>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <button
                className="btn btn-sm btn-dark align-items-center"
                style={{ width: "135%", height: 40 }}
              >
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow">
              <React.Fragment>
                {clientgroup_name === "" ? null : (
                  <>
                    <DropdownItem size="sm">
                      <Popup
                        modal
                        data-backdrop="static"
                        trigger={
                          <h5 className="text-left">CG Consolidate Report </h5>
                        }
                      >
                        {(close) => (
                          <CGConsolidateReportsApp
                            dateStart={dateRangeSt}
                            dateEnd={dateRangeEd}
                            clientgroup={clientgroup_name}
                            clientDetails={clientdatas}
                            closebtn={close}
                          />
                        )}
                      </Popup>
                    </DropdownItem>
                    <DropdownItem size="sm">
                      <Popup
                        modal
                        data-backdrop="static"
                        trigger={
                          <h5 className="text-left">CG Detailed Reports</h5>
                        }
                      >
                        {(close) => (
                          <CGDetailedReportsApp
                            dateStart={dateRangeSt}
                            dateEnd={dateRangeEd}
                            clientgroup={clientgroup_name}
                            clientDetails={clientdatas}
                            closebtn={close}
                          />
                        )}
                      </Popup>
                    </DropdownItem>
                  </>
                )}
                {clientgroup_name === "" ? (
                  <>
                    <DropdownItem size="sm">
                      <Popup
                        modal
                        data-backdrop="static"
                        trigger={
                          <h5 className="text-left">Consolidated Reports</h5>
                        }
                      >
                        {(close) => (
                          <OverviewConsolidateReportsApp
                            selectedClientname={selectedOptions}
                            dateStart={dateRangeSt}
                            dateEnd={dateRangeEd}
                            closebtn={close}
                          />
                        )}
                      </Popup>
                    </DropdownItem>
                    <DropdownItem size="sm">
                      <Popup
                        modal
                        data-backdrop="static"
                        trigger={
                          <h5 className="text-left">Detailed Reports</h5>
                        }
                      >
                        {(close) => (
                          <OverviewDetailReportsApp
                            selectedClientname={selectedOptions}
                            dateStart={dateRangeSt}
                            dateEnd={dateRangeEd}
                            closebtn={close}
                          />
                        )}
                      </Popup>
                    </DropdownItem>
                  </>
                ) : null}
              </React.Fragment>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <Row>
          <Col md="0.5">
            <ReactTooltip
              id="transactions-Tip"
              place="right"
              type="dark"
              effect="solid"
            >
              Go to Invoice Page
            </ReactTooltip>
            <NavLink
              style={{ marginTop: 10, marginLeft: -25 }}
              to="/admin/invoice/invoice-management"
              tag={Link}
            >
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                data-tip
                data-for="transactions-Tip"
              >
                <i className="fa fa-arrow-left"></i>
              </button>
            </NavLink>
          </Col>
          <Col md="3">
            <NavLink style={{ marginTop: 13, marginLeft: -10 }}>
              <DateRangePicker
                autoUpdateInput={false}
                onApply={handleDateRangeCallback}
                initialSettings={{
                  locale: {
                    format: 'DD/MM/YYYY'
                  }
                }}
              >
                <button
                  className="form-control-alternative"
                  style={{
                    height: "35px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "2px 2px 2px",
                    backgroundColor: "white",
                  }}
                >
                  <i
                    className="fa fa-calendar-o"
                    style={{ paddingRight: 15 }}
                    aria-hidden="true"
                  ></i>
                  {moment(Date1).format("DD/MM/YYYY")} -
                  {moment(Date2).format("DD/MM/YYYY")}
                </button>
              </DateRangePicker>
            </NavLink>
          </Col>
          <Col md="3">
            <Input
              style={{
                height: "35px",
                width: "100%",
                overflow: "hidden",
                padding: "2px 2px 2px",
                marginTop: 16,
                marginLeft: -25,
              }}
              type="select"
              className="form-control-alternative"
              value={clientgroup_name}
              onChange={selectClientgroupName}
            >
              <option value="">Select Clientgroup</option>
              {clientgroupdetails.map((item, key) => (
                <option key={key} value={item.name}>
                 
                  {item.name}
                </option>
              ))}
            </Input>
          </Col>
          <Col md="3">
            <div
              style={{
                marginTop: 14,
                marginLeft: -40,
                height: "10px",
              }}
            >
              <ReactMultiSelectCheckboxes
                width={"75%"}
                options={selectedOptionData}
                onChange={onChangeHandler}
                value={selectedOptions}
              />
            </div>
          </Col>
          <Col md="2.5">
            <Form
              className="searchForm"
              style={{ marginTop: 10, right: "117px" }}
            >
              <FormGroup className="mb-0">
                <input
                  className="searchBox "
                  type="search"
                  placeholder="Search"
                  value={searchPhrase}
                  onChange={(e) => setSearchPhrase(e.target.value)}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        {loading === true ? (
          <div>
      
            <ConPreLoader />
          </div>
        ) : (
          <Row style={{ marginTop: 15 }}>
            <div className=" col">
              <ReactTooltip place="top" type="dark" effect="float" />
              <TableContainer
                columns={columns}
                data={search(Filterdata)}
                checkedToggle={checked}
              />
            </div>
          </Row>
        )}
        <br />
        <button
          className="btn btn-dark btn-sm"
          style={{ width: 100, height: 43, float: "right" }}
          onClick={() => DownloadData(Filterdata)}
        >
          Export <br />
          to excel
        </button>
        <br />
        <br />
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails:state.clientdetails
  };
};
export default connect(mapStateToProps, null)(TransactionOverview);
