import React,{Component} from 'react';
import {Input} from "reactstrap";
import { connect } from "react-redux";


class EditTaskRow extends Component {
    constructor(props){
        super(props);
         this.state={
            plantype:this.props.data.plantype,
            drawingtitle:this.props.data.drawingtitle,
            taskname:this.props.data.taskname,
            drawingtitleborder:"none",
            plan:[],
         }
    }
    componentDidMount(){
        
    }

    componentWillReceiveProps(nextProps) {
            this.setState({
                taskname: nextProps.data.taskname,
                plantype:nextProps.data.plantype,
                drawingtitle:nextProps.data.drawingtitle,
                plan:nextProps.plan,
            })
    }


    removeTaskRow=(id)=>{
        this.props.removeTaskRow(this.props.data.id)
    }

    changePlantype=(event)=>{
         this.setState({         
             plantype:event.target.value,
         },function(){
            this.updateJsonObject();
         })
    }


    changeDrawingtitle=(event)=>{
         this.setState({
            drawingtitle:event.target.value,
         },function(){
            this.updateJsonObject();
         })
         let format = /[`!@#$%^&*()_+\-={};':"|,.<>?~]/;
        if(format.test(event.target.value)){
           this.setState({
            drawingtitleborder:"1px solid #fb6340",
           })
          }
          else{
            this.setState({
                drawingtitleborder:"none",
               })
          }
    }

updateJsonObject=()=>{
    let plantype = this.state.plantype.substr(0,2);
    let tasknamedetails=this.props.Client_id.toString()+plantype+"_"+this.props.subclient+"_"+this.state.drawingtitle+"_"+this.props.date;
    this.setState({
        taskname:tasknamedetails,
    })
    this.props.updateJsondataObject(this.props.data.id,this.state.plantype,this.state.drawingtitle,tasknamedetails);
}


render(){
    return(
        <React.Fragment>

              <tr>
                  <td className='text-center'>
                  {this.props.data.id === 999999 ?
                        <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }} onClick={this.props.addTaskrow}>
                            <i className="fas fa-plus-circle fa-1x" />
                        </button> : 
                        <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }} onClick={()=>this.removeTaskRow(this.props.data.id)}>
                            <i className="fa fa-trash" />
                        </button>
                    }
                  </td>
                  {this.props.data.id !== 999999 ? <td  className='text-center'>
                  <Input 
                  type="option" 
                  list="models-list"
                  className="form-control-alternative"
                  value={this.state.plantype}
                  onChange={this.changePlantype} 
                  />
                  <datalist id="models-list">
                   {this.props.plan.map((item, plan) =>
                   <option key={plan} value={item.planname} newvalue={item.id}/>
                    )}
                  </datalist>
                  </td>:<td></td>}
                  {this.props.data.id !== 999999 ? <td className='text-center'>
                      <Input 
                       type="text"
                       value={this.state.drawingtitle}
                       onChange={this.changeDrawingtitle}
                       style={{border:this.state.drawingtitleborder}}
                      />
                    
                      {this.state.drawingtitleborder ==="1px solid #fb6340" ? <h5 style={{color:"#fb6340",textAlign:"left"}}>Please Remove special character</h5>:null}
                      </td>:<td></td>}
                  {this.props.data.id !== 999999 ? <td className='text-center'>
                  <Input 
                       type="text"
                       value={this.props.data.taskname}
                       
                      />
                     
                      </td>:<td></td>}
              </tr>
        </React.Fragment>
    )
}
}

const mapStateToProps = (state) => {
    return {
      token: state.token,
    };
  };
export default connect(mapStateToProps, null)(EditTaskRow);