import React, { Component } from 'react'
import axios from 'axios';
import { Row, Col, Input } from "reactstrap";
import { connect } from "react-redux";
import config from "react-global-configuration";
import consoleFunction from "../../../Helpers";
import '../../../config';

const authApi = config.get('apiDomain');

class Editsubclient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.EditRowSend.id,
      subClientName: this.props.EditRowSend.name,
      PhoneNo: this.props.EditRowSend.phoneno,
      ClientId: this.props.EditRowSend.clients,
    }
  }

  HandleChangeSubClientName = (event) => {
    this.setState({
      subClientName: event.target.value,
    })
  }

  HandleChangeSubClientPhn = (event) => {
    this.setState({
      PhoneNo: event.target.value,
    })
  }

  UpdateSubclient = () => {
    const name = this.state.subClientName;
    const phonenum = this.state.PhoneNo;
    const clientref = this.state.ClientId;
    axios({
      method: "put",
      url: `${authApi}subclients/${this.state.id}/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
      data: {
        name: name,
        phoneno: phonenum,
        clients: clientref
      }
    })
      .then(res => {
        if (res.status === 200) {
          this.props.closebtn();
          this.props.callbackfunction();
        }
      })
      .catch((err) => {
        consoleFunction("Subclient updation error", err.response.request.response)
        this.setState({ error: err.message });
      });
  }

  keyEventOperation = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    }
    else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  render() {
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">
          Add Subclient
        </div>
        <div className="Scroll-Content">
          <form>
            <Row>
              <Col md="6">
                <label className="form-control-label">
                  Name
                </label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  style={{ border: "1px solid #dee2e6" }}
                  value={this.state.subClientName}
                  onChange={this.HandleChangeSubClientName}
                  onKeyDown={this.keyEventOperation} />
              </Col>
              <Col md="6">
                <label className="form-control-label">
                  Phone No.
                </label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  style={{ border: "1px solid #dee2e6" }}
                  value={this.state.PhoneNo}
                  onChange={this.HandleChangeSubClientPhn}
                  onKeyDown={this.keyEventOperation} />
              </Col>
            </Row>

            <button id="buttonsave" className="btn btn-dark" style={{ float: "right", marginRight: 15 }} onClick={this.UpdateSubclient}> Save </button>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  }
}
export default connect(mapStateToProps, null)(Editsubclient);