import React, { Component } from "react";
import { Row, Col, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
import moment from "moment";
import consoleFunction from "../../../Helpers";
import axios from "axios";
const authApi = config.get("apiDomain");
let taxyearstartdate = "",
  taxyearenddate = "",
  closingbalance = "";
class MultiInvoicePaid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClientBalance: 0,

      TransactionClientBalance: 0,
      TransactionInvoiceBalance: 0,
      Amount: 0,
      transaction: [],
      date: new Date(),
      userid: 0,
      companydetails: [],
      companyborder: "1px solid #dee2e6",
      amountBorder: "1px solid #dee2e6",
      isButtonDisabled: true,
      isGeneral: 0,
      companyid: 0,
      company_name: "All Companies",
      paymentmode: "Cash",
      referenceno: 0,
      BankNames: [],
      checkbox: false,
      commentsborder: "1px solid #dee2e6",
      comments: "",
      setcompanyidvalue: 0,
    };
  }

  async componentDidMount() {
    this.getBankDetailsForCurrenttaskCompany();
    if (this.props.invoiceData.length !== 0) {
      if (
        this.props.invoiceData[0].client === "110" ||
        this.props.invoiceData[0].client === "182"
      ) {
        this.setState({
          isGeneral: 1,
          isButtonDisabled: true,
        });
      }
      await this.CompanyDetails();
      let maxdata = 0,
        max = 0;
      const responseyear = await this.checkTaxYear();
      responseyear.forEach((currentyear) => {
        if (currentyear.id > max) {
          max = currentyear.id;
          maxdata = currentyear;
        }
      });
      taxyearstartdate = maxdata.startdate;
      taxyearenddate = maxdata.enddate;
      await this.clientbalancedetails();
      let currentuderdetails = this.props.allusers.filter((userdetails)=>{
        return userdetails.name === this.props.username
      }) 
      this.setState({
        userid: currentuderdetails[0].id,
      });
    } else {
      alert("No task available");
    }
  }

  getBankDetailsForCurrenttaskCompany = () => {
    let company =
      this.state.setcompanyidvalue === 0 ? 1 : this.state.setcompanyidvalue;
    fetch(`${authApi}bankfilter/?companydetails=${company}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          BankNames: json,
        });
      });
  };

  checkTaxYear = () => {
    return fetch(`${authApi}taxyear/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  CompanyDetails = () => {
    fetch(`${authApi}companyfilter/?isactive=1`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          companydetails: json,
        });
      });
  };

  clientbalancedetails = () => {
    axios({
      method: "post",
      url: `${authApi}sp_calculateclosingbalance/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: this.props.client,
        company_id: this.state.setcompanyidvalue,
        startdate: taxyearstartdate,
        enddate: taxyearenddate,
      },
    })
      .then((res) => {
        console.log(res);
        let balance =
          res.data.balance != null || res.data.balance !== ""
            ? res.data.balance
            : 0;
        closingbalance = balance;
        if (balance > 0) {
          this.setState({
            TransactionClientBalance: 0,
            TransactionInvoiceBalance: balance,
            isButtonDisabled: false,
          });
        } else {
          this.setState({
            TransactionClientBalance: parseInt(balance) * -1,
            TransactionInvoiceBalance: 0,
            isButtonDisabled: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  ChangeAmount = (event) => {
    if (this.state.checkbox === true) {
      if (event.target.value < 0) {
        this.setState({
          Amount: event.target.value,
        });
      } else {
        this.setState({
          Amount: isNaN(event.target.value * -1) ? 0 : event.target.value * -1,
        });
      }
    } else {
      this.setState({
        Amount: event.target.value,
      });
    }
  };

  FormValidation = () => {
    if (this.state.Amount !== 0 && this.state.Amount !== "") {
      if (this.state.isGeneral === 0) {
        if (this.state.company_name !== "All Companies") {
          if (this.state.comments !== "" || this.state.checkbox === false) {
            this.setState({
              isButtonDisabled: true,
            });
            if (parseInt(this.state.Amount) < 0) {
              this.callAdjustmentStoreproc();
            } else {
              this.OnSubmitInvoice();
            }
          } else {
            this.setState({
              commentsborder: "1px solid #fb6340",
              companyborder: "1px solid #dee2e6",
              amountBorder: "1px solid #dee2e6",
            });
          }
        } else {
          this.setState({
            companyborder: "1px solid #fb6340",
            amountBorder: "1px solid #dee2e6",
          });
        }
      }
    } else {
      this.setState({
        amountBorder: "1 px solid #fb6340",
      });
    }
  };

  callAdjustmentStoreproc = () => {
    axios({
      method: "post",
      url: `${authApi}sp_receivedamountadjustment/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        clientid: this.props.client,
        userid: this.state.userid,
        companyid: this.state.companyid,
        adjustmentamount: this.state.Amount,
        comments: this.state.comments,
        closingbalanceamount: closingbalance,
        paymentmode: this.state.paymentmode,
        paymentreference: this.state.referenceno,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          this.props.closebtn();
          this.props.refresh();
        }
      })
      .catch((err) => {
        consoleFunction(
          "Pay for unpaid task error",
          err.response.request.response
        );
        this.props.refresh();
      });
  };

  OnSubmitInvoice = () => {
    if (
      this.state.Amount !== 0 &&
      this.state.Amount !== "0" &&
      this.state.Amount !== ""
    ) {
      this.StoreReceiveTransaction();
    }
  };

  PayforUnpaidTask = async () => {
    axios({
      method: "post",
      url: `${authApi}sp_payforunpaidinvoice/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: this.props.client,
        company_id_value: this.state.companyid,
        startdate: taxyearstartdate,
        enddate: taxyearenddate,
        userid: this.state.userid,
        received_amount: this.state.Amount,
        description: "Payment received for multiple invoices",
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.props.closebtn();
          this.props.refresh();
        }
      })
      .catch((err) => {
        consoleFunction(
          "Pay for unpaid task error",
          err.response.request.response
        );
        this.props.refresh();
      });
  };

  StoreReceiveTransaction = () => {
    let newamount = parseInt(this.state.Amount) || 0;
    let date = moment(new Date()).format("YYYY-MM-DD");
    let currentTime = moment(new Date())
      .utcOffset("+05:30")
      .format("hh:mm:ss A");
    axios({
      method: "post",
      url: `${authApi}transactions/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        transactiontaskid: "",
        date: date,
        time: currentTime,
        clientidnum: this.props.client,
        transaction_clientid: this.props.client,
        userid_id: this.state.userid,
        amount: newamount,
        deliverynoteid: "",
        originaldeliverynoteid: 0,
        originalcompanyid: 0,
        description: "",
        transactiontype: "received",
        companyid: this.state.companyid,
        companyidnum: this.state.companyid,
        pmtmode: this.state.paymentmode,
        pmtreference: this.state.referenceno,
        voucherid: "",
      },
    })
      .then((res) => {
        if (res.status === 201) {
          this.PayforUnpaidTask();
        }
      })
      .catch((err) => {
        consoleFunction(
          "Transaction received error",
          err.response.request.response
        );
        this.props.refresh();
      });
  };

  HandleCompany = (event) => {
    if (event.target.value !== "All Companies") {
      event.persist();
      this.setState({
        company_name: event.target.value,
      });
      fetch(`${authApi}companyfilter/?companyname=${event.target.value}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.length !== 0) {
            this.setState(
              {
                companyid: json[0].id,
                setcompanyidvalue: json[0].id,
              },
              function () {
                this.getBankDetailsForCurrenttaskCompany();
                this.clientbalancedetails();
              }
            );
          }
        });
    } else {
      this.setState(
        {
          company_name: event.target.value,
          companyid: 0,
          setcompanyidvalue: 0,
        },
        function () {
          this.clientbalancedetails();
        }
      );
    }
  };

  HandleChangepaymentmode = (event) => {
    this.setState({
      paymentmode: event.target.value,
    });
  };

  HandleChangereferenceno = (event) => {
    this.setState({
      referenceno: event.target.value,
    });
  };

  onChangeChaeckbox = () => {
    if (this.state.checkbox === true) {
      this.setState({
        checkbox: false,
        Amount: this.state.Amount * -1,
      });
    } else {
      this.setState({
        checkbox: true,
        Amount: this.state.Amount * -1,
      });
    }
  };

  HandleComments = (event) => {
    this.setState({
      comments: event.target.value,
    });
  };

  render() {
    let bankname = this.state.BankNames.map((q) => q.bankname);
    let nameofbanks = bankname.filter((q, idx) => bankname.indexOf(q) === idx);

    let companydetails = this.state.companydetails.map((q) => q.companyname);
    let nameofcompany = companydetails.filter(
      (q, idx) => companydetails.indexOf(q) === idx
    );

    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Payment</div>
        <Row>
          <Col md="4">
            <label className="form-control-label">Company</label>
            <Input
              type="select"
              className="form-control"
              style={{ border: "1px solid #dee2e6" }}
              value={this.state.company_name}
              onChange={this.HandleCompany}
            >
              <option value="All Companies">All Companies</option>
              {nameofcompany.map((item, key) => (
                <option key={key} value={item}>
                  {item}
                </option>
              ))}
            </Input>

            {this.state.companyborder !== "1px solid #dee2e6" ? (
              <h5 style={{ color: "#fb6340" }}>Please select companyname</h5>
            ) : null}
          </Col>
          <Col md="2">
            <label className="form-control-label">ClientBalance</label>
            <br />
            <Label className="form-control-alternative">
              <Input
                className="form-control-alternative"
                style={{ border: "1px solid #dee2e6" }}
                type="text"
                value={this.state.TransactionClientBalance}
              />
            </Label>
          </Col>
          <Col md="2">
            <label className="form-control-label">InvoiceBalance</label>
            <br />
            <Label className="form-control-alternative">
              <Input
                className="form-control-alternative"
                style={{ border: "1px solid #dee2e6" }}
                type="text"
                value={this.state.TransactionInvoiceBalance}
              />
            </Label>
          </Col>
          <Col md="4">
            <label className="form-control-label">Amount</label>
            <Input
              className="form-control-alternative"
              type="text"
              style={{ border: this.state.amountBorder }}
              value={this.state.Amount}
              onChange={this.ChangeAmount}
            />
            {this.state.amountBorder !== "1px solid #dee2e6" ? (
              <h5 style={{ color: "#fb6340" }}>Please enter amount</h5>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <label className="form-control-label">payment mode</label>
            <Input
              type="select"
              style={{ border: "1px solid #dee2e6" }}
              className="form-control"
              value={this.state.paymentmode}
              onChange={this.HandleChangepaymentmode}
            >
              {nameofbanks.map((item, key) => (
                <option key={key} value={item}>
                  {item}
                </option>
              ))}
            </Input>
          </Col>
          <Col md="4">
            <label className="form-control-label">Reference No</label>
            <Input
              type="text"
              style={{ border: "1px solid #dee2e6" }}
              list="id_value-lists"
              className="form-control-alternative"
              value={this.state.referenceno}
              onChange={this.HandleChangereferenceno}
            />
          </Col>
          {this.props.Checkadmin === "1" ? (
            <Col md="4">
              <td>
                <label className="form-control-label">Adjust Amount</label>
                <br />
                <input
                  type="checkbox"
                  style={{ width: "20px", height: "20px" }}
                  checked={this.state.checkbox}
                  onChange={this.onChangeChaeckbox}
                />
              </td>
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col md="4">
            {this.state.checkbox === true ? (
              <label className="form-control-label">
                Reason For Adjustment
              </label>
            ) : null}
            {this.state.checkbox === true ? (
              <Input
                type="text"
                list="id_value-lists"
                className="form-control-alternative"
                style={{ border: "1px solid #dee2e6" }}
                value={this.state.comments}
                onChange={this.HandleComments}
              />
            ) : null}
            {this.state.commentsborder !== "1px solid #dee2e6" ? (
              <h5 style={{ color: "#fb6340" }}>Please enter reason</h5>
            ) : null}
          </Col>
          <Col md="6">
            {this.state.isGeneral === 1 ? (
              <h5 style={{ color: "#fb6340", textAlign: "center" }}>
                This option is not supported for General/General-printing task
              </h5>
            ) : null}
          </Col>
          <Col md="2">
            <button
              id="buttonsave"
              className="btn btn-dark"
              style={{ float: "right", marginRight: 15 }}
              onClick={this.FormValidation}
              disabled={this.state.isButtonDisabled}
            >
              Pay
            </button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username:state.username,
    allusers:state.allusers
  };
};
export default connect(mapStateToProps, null)(MultiInvoicePaid);
