import React, { Component } from 'react'
import axios from 'axios';
import {Row, Col, Input } from "reactstrap";
import moment from "moment";
import {connect} from "react-redux";
import config from "react-global-configuration";
import consoleFunction from "../../../Helpers";
import '../../../config';
const authApi = config.get('apiDomain');

class AddTime extends Component {
    constructor(props){
        super(props);
        this.state={
        date:moment(new Date()).format("YYYY-MM-DD"),
        comments:"",
        time:"00:00",
        username:this.props.EditRowSend.assignto,
        endtime:"",
        start_time:"",
        subtask: this.props.EditRowSend.id,
        isNotvalid:"0",
        border:"1px solid #dee2e6",
        isButtonDisabled: false
    }
    }

UpdateSubtaskTime=()=>{
  this.setState({
    isButtonDisabled: true
  }) 
   const date=moment(this.state.date).format("YYYY-MM-DD");
   const username=this.state.username;
   const comments=this.state.comments;
   const time=this.state.time;
   const endtime=this.state.endtime;
   const starttime=this.state.start_time;
   const subtask=this.state.subtask;
   let times=this.state.time.split(':');
   let valid=true;
   if(parseInt(times[1])>60){
     valid=false;
   }
   if(times.length === 2 && valid===true){
   axios({
    method: "post",
    url: `${authApi}subtasktime/`,
    headers: { 'content-type': "application/json",
    'Authorization': `token ${this.props.token}`, },
    data: {
      date: date,
      username: username,
      comments: comments,
      time:time,
      endtime:endtime,
      start_time: starttime,
      subtask:subtask,
    }
    })
      .then(res =>{
         if (res.status === 201) {
           this.props.closebtn();
           this.props.functionreload(this.props.tt_client, this.props.tt_company,this.props.tt_assignto,this.props.tt_plantype,this.props.tt_status);
            }})
            .catch((err) => {
              this.setState({ error: err.response.request.response,
                isNotvalid:"1",
              });
              consoleFunction("Subtask time updation error", err.response.request.response);
              this.props.functionreload(this.props.tt_client, this.props.tt_company,this.props.tt_assignto,this.props.tt_plantype,this.props.tt_status);
            });
          }
  else{
    this.setState({ 
      isNotvalid:"1",
    })
     }
}


SubtasktimeTime=(event)=>{
  let times= event.target.value.split(':');
  let valid=true;
  if(parseInt(times[1])>59){
    valid=false;
  }
  if(times.length === 2 && valid===true){
  this.setState({
  time:event.target.value,
  border:"1px solid #dee2e6"
 })}
 else{
  this.setState({
    time:event.target.value,
    border:"1px solid #fb6340",
   })
 }
}

SubtasktimeDate=(event)=>{
this.setState({
    date:event.target.value,
})
}
SubtaskTimeComments=(event)=>{
    this.setState({
        comments:event.target.value,
    })
}

render() {
        return (
            <React.Fragment>
            <button className="x" onClick={this.props.closebtn}>
            x
            </button>
            <div className="header">
             Add SubtaskTime
            </div>
            <div className="Scroll-Content">
            <Row>
            <Col md="4">
                <label className="form-control-label">
                  Date :
                  </label>
                  <Input
                    className="form-control-alternative"
                    style={{ border: "1px solid #dee2e6" }}
                    type="date"
                    defaultValue={this.state.date}
                    onChange={this.SubtasktimeDate}
                  />
              </Col>
              <Col md="4">
                   <label className="form-control-label">
                   time
                   </label>
                    <Input 
                    className="form-control-alternative"
                    style={{border:this.state.border}}
                    type="text"  
                    defaultValue={this.state.time} 
                    onChange={this.SubtasktimeTime} />
                  </Col>
              </Row>
              <Row>
              <Col md="12">
                   <label className="form-control-label">
                   Comments
                   </label>
                    <Input 
                    className="form-control-alternative"
                    style={{ border: "1px solid #dee2e6" }}
                    type="text"  
                    value={this.state.comments} 
                    onChange={this.SubtaskTimeComments} />
                  </Col>
              </Row>
              {this.state.isNotvalid==="1" ? <h5 style={{color:"#fb6340",textAlign:"center"}}>Please check time format</h5>: null}
            <button id="buttonsave" className="btn btn-dark" style={{ float: "right", marginRight: 15}} onClick={this.UpdateSubtaskTime} disabled={this.state.isButtonDisabled} > Add </button>
         </div>
      </React.Fragment>        
    )
    }
}
const mapStateToProps = state => {
  return {
    token:state.token, 
    tt_client : state.teamtask_client,
    tt_company : state.teamtask_company,
    tt_assignto : state.teamtask_assignto,
    tt_plantype : state.teamtask_plantype,
    tt_status : state.teamtask_status
  }
}
export default connect(mapStateToProps, null)(AddTime);