import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, NavLink, Form, FormGroup } from "reactstrap";
import TableContainerExpand from "../../Filters/TableContainerExpand";
import TableContainerExpandTeam from "../../Filters/TableContainerExpandTeam";
import { SelectColumnFilter } from "../../Filters/filters";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import EditPurchaseDetails from "./EditPurchase/EditPurchaseDetails";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import CancelEdit from "./CancelEdit";
import ReactTooltip from "react-tooltip";
import Header from "../../../../components/Headers/SimpleHeader";
import axios from "axios";

const authApi = config.get("apiDomain");
let edit = false;
function Purchase(props) {
  const [Purchagesdata, storePurchagesData] = useState([]);
  const [q, setQ] = useState("");
  const [Editdata, getEditdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [editChecked, setEditChecked] = useState(false);
  const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm");

  useEffect(
    () => {
      const getPurchagesDetails = async () => {
        const response = await fetch(
          `${authApi}purchaseorderfilter/?isdeleted=false`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        let body = await response.json();
        if (body.length !== 0) {
          let companyfulldetails = await getCompanyName();
          body.forEach((oreder) => {
            companyfulldetails.forEach((company) => {
              if (parseInt(oreder.companyid) === company.id) {
                oreder.companyid = company.companyname;
              }
            });
          });

          body.forEach((order) => {
            props.clientdetails.forEach((client) => {
              if (parseInt(order.supplier) === client.id) {
                order.supplier = client.company_name;
              }
            });
          });
          setLoading(false);
          storePurchagesData(body.reverse());
          edit = false;
        } else {
          setLoading(false);
        }
      };
      getPurchagesDetails();
    },
    // eslint-disable-next-line
    []
  );

  function getFilterButton(event) {
    if (event === true) {
      setChecked(false);
      setBtnClass("btn btn-success btn-sm");
    } else {
      setChecked(true);
      setBtnClass("btn btn-danger btn-sm");
    }
  }

  const getCompanyName = async () => {
    const response = await fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
    });
    const body = await response.json();
    return body;
  };

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : null
      )
    );
  }

  function editDetails(rowdata) {
    getEditdata(rowdata);
    setEditChecked(true);
    edit = true;
    console.log(edit)
  }




  const callBackFunction = async () => {
    let companyfulldetails = await getCompanyName();
    setLoading(true);
    setEditChecked(false);
    edit = false;
    const response = await fetch(
      `${authApi}purchaseorderfilter/?isdeleted=false`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${props.token}`,
        },
      }
    );
    let body = await response.json();
    if (body.length !== 0) {
      body.forEach((oreder) => {
        companyfulldetails.forEach((company) => {
          if (parseInt(oreder.companyid) === company.id) {
            oreder.companyid = company.companyname;
          }
        });
      });

      body.forEach((order) => {
        props.clientdetails.forEach((client) => {
          if (parseInt(order.supplier) === client.id) {
            order.supplier = client.company_name;
          }
        });
      });
      setLoading(false);
      storePurchagesData(body.reverse());
    } else {
      setLoading(false);
    }
  };

  const cancelPurchase = async (data) => {
    let response = await axios({
      method: "post",
      url: `${authApi}sp_deletepurchaseorder/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${props.token}`,
      },
      data: {
        purchaseorderid: data.id
      }
    })
    if (response.status === 200) {
      callBackFunction();
    }
  };

  const columnSecondLayer = useMemo(
    () => [
      {
        Header: "StockName",
        accessor: "name",
      },
      {
        Header: "ProductCode",
        accessor: "productcode",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Rate",
        accessor: "rate",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "CGST",
        accessor: "cgstdiscount",
      },
      {
        Header: "SGST",
        accessor: "sgstdiscount",
      },
      {
        Header: "Total",
        accessor: "total",
      },
    ],
    // eslint-disable-next-line
    []
  );

  const renderRowSubComponent = (row) => {
    const rowdata = row.original.purchasestock.filter(function (item) {
      return item.isdeleted === false;
    });
    let stocks = [];
    stocks = rowdata.filter(function (item) {
      return item.name !== "Total";
    });
    rowdata.forEach((item) => {
      if (item.name === "Total") {
        stocks = [...stocks, item];
      }
    });
 

    return (
      <React.Fragment>
        <br />
        <h5 className="thead-light">Purchase Stocks Details :</h5>
        <TableContainerExpandTeam columns={columnSecondLayer} data={stocks} />
        <br />
      </React.Fragment>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Actions",
        Cell: (row) => {
          const row_data = row.row.original;
          return (
            <React.Fragment>
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
                onClick={() => editDetails(row_data)}
                disabled={
                  row_data.status === "Received" ||
                    row_data.status === "Partially Received" ||
                    row_data.status === "Issued"
                    ? true
                    : false
                }
              >
                <i className="fa fa-edit" />
              </button>
              <Popup
                modal
                trigger={
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: "5px" }}
                    disabled={row_data.status === "Draft" ? false : true}
                  >
                    <i className="fa fa-trash" />
                  </button>
                }
              >
                {(close) => (
                  <CancelEdit
                    closebtn={close}
                    purchaseorder={true}
                    Editdata={row_data}
                    clearpurchase={cancelPurchase}
                  />
                )}
              </Popup>
              <button
                className="btn btn-primary btn-sm"
                style={{ width: 30, height: 28 }}
              >
                <NavLink
                  style={{ marginTop: -5 }}
                  to={{
                    pathname: "/admin/management/view-purchaseorder",
                    state: { purchaseorderData: row_data },
                  }}
                  tag={Link}
                >
                  <i
                    style={{ marginLeft: -13, marginBottom: 500 }}
                    className="fa fa-eye text-white"
                  />
                </NavLink>
              </button>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Purchase Id",
        accessor: "id",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Company Name",
        accessor: "companyid",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Supplier",
        accessor: "supplier",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Payment",
        accessor: "payment",
        disableSortBy: true,
      },
      {
        Header: "Adjustment",
        accessor: "roundoff",
        disableSortBy: true,
      },
      {
        Header: "Total",
        accessor: "total",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Header />
      {editChecked === false ? (
        <Container className="mt--7" fluid>
          <br />
          <Row>
            <Form
              className="searchForm"
              style={{ marginTop: 14, right: "145px" }}
            >
              <FormGroup className="mb-0">
                <input
                  className="searchBox "
                  type="search"
                  placeholder="Search"
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
              </FormGroup>
            </Form>
            <div
              className="addRow"
              style={{ position: "absolute", right: "90px", marginTop: 2 }}
            >
              <NavLink
                style={{ marginTop: 10 }}
                to="/admin/addpurchase/addpurchase"
                tag={Link}
              >
                <button
                  className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                  data-tip
                  data-for="completed-Tip"
                >
                 
                  <i className="fas fa-plus-circle fa-1x"></i>
                </button>
              </NavLink>
              <ReactTooltip
                id="completed-Tip"
                place="left"
                type="dark"
                effect="solid"
              >
                Add Purchase
              </ReactTooltip>
            </div>
            <div
              className="invoicegenerate"
              style={{ position: "absolute", right: "15px", marginTop: 16 }}
            >
              <button
                className={btnClass}
                style={{ width: "135%", height: 40 }}
                onClick={(e) => getFilterButton(checked)}
              >
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </Row>
          {loading === true ? (
            <div>
             
              <ConPreLoader />
            </div>
          ) : (
            <Row style={{ marginTop: 80 }}>
              <div className=" col">
                <ReactTooltip place="top" type="dark" effect="float" />
                <TableContainerExpand
                  columns={columns}
                  checkedToggle={checked}
                  data={search(Purchagesdata)}
                  renderRowSubComponent={renderRowSubComponent}
                />
              </div>
            </Row>
          )}
          <br />
          <br />
          <br />
        </Container>
      ) : null}
      {editChecked === true ? (
        <Container className="mt--7" fluid>
          <br />
          <EditPurchaseDetails
            Editdata={Editdata}
            checked={editChecked}
            callBack={callBackFunction}
          />
        </Container>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails:state.clientdetails
  };
};
export default connect(mapStateToProps, null)(Purchase);
