import React,{useState} from 'react';
import {Row,Col,Input} from 'reactstrap';
import {connect} from 'react-redux';
import config from "react-global-configuration";
import '../../../../config';
import axios from 'axios';
const authApi = config.get('apiDomain');
function AddEditLocation(props){
    const [location,updatelocation]=useState(props.locationdetails === "" ? "":props.locationdetails.location);
    const [shortlocation,updateshortlocation]=useState(props.locationdetails === "" ? "":props.locationdetails.shortlocation);
    const [description,updatedescription]=useState(props.locationdetails === "" ? "":props.locationdetails.description);
    const [godown,updategodown]=useState(props.locationdetails === "" ? "":props.locationdetails.godown);
    const [id,updateid]=useState(props.locationdetails === "" ? "":props.locationdetails.id);
    const [validlocation,validateLocationdetails]=useState("1px solid #dee2e6");
    const [validshortlocation,validateshortlocationdetails]=useState("1px solid #dee2e6");

const changeLocation=(event)=>{
    updatelocation(event.target.value);
}

const changeShortLocation=(event)=>{
    updateshortlocation(event.target.value);
}

const changeLocationDescription=(event)=>{
    updatedescription(event.target.value);
}

const changeGodown=(event)=>{
  updategodown(event.target.value);
}

const isValidation=()=>{
  if(location !== ""){
      if(shortlocation !== ""){
            validateLocationdetails("1px solid #dee2e6");
            validateshortlocationdetails("1px solid #dee2e6");
            StoreChanges();
      }
      else{
        validateLocationdetails("1px solid #dee2e6");
        validateshortlocationdetails('1px soli#fb6340');
      }
  }
  else{
    validateLocationdetails('1px solid #fb6340');
    validateshortlocationdetails("1px solid #dee2e6");
  }
}

const StoreChanges=async()=>{
  if(id===""){
    axios({
        method: "post",
        url: `${authApi}location/`,
        headers: { 'content-type': "application/json",
        'Authorization': `token ${props.token}`, },
      data: {
        location:location,
        shortlocation:shortlocation,
        godown:godown,
        description:description,
        }
    })
    .then(res=>{
        if(res.status===201){
            updateid(id);
            props.referesh();
            props.closebtn();
        }
    })
     .catch(err=>console.log("Task move to readytobill error", err.response.request.response))
  }
  else{
    axios({
        method: "put",
        url: `${authApi}location/${id}/`,
        headers: { 'content-type': "application/json",
        'Authorization': `token ${props.token}`, },
      data: {
        location:location,
        shortlocation:shortlocation,
        description:description,
        }
    })
    .then(res=>{
        if(res.status===200){
            updateid(id);
            props.referesh();
            props.closebtn();
        }
    })
     .catch(err=>console.log("Task move to readytobill error", err.response.request.response))
  }
}


return(
    <React.Fragment>
         <button className="x" onClick={props.closebtn}>
            x
            </button>
            <div className="header" style={{color:"black"}}> 
             Add/Edit Location
            </div>
            <div className="Scroll-Content">
            <Row>
                <Col md="5">
                    <label style={{color:"black",paddingLeft:10}}>Location</label>
                    <Input 
                    style={{border:validlocation}}
                    type="text"
                    className="form-control-alternative"
                    value={location}
                    onChange={changeLocation}
                    />
                    {validlocation==='1px solid #fb6340'?<h5 style={{color:"#fb6340"}}>Please Enter Location</h5>:null}
                </Col>
                <Col md="5">
                    <label style={{color:"black",paddingLeft:10}}>Short Location</label>
                    <Input 
                    style={{border:validshortlocation}}
                    type="text"
                    className="form-control-alternative"
                    value={shortlocation}
                    onChange={changeShortLocation}
                    />
                    {validshortlocation==='1px solid #fb6340'?<h5 style={{color:"#fb6340"}}>Please Enter ShortLocation</h5>:null}
                </Col>
            </Row>
            <Row>
            <Col md="5">
                    <label style={{color:"black",paddingLeft:10}}>Godown</label>
                    <Input 
                    style={{border:validshortlocation}}
                    type="text"
                    className="form-control-alternative"
                    value={godown}
                    onChange={changeGodown}
                    />
                    {validshortlocation==='1px solid #fb6340'?<h5 style={{color:"#fb6340"}}>Please Enter ShortLocation</h5>:null}
                </Col>
                <Col md="5">
                <label style={{color:"black",paddingLeft:10}}>Description</label>
                    <Input 
                    style={{border:"1px solid #dee2e6"}}
                    type="text"
                    className="form-control-alternative"
                    value={description}
                    onChange={changeLocationDescription}
                    />
                </Col>
                <Col md="1">
                <button type="button" class="btn btn-dark" onClick={isValidation} style={{marginTop:30}}>Submit</button>
                </Col>
            </Row>
            <br />
            </div>
    </React.Fragment>
)
}

const mapStateToProps = state => {
    return {
      token:state.token, 
    }
  }
  
  export default connect(mapStateToProps, null)(AddEditLocation);