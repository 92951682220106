import React, { Component } from 'react'
import ManageUsersRow from "./ManageUsersRow";
import {
  Container, 
  Card,
  Row,
  Table,
} from "reactstrap";
import Header from "./../../../components/Headers/SimpleHeader";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import { connect } from "react-redux";
import config from "react-global-configuration";
import '../../../config';
import UserAccessControl from './UserAccessControl';

const authApi = config.get('apiDomain');

class ManageUsers extends Component {
    constructor(props){
          super(props);
          this.state={
              items:[],
              loading : true,
              showuserAccesspanel:false,
              Editdata:[],
          }
    }

componentDidMount(){
  fetch(`${authApi}users/`, {
    method: 'GET',
    headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
    }
     })
      .then(res => res.json())
      .then(json => {
             this.setState({
                 items: json,
                 loading: false
             })
         }) 
    }

  Callbackfunction=()=>{
    fetch(`${authApi}users/`, {
    method: 'GET',
    headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
    }
    })
    .then(res => res.json())
    .then(json => {
     this.setState({
         items: json,
         loading: false
     })
    })
  }

  showuserAccessPanelScreen=(data)=>{
          this.setState({
            Editdata:data,
            showuserAccesspanel:true,
          })
  }

hideEditdata=(data)=>{
  this.setState({
    Editdata:[],
    showuserAccesspanel:false,
  },function(){
    this.Callbackfunction();
  })
}

render() {
    const ChecklistData = [...this.state.items].reverse().map((item) => (
        <ManageUsersRow
            key = {item.id}
            id = {item.id}
            rowdata={item}
            username = {item.name}
            email_id = {item.mailid}
            IsAdmin = {item.isadmin}
            IsApproved = {item.isapproved}
            default_rate={item.default_rate}
            superuser={item.superuser}
            profile={item.userprofile}
            Refreshpage={this.Callbackfunction}
            showuserAccessPanelScreen={this.showuserAccessPanelScreen}
        />
    ));

      return (
        <React.Fragment>  
       <Header />
       {this.state.showuserAccesspanel ===false ? <Container className="mt--7" fluid>
            <br /><br /><br /><br />
          { this.state.loading === true ? <div> <ConPreLoader /> </div> :
          <Row>
            <div className=" col">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">  Action</th>
                      <th className="text-center">  Username</th>
                    
                      <th className="text-center"> IsApprove ? </th>
                      <th className="text-center"> IsAdmin ? </th>
                
                      <th className="text-center">Task</th>
                      <th className="text-center">Admin</th>
                      <th className="text-center">Report</th>
                      <th className="text-center">Sales</th>
                      <th className="text-center">Purchase</th>
                      <th className="text-center">Voucher</th>
                      <th className="text-center">Default Rate </th>
                    
                    </tr>
                  </thead> 
                  <tbody>{ChecklistData}</tbody> 
                </Table>
              </Card>
            </div>
          </Row>
          }
        </Container>:
        <UserAccessControl
        hideEditdata={this.hideEditdata}
        Editdata={this.state.Editdata}
        />
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    token:state.token, 
    allusers:state.allusers,
    username:state.username
  }
}
export default connect(mapStateToProps, null)(ManageUsers);