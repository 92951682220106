import React from "react";
import "./MydayRow.css";
import { connect } from "react-redux";
import config from "react-global-configuration";
import axios from "axios";
import "../../../config";

const authApi = config.get("apiDomain");

class MydayRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ChangeSubtaskTime: this.props.totalsubtask.subtaskTime,
      ChangeTime: this.props.totalsubtask.subtaskTime,
      ChangeStarttime: this.props.totalsubtask.task_starttime,
      ChangeClientid: this.props.totalsubtask.client_id,
      subtaskDetails: this.props.totalsubtask,
      report: "",
      check: "",
      Maintaskdetails: [],
      playTaskButton: this.props.isDisabled,
      Client: "",
      Client_Json: [],
      favourited: false,
      fav: 0,
    };
    this.currentTaskButton = this.currentTaskButton.bind(this);
  }

  componentDidMount() {
    if (parseInt(this.state.subtaskDetails.favourites) === 1) {
      this.setState({
        favourited: true,
        fav: 1,
      });
    }
    if (this.state.subtaskDetails.status === "InProgress") {
      this.props.taskOpenButton(this.state);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.totalsubtask.id,
      ChangeSubtaskTime: nextProps.totalsubtask.subtaskTime,
      ChangeTime: nextProps.totalsubtask.subtaskTime,
      ChangeStarttime: nextProps.totalsubtask.task_starttime,
      subtaskDetails: nextProps.totalsubtask,
      playTaskButton: nextProps.isDisabled,
    });
    if ( this.state.subtaskDetails.status === "InProgress") {
      this.setState({
        playTaskButton: false,
      });
    } else {
      this.setState({
        playTaskButton: nextProps.isDisabled,
      });
    }
  }

  currentTaskButton = async () => {
    // this.updateProjectStatus();
    this.setState(
      {
        report: false,
        taskfunction: !this.state.taskfunction,
        playTaskButton: !this.state.playTaskButton,
      },
      function () {
        this.props.taskOpenButton(this.state);
      }
    );
  };

  favouriteButton = (e) => {
    if (this.state.fav === 1) {
      this.setState(
        {
          favourited: false,
          fav: 0,
        },
        function () {
          this.updateFavourites();
        }
      );
    } else {
      this.setState(
        {
          favourited: true,
          fav: 1,
        },
        function () {
          this.updateFavourites();
        }
      );
    }
  };

  updateFavourites = () => {
    axios({
      method: "put",
      url: `${authApi}subtasks/${this.props.totalsubtask.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        favourites: this.state.fav,
        tasklist: this.props.totalsubtask.tasklist_id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.callbackfunction();
        }
      })
      .catch((err) => {
        alert(err);
        this.callbackfunction();
      });
  };
  callbackfunction = () => {
    this.props.rerender("TaskDetails");
  };

  render() {
    return (
      <tr>
        <td className="text-center">
          {this.state.playTaskButton ? (
            <>
              <button
                className="btn  btn-sm"
                value={this.state.fav}
                onClick={this.favouriteButton}
                style={{
                  fontSize: "17px",
                  color: this.state.fav === 1 ? "red" : "grey",
                }}
              >
                <i class="fa fa-heart" aria-hidden="true" />
              </button>
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
                onClick={this.currentTaskButton}
              >
                <i className="fa fa-play" />
              </button>
            </>
          ) : null}
        </td>
        <td className="text-center">
          <label className="Todo-Details">{this.state.subtaskDetails.clientname}</label>
        </td>
        <td className="text-center">
          <label className="Todo-Details">{this.state.subtaskDetails.plantype}</label>
        </td>
        <td className="text-center">
          <label className="Todo-Details">{this.state.subtaskDetails.tasklist}</label>
        </td>
        <td className="text-center">
          <label className="Todo-Details">{this.state.subtaskDetails.subtask}</label>
        </td>
        <td className="text-center">
          <label>{this.state.subtaskDetails.status}</label>
        </td>
        <td className="text-center">
          <label>{this.state.subtaskDetails.subtaskTime}</label>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(MydayRow);
