import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import consoleFunction from "../../Helpers";
import axios from 'axios';
import { connect } from "react-redux";
import UserHeader from "../../components/Headers/ProfileHeader";

import config from "react-global-configuration";
import '../../config';

const authApi = config.get('apiDomain');

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id:"",
      username:this.props.Username,
      email:this.props.Email,
      fristname:"",
      lastname:"",
      address:"",
      city:"",
      country:"",
      postalcode:"",
      aboutme:"",
      items:[],
      update:"",
      error: ''
    }
  } 
  componentDidMount () {
    fetch(`${authApi}userdetailfilter/?username=${this.props.Username}&email=${this.props.Email}`, {
    method: 'GET',
    headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
   }
   })
  .then(res => res.json())
  .then(json => {
      this.setState({
          items: json,
      },function(){
        this.Variableupdate();
      })
  })
}
Variableupdate=()=>{
  this.state.items.map((item)=>(
    this.setState({
    id:item.id,
    username:item.username,
    email:item.email,
    fristname:item.fristname,
    lastname:item.lastname,
    address:item.address,
    city:item.city,
    country:item.country,
    postalcode:item.postalcode,
    aboutme:item.aboutme,
  })))
}

handleProfile_Name=(event)=>{
      this.setState({
        username:event.target.value,
     })
  }

  handleProfile_Mail=(event)=>{
    this.setState({
      email:event.target.value,
    })
  }
  handleProfile_Fname=(event)=>{
    this.setState({
      fristname:event.target.value,
    })
  }
  handleProfile_Lname=(event)=>{
    this.setState({
      lastname:event.target.value,
    })
  }
  handleProfile_Address=(event)=>{
    this.setState({
      address:event.target.value,
    })
  }
  handleProfile_City=(event)=>{
    this.setState({
      city:event.target.value,
    })
  }
  handleProfile_Country=(event)=>{
    this.setState({
      country:event.target.value,
    })
  }
  handleProfile_Code=(event)=>{
     this.setState({
      postalcode:event.target.value,
     })
  }
  handleProfile_About=(event)=>{
    this.setState({
      aboutme:event.target.value,
    })
  }
  saveProfile=()=>{
    if(this.state.id === ""){
      axios({
        method: "post",
        url: `${authApi}userdetail/`,
        headers: { 'content-type': "application/json",
        'Authorization': `token ${this.props.token}`, },
        data: {
      username:this.state.username,
      email:this.state.email,
      fristname:this.state.fristname,
      lastname:this.state.lastname,
      address:this.state.address,
      city:this.state.city,
      country:this.state.country,
      postalcode:this.state.postalcode,
      aboutme:this.state.aboutme,
        }
  })
      .then(res => {
          if (res.status === 201) {
              this.setState({
                    update:"ok",
              });
          }
      })
      .catch(err => {
        consoleFunction("Profile details missing error", err.response.request.response)
        this.setState({ error: err.response.request.response });
      });
  }
  else{
    axios({
      method: "put",
      url: `${authApi}userdetail/${this.state.id}/`,
      headers: { 'content-type': "application/json",
      'Authorization': `token ${this.props.token}`, },
      data: {
      username:this.state.username,
      email:this.state.email,
      fristname:this.state.fristname,
      lastname:this.state.lastname,
      address:this.state.address,
      city:this.state.city,
      country:this.state.country,
      postalcode:this.state.postalcode,
      aboutme:this.state.aboutme,
      }
    })
    .then(res=>{
      if(res === 200)
      this.setState({
        update:"ok",
      });
    })
    .catch(err => {
      consoleFunction("Profile details updation error " , err.message)
      this.setState({ error: err.message });
    });
  }
}
  render() {
    return (
      <React.Fragment>
        <UserHeader />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Settings
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.props.Username}
                              onChange={this.handleProfile_Name}
                              id="input-username"
                              placeholder="Username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={this.props.Email}
                              onChange={this.handleProfile_Mail}
                              id="input-email"
                              placeholder=""
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.fristname}
                              onChange={this.handleProfile_Fname}
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.lastname}
                              onChange={this.handleProfile_Lname}
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.address}
                              onChange={this.handleProfile_Address}
                              id="input-address"
                              placeholder="Home Address"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.city}
                              onChange={this.handleProfile_City}
                              id="input-city"
                              placeholder="City"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                            </label>
                            <Input
                              className="form-control-alternative"
                              onChange={this.handleProfile_Country}
                              defaultValue={this.state.country}
                              id="input-country"
                              placeholder="Country"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Postal code
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              defaultValue={this.state.postalcode}
                              onChange={this.handleProfile_Code}
                              placeholder="Postal code"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                  
                    <h6 className="heading-small text-muted mb-4">About me</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          className="form-control-alternative"
                          placeholder="A few words about you ..."
                          onChange={this.handleProfile_About}
                          defaultValue={this.state.aboutme}
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </div>
                    <Button
                      className="mr-4"
                      color="info"
                      onClick={this.saveProfile}
                      size="sm"
                    >
                      Save Profile
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    Username: state.name,
    Email: state.email,
    Profile_Pic: state.profilepic,
    token:state.token,
  }
}

export default connect(mapStateToProps, null)(Profile);
