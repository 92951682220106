import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Invoice from "./Invoice";
import { Row, Card } from "reactstrap";
import "../../../../config";
import config from "react-global-configuration";
import { connect } from "react-redux";
import moment from "moment";

const authApi = config.get("apiDomain");
let group_data = [];

class InvoiceAppPS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceData: this.props.invoiceData,
      JsonObject: " ",
      startDate: "",
      endDate: "",
      loading: true,
      PresentDay: new Date(),
    };
  }

  componentDidMount() {
    this.datefunction();
    this.downloadFunction();
  }

  datefunction = () => {
    const a = moment(this.props.dateStart).format("YYYY-MM-DD");
    const b = moment(this.props.dateEnd).format("YYYY-MM-DD");
    this.setState({
      startDate: a,
      endDate: b,
    });
  };

  downloadFunction = () => {
    const Filtered_Data = this.props.invoiceData.filter((item) => {
      return item.invoicestatus !== "Cancelled";
    });
    this.setState({
      JsonObject: "",
    });
    this.GetTaskData(Filtered_Data.filter((i) => i.taskid !== null));
  };

  GetTaskData = (data) => {
    data.forEach((d) => {
      fetch(`${authApi}tasklistfilter/?id=${d.taskid}&isdeleted=0`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => this.updateJsonObject(json, d));
    });
  };

  updateJsonObject = (json, data) => {
    this.setState(
      {
        JsonObject: [
          ...this.state.JsonObject,
          {
            id: data.id,
            invoiceno: `${data.companycode}-${data.deliverynoteid}`,
            GenerateDate: moment(this.state.PresentDay).format("DD-MM-YYYY"),
            invoicedate: moment(data.date).format("DD-MM-YYYY"),
            client: data.company_name,
            amount_received: data.amount_received,
            balancedue: data.balancedue,
            discount: data.discount,
            duedate: data.duedate,
            invoice_amount: data.invoice_amount,
            invoicestatus: data.invoicestatus,
            subtotal: data.subtotal,
          },
        ],
        loading: true,
      },
      function () {
        this.multipleGroupByArray(this.state.JsonObject, (property) => [
          property.client,
        ]);
      }
    );
  };

  multipleGroupByArray = (dataArray, groupPropertyArray) => {
    const groups = {};
    dataArray.forEach((item) => {
      const group = JSON.stringify(groupPropertyArray(item));
      groups[group] = groups[group] || [];
      groups[group].push(item);
    });

    group_data = Object.keys(groups).map(function (group) {
      return groups[group];
    });
    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">Invoice Statement</div>
          <Row>
            <div className=" col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <Invoice
                      GenerateData={this.state.JsonObject}
                      group={group_data}
                      sD={this.state.startDate}
                      eD={this.state.endDate}
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(InvoiceAppPS);
