import React, { Component } from "react";
import "./Editrow.css";
import moment from "moment";
import axios from 'axios';
import {Row, Col, Input} from "reactstrap";
import config from "react-global-configuration";
import '../../../config';
import consoleFunction from "../../../Helpers";
import {
  AllClientDetails,
} from "../../../components/loginRedux/loginReducers";
import store from "../../../components/loginRedux/Store";
import { connect } from "react-redux";
const authApi = config.get('apiDomain');

class Editrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.EditRowSend.id,
      ChangeDate: this.props.EditRowSend.date,
      ChangeTask: this.props.EditRowSend.task,
      ChangeStart: moment(this.props.EditRowSend.startdate).format("YYYY-MM-DD"),
      ChangeEnd: moment(this.props.EditRowSend.enddate).format("YYYY-MM-DD"),
      ChangeAssign: this.props.EditRowSend.assignto,
      ChangePriority: this.props.EditRowSend.priority,
      ChangeStatus: this.props.EditRowSend.status,
      ChangeTime:this.props.EditRowSend.time,
      ChangeIsdeleted:this.props.EditRowSend.isdeleted,
      ChangePlanType:this.props.EditRowSend.plantype,
      subclient:this.props.EditRowSend.subclient,
      file_path:this.props.EditRowSend.file_path,
      advanceamount:this.props.EditRowSend.advanceamount=== "" ? 0:this.props.EditRowSend.advanceamount,
      phonenumber:this.props.EditRowSend.phonenumber,
      completed_date:this.props.EditRowSend.completed_date,
      clientname:this.props.EditRowSend.clientname,
      Phonenum: this.props.EditRowSend.phonenumber,
      ChangeDrawingTitle: this.props.EditRowSend.drawingtitle,
      ChangeCompanyId:this.props.EditRowSend.company,
      ChangeCompanyname:this.props.EditRowSend.companyname,
      clientid:[],
      id_value:this.props.EditRowSend.client,
      plan:[],
      currentuser:this.props.username,
      items:this.props.allusers,
      nickname:[],
      Client_id:"",
      client_alldetails:this.props.clientdetails,
      subclient_details:[],
      current_client_id:[],
      select_clientName:[],
      select_client_id:[],
      companydetails:[],
      planId:"",
      pgcaddTaskNames:[],
      todaydate:moment(new Date()).format("YYYYMMDD"),
      error:null,
      Maintaskid:"",
      enableprinting:0,
      border:"1px solid #dee2e6",
      userinform:0,
      clientidborder:"1px solid #dee2e6",
      palntypeborder:"1px solid #dee2e6",
      subclientborder:"1px solid #dee2e6",
      companyborder:"1px solid #dee2e6",
      isButtonDisabled: false
    };
  }

  componentDidMount(){
    let currentclient=this.props.clientdetails.filter((clientdetails)=>{
      return clientdetails.id === this.props.EditRowSend.client
    })
    this.setState({
      subclient_details: currentclient[0].clients,
    })
    this.checkprinting();
    this.PlanTypeFunction();
    this.CompanyDetails();
    this.filtereditclient();
}

checkprinting=()=>{
   if(this.state.clientname === "General-Printing" || this.state.clientname === "General" ){
   this.setState({
    enableprinting:1,
   })
   }
}

CompanyDetails=()=>{
  fetch(`${authApi}companyfilter/?isactive=1`, {
    method: 'GET',
    headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
    }
    })
    .then(res => res.json())
    .then(json => {
        this.setState({
          companydetails: json,
        })
    })
}

filtereditclient=()=>{
  if(this.state.id_value !== ""&& this.state.id_value !== undefined){
    let currentclientid=this.props.clientdetails.filter((clientdata)=>{
       return clientdata.id === this.state.id_value
    })
    this.setState({
      current_client_id: currentclientid,
    },function(){
      this.updatenickname();
    })
}
}

PlanTypeFunction=()=>{
  fetch(`${authApi}Plans/`, {
    method: 'GET',
    headers: {
    'content-type': "application/json",
    'Authorization': `token ${this.props.token}`,
    }
    })
    .then(res => res.json())
    .then(json => {
        this.setState({
            plan: json,
        })
    })
}
    
HandleChangeDate = (event) => {
    const a = event.target.value;
    this.setState({
      ChangeDate: a,
    });
};

HandleChangeTask = (event) => {
    const b = event.target.value;
    this.setState({
      ChangeTask: b,
    });
};

HandleChangeTime=(event)=>{
    this.setState({
      ChangeTime:event.target.value,
    })
}

HandleChangeAssign = (event) => {
    const c = event.target.value;
    this.setState({
      ChangeAssign: c,
    });
};

HandleChangePriority = (event) => {
    const d = event.target.value;
    this.setState({
      ChangePriority: d,
    });
};

HandleChangeStart = (event) => {
    const e = event.target.value;
    this.setState({
      ChangeStart: e,
    });
};

HandleChangeEnd = (event) => {
    const f = event.target.value;
    this.setState({
      ChangeEnd: f,
    });
};

HandleChangeStatus = (event) => {
    const g = event.target.value;
    this.setState({
      ChangeStatus: g,
    });
};

Formvalidation=()=>{
  if(this.state.id_value !== ""){
      if(this.state.ChangePlanType !== ""){
        if(this.state.subclient !==""){
          if(this.state.ChangeCompanyname !== ""){
            this.setState({
              isButtonDisabled: true
            })
            this.UpdationRow();
          }
          else{
            this.setState({
              companyborder:"1px solid #fb6340",
              subclientborder:"1px solid #dee2e6",
              clientidborder:"1px solid #dee2e6",
              palntypeborder:"1px solid #dee2e6",
            })
          }
        }
        else{
          this.setState({
            subclientborder:"1px solid #fb6340",
            clientidborder:"1px solid #dee2e6",
            palntypeborder:"1px solid #dee2e6",
          })
        }
      }
      else{
        this.setState({
          palntypeborder:"1px solid #fb6340",
          clientidborder:"1px solid #dee2e6",
        })
      }
  }
  else{
    this.setState({
      clientidborder:"1px solid #fb6340",
    })
  }
}



UpdationRow = () => {
  
     this.subclientUpdation();
    const id = this.state.id;
    const date = this.state.ChangeDate;
    const task = this.state.ChangeTask;
    const assignto = this.state.ChangeAssign;
    const periority = this.state.ChangePriority;
    const startdate = this.state.ChangeStart;
    const enddate = this.state.ChangeEnd;
    const status = this.state.ChangeStatus;
    const time = this.state.ChangeTime;
    const isdeleted = this.state.ChangeIsdeleted;
    const link = `${authApi}Tasklist/${id}/`;
    axios({
      method: "put",
      url: link,
      headers: { 'content-type': "application/json",
      'Authorization': `token ${this.props.token}`, },
      data: {
      date: date,
      task: task,
      assignto: assignto,
      priority: periority,
      startdate: startdate,
      enddate: enddate,
      status: status,
      time:time,
      plantype:this.state.ChangePlanType,
      isdeleted:isdeleted,
      subclient:this.state.subclient,
      task_starttime:"",
      client:this.state.id_value,
      file_path:this.state.file_path,
      completed_date:this.state.completed_date,
      advanceamount:this.state.advanceamount,
      phonenumber:this.state.phonenumber,
      clientname:this.state.clientname,
      drawingtitle:this.state.ChangeDrawingTitle,
      company:this.state.ChangeCompanyId,
      companyname:this.state.ChangeCompanyname
      }
      })
      .then(res => {
      if (res.status === 200) {
      this.props.closebtn();
      this.props.calbackfunction();
      }})
      .catch((err) => {
        consoleFunction("Task updation error", err.response.request.response);
        this.props.calbackfunction();
        this.setState({ error: err.response.request.response });
      });
};

subclientUpdation = () => {
  let subclient=this.state.subclient
  let nameSub = this.state.subclient_details.filter(function(item){
    return item.name === subclient
  });
  if(nameSub.length === 0){
    axios({
      method: "post",
      url: `${authApi}subclients/`,
      headers: { 'content-type': "application/json",
      'Authorization': `token ${this.props.token}`, },
      data: {
        name: this.state.subclient,
        phoneno: this.state.Phonenum,
        clients : this.state.id_value
      }
    })
    this.updateclientdetails();
  }
}

updateclientdetails=()=>{
  fetch(`${authApi}clientFilter/?isdeleted=0`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `token ${this.props.token}`,
    },
  })
    .then((res) => res.json())
    .then((json) => {
      localStorage.setItem(
        "clientdetails",
        JSON.stringify(json.sort((a, b) => a.clientid - b.clientid))
      );
      store.dispatch({
        type: AllClientDetails,
        data: json.sort((a, b) => a.clientid - b.clientid),
      });
    }); 
}

DefaultSubtask=()=>{
  const id= this.state.Maintaskid;
  const date = moment(this.state.todaydate).format("YYYY-MM-DD");
  const subtask ="Default";
  const assignto = this.state.ChangeAssign;
  const periority = this.state.ChangePriority;
  const status = this.state.ChangeStatus;
  const time = this.state.ChangeTime;
  const isdeleted = this.state.ChangeIsdeleted;
  if(this.state.ChangeStatus === "Printing"){
    axios({
      method: "post",
      url: `${authApi}subtasks/`,
      headers: { 'content-type': "application/json",
      'Authorization': `token ${this.props.token}`, },
      data: {
           date:date,
           subtask:"Printing",
           assignto:assignto,
           priority:periority,
           status:status,
           time:time,
           isdeleted:isdeleted,
           completed_date:"",
           task_starttime: this.state.completed_date,
           tasklist:id,
      }
    })
    .catch(err=>{
      consoleFunction("Default subtask updation error", err.response.request.response);
      this.props.calbackfunction();
    })
  }
  else{
  axios({
    method: "post",
    url: `${authApi}subtasks/`,
    headers: { 'content-type': "application/json",
    'Authorization': `token ${this.props.token}`, },
    data: {
         date:date,
         subtask:subtask,
         assignto:assignto,
         priority:periority,
         status:status,
         time:time,
         isdeleted:isdeleted,
         completed_date:"",
         task_starttime: this.state.completed_date,
         tasklist:id,
    }
  })
  .catch(err=>{
    consoleFunction("User subtask updation error",err.response.request.response);
    this.props.calbackfunction();
  })
}
}

selectClient_Name=(event)=>{
  if(event.target.value === "General-Printing" || this.state.clientname === "General" ){
    let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
    let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
      this.setState({
        enableprinting:1,
        ChangeStart: startdate,
        ChangeEnd: enddate,
      });
  }
  else{
    this.setState({
      enableprinting:0,
    });
  }
 const re_space_clientname=event.target.value.replace(/ /g,'%20');
 let selectedclient=this.props.clientdetails.filter((clientdetails)=>{
    return clientdetails.company_name === re_space_clientname
  })
  this.setState({
    select_clientName: selectedclient,
    Clientname:event.target.value,
  },function(){
    this.updateClientID();
  }
  )
}

updateClientID=()=>{
let plantype = this.state.ChangePlanType.substr(0,2);
  this.state.select_clientName.map((item)=>{
    this.setState({
      Client_id:item.clientid,
      subclient_details: item.clients,
      id_value:item.id,
      ChangeTask:this.state.Client_id.toString()+plantype+"_"+this.state.subclient+"_"+this.state.todaydate
    },function(){
      this.TaskUpdate();
    })
    return this.state.Client_id;
})
}

selectplantype=(event)=>{
  let plantype = this.state.ChangePlanType.substr(0,2);
   this.state.plan.map((item)=>{
    if(item.planname===this.state.ChangePlanType){
    this.setState({
      planId:item.id,
      ChangeTask:this.state.Client_id.toString()+plantype+"_"+this.state.subclient+"_"+this.state.todaydate
    })
  }
  return this.state.plan;
})
  this.setState({
      ChangePlanType:event.target.value,
  },function(){
    this.TaskUpdate();
  })
}

selectid=(event)=>{
  if(event.target.value === "00300" || event.target.value === "00100"){
    let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
    let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
      this.setState({
        enableprinting:1,
        ChangeStart: startdate,
        ChangeEnd: enddate,
      });
  }
  else{
    this.setState({
      enableprinting:0,
    });
  }
  let selected_id=event.target.value;
  if(selected_id !== ''){
    let currentclient=this.props.clientdetails.filter((clientdata)=>{
      return clientdata.id=selected_id
    })
    this.setState({
      select_client_id: currentclient,
      Client_id:event.target.value,
    },function(){
      this.SelectClient_nickname();
    })
}
else{
  this.setState({
    Client_id:event.target.value,
    Clientname:"",
    id_value:"",
   }) 
}
}

SelectClient_nickname=()=>{
  let plantype = this.state.ChangePlanType.substr(0,2);
  this.state.select_client_id.map((item)=>{
    this.setState({
        Clientname:item.company_name,
        subclient_details: item.clients,
        id_value:item.id,
        ChangeTask:this.state.Client_id.toString()+plantype+"_"+this.state.subclient+"_"+this.state.todaydate
    },function(){
      this.TaskUpdate();
     })
    return this.state.Clientname;
})
}

updatenickname=()=>{
   this.state.current_client_id.map((item)=>{
     if(item.clientid === "00300"){
      this.setState({
        Clientname:item.company_name,
        Client_id: item.clientid,
        enableprinting:1,
    })
     }
     else{
    this.setState({
        Clientname:item.company_name,
        Client_id: item.clientid,
    })
     }
    return this.state.Clientname;
})
}

handlesubclient=(event)=>{
 this.setState({
  subclient:event.target.value,
  Phonenum:"",
  phonenumber:""
 },function(){
  this.PhonenumberUpd();
  this.TaskUpdate();
 })
}

PhonenumberUpd = () => {
  this.state.subclient_details.forEach((ph) => {
    if(ph.name === this.state.subclient){
      this.setState({
        Phonenum:ph.phoneno,
        phonenumber:ph.phoneno,
      })
    }
  })
}

TaskUpdate=()=>{
  let plantype = this.state.ChangePlanType.substr(0,2);
  let task=this.state.Client_id.toString()+plantype+"_"+this.state.subclient+"_"+this.state.ChangeDrawingTitle+"_"+this.state.todaydate;
  this.setState({
    ChangeTask:task,
  })
}

HandleAdvanceAmount=(event)=>{
  this.setState({
    advanceamount:event.target.value,
  })
}

HandlePhonenum=(event)=>{
  this.setState({
    Phonenum:event.target.value,
    phonenumber:event.target.value,
  })
}

HandleDrawingTitle =(event)=>{
  this.setState({
    drawingTitle: event.target.value
  },function(){
    this.TaskUpdate();
   })
}

HandleCompany = (event) => {
  event.persist();
  this.setState({
    ChangeCompanyname:event.target.value
  })
  fetch(`${authApi}companyfilter/?companyname=${event.target.value}`, {
      method: 'GET',
      headers: {
      'content-type': "application/json",
      'Authorization': `token ${this.props.token}`,
      }
      })
      .then(res => res.json())
      .then(json => {
        if(json.length!==0){
          this.setState({
            ChangeCompanyId:json[0].id,
          })
        }
      })
}

render() {
    return (
      <React.Fragment>
            <button className="x" onClick={this.props.closebtn}>
            x
            </button>
            <div className="header">
              Edit Details
            </div>
            <div className="Scroll-Content">
            <Row>
              <Col md="4">
                  <label className="form-control-label">
                  Client ID
                  </label>
                  <Input type="option" 
                  list="id_value-list"
                  className="form-control-alternative"
                  value={this.state.Client_id}
                  style={{border:this.state.clientidborder}}
                  onChange={this.selectid} />
                  <datalist id="id_value-list">
                   {this.state.client_alldetails.map((item, key) =>
                   <option key={key} value={item.clientid}/>
                    )}
                  </datalist>
                  {this.state.clientidborder !=="1px solid #dee2e6" ? <h5 style={{color:"#fb6340"}}>Please select clientid</h5>:null}
              </Col>
              <Col md="4">
                <label className="form-control-label">
                Client Name :
                </label>
                <Input type="option" 
                list="nickname-list"
                className="form-control-alternative"
                defaultValue={this.state.Clientname}
                style={{border:this.state.clientidborder}}
                onChange={this.selectClient_Name} />
                <datalist id="nickname-list">
                 {this.state.client_alldetails.map((item, name) =>
                 <option key={name} value={item.company_name}/>
                  )}
                </datalist>
                {this.state.clientidborder !=="1px solid #dee2e6" ? <h5 style={{color:"#fb6340"}}>Please select name</h5>:null}
              </Col>
              <Col md="4">
                <label className="form-control-label">
                  AssignTo
                  </label>
                  <Input 
                    type="select"
                    className="form-control-alternative"
                    style={{ border: "1px solid #dee2e6" }}
                    value={this.state.ChangeAssign}
                    onChange={this.HandleChangeAssign}
                  >
                   <option>Select</option>
                     {
                       this.state.items.map((obj, assign) => 
                       (<option key={assign} value={obj.name}>{obj.name}</option>))
                     }
                  </Input>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                  <label className="form-control-label">
                  Plan type :
                  </label>
                  <Input type="option" 
                  list="models-list"
                  className="form-control-alternative"
                  defaultValue={this.state.ChangePlanType}
                  style={{border:this.state.palntypeborder}}
                  onChange={this.selectplantype} />
                  <datalist id="models-list">
                   {this.state.plan.map((item, plan) =>
                   <option key={plan} value={item.planname} newvalue={item.id}/>
                    )}
                  </datalist>
                  {this.state.palntypeborder !=="1px solid #dee2e6" ? <h5 style={{color:"#fb6340"}}>Please select plantype</h5>:null}
                </Col>
              <Col md="4">
               <label className="form-control-label">
               Subclient :
               </label>
               <Input
                 type="option" 
                 list="subclient-list"
                 className="form-control-alternative"
                 value={this.state.subclient}
                 style={{border:this.state.subclientborder}}
                 onChange={this.handlesubclient}
               />
               <datalist id="subclient-list">
                {this.state.subclient_details.map((item, name) =>
                  <option key={name} value={item.name} />
                )}
              </datalist>
              {this.state.subclientborder !== "1px solid #dee2e6" ? <h5 style={{color:"#fb6340"}}>Please enter subclient</h5>:null}
           </Col>
           <Col md="4">
              <label className="form-control-label">
                Phonenumber
                </label>
                <Input
                  className="form-control-alternative"
                  style={{ border: "1px solid #dee2e6" }}
                  type="text"
                  value={this.state.Phonenum}
                  onChange={this.HandlePhonenum}
                />
            </Col>
         </Row>
          <Row>
            <Col md="2">
                <label className="form-control-label">
                  Status
                </label>
                  <Input 
                    type="select"
                    className="form-control-alternative"
                    style={{ border: "1px solid #dee2e6" }}
                    defaultValue={this.state.ChangeStatus}
                    onChange={this.HandleChangeStatus}
                  >
                    <option value="0">Select</option>
                    <option value="ToDo">ToDo</option>
                    <option value="Pending">Pending</option>
                    <option value="InProgress">InProgress </option>
                    <option value="Completed">Completed</option>
                  </Input>
              </Col>
              <Col md="2">
                <label className="form-control-label">
                  Priority
                </label>
                  <Input 
                    type="select"
                    className="form-control-alternative"
                    style={{ border: "1px solid #dee2e6" }}
                    defaultValue={this.state.ChangePriority}
                    onChange={this.HandleChangePriority}
                  >
                    <option value="0">Select</option>
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                  </Input>
              </Col>
              <Col md="4">
            <label className="form-control-label">
              Start Date :
              </label>
              <Input
                className="form-control-alternative"
                style={{ border: "1px solid #dee2e6" }}
                type="date"
                defaultValue={this.state.ChangeStart}
                onChange={this.HandleChangeStart}
              />
            </Col>
              <Col md="4">
                <label className="form-control-label">
                  End Date :
                  </label>
                  <Input
                    className="form-control-alternative"
                    style={{ border: "1px solid #dee2e6" }}
                    type="date"
                    defaultValue={this.state.ChangeEnd}
                    onChange={this.HandleChangeEnd}
                  />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <label className="form-control-label">
                  Company
                  </label>
                  <Input type="select" 
                  className="form-control-alternative"
                  value={this.state.ChangeCompanyname}
                  style={{border:this.state.companyborder}}
                  onChange={this.HandleCompany} 
                  >
                   <option value="">Select Company</option>
                   {this.state.companydetails.map((item, key) =>
                    <option key={key} value={item.companyname}>{item.companyname}</option>
                    )}
                  </Input>
                  {this.state.companyborder !=="1px solid #dee2e6" ? <h5 style={{color:"#fb6340"}}>Please select companyname</h5>:null}
              </Col>
              <Col md="6">
                  <label className="form-control-label">
                  Task Name :
                  </label>
                  <Input type="option" 
                  list="id_value-lists"
                  className="form-control-alternative"
                  value={this.state.ChangeTask}
                  style={{ border: "1px solid #dee2e6" }}
                  onChange={this.HandleChangeTask} />
                  <datalist id="id_value-lists">
                   {this.state.pgcaddTaskNames.map((item, key) =>
                   <option key={key} value={item.task}/>
                    )}
                  </datalist>
              </Col>
              <Col md="2" style={{textAlign:"center"}}>
              <label className="form-control-label">
              &nbsp;&nbsp;
                <button id="buttonsave" className="btn btn-dark" onClick={this.Formvalidation} disabled={this.state.isButtonDisabled}>
                  Save
                </button>
              </label>
              </Col>
            </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    token:state.token,
    username:state.username,
    clientdetails: state.clientdetails,
    allusers: state.allusers
  }
}
export default connect(mapStateToProps, null)(Editrow);