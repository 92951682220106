import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/pgcadd-dashboard.scss";

import * as firebase from "firebase/app";
import "firebase/auth";

import Admin from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";

import {
  refreshLogin,
  Usertoken,
  AllClientDetails,
  AllUsers,
} from "./components/loginRedux/loginReducers";
import PreLoader from "./components/Loader/Loader";

import { Provider } from "react-redux";
import store from "./components/loginRedux/Store";

import config from "react-global-configuration";
import "./config";

const authApi = config.get("apiDomain");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authendicated: false,
      loading: true,
      admin: "",
      CurrentUser: "",
    };
  }

  componentDidMount() {
    this.removeListener = firebase.auth().onAuthStateChanged((user) => {
      if (user && user.displayName) {
        localStorage.setItem("username", user.displayName.replace(/ /g, "%20"));
        store.dispatch({ type: refreshLogin, detail: user });
        this.setState(
          {
            authendicated: true,
            loading: false,
            CurrentUser: user.displayName,
          },
          function () {
            this.getAllUsers(user.displayName);
          }
        );
      } else {
        this.setState({
          authendicated: false,
          loading: false,
        });
      }
    });
  }

  getAllUsers = (currentusername) => {
    let token = localStorage.getItem("token");
    store.dispatch({ type: Usertoken, detail: token });
    this.getClientDetails(token);
    fetch(`${authApi}userfilter/?isdeleted=0&isapproved=1`)
      .then((res) => res.json())
      .then((json) => {
        store.dispatch({ type: AllUsers, detail: json });
        localStorage.setItem("userdetails", JSON.stringify(json));
        let currentuser = json.filter((userdetails) => {
          return userdetails.name === currentusername;
        });
        this.setState({
          admin: currentuser[0].isadmin,
        });
      });
  };

  getClientDetails = (token) => {
    fetch(`${authApi}clientFilter/?isdeleted=0`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        localStorage.setItem(
          "clientdetails",
          JSON.stringify(json.sort((a, b) => a.clientid - b.clientid))
        );
        store.dispatch({
          type: AllClientDetails,
          data: json.sort((a, b) => a.clientid - b.clientid),
        });
      });
  };

  componentWillUnmount() {
  }

  render() {

    return this.state.loading === true ? (
      <div>
        <PreLoader />
      </div>
    ) : (
      <HashRouter>
        <Switch>
          <Provider store={store}>
            <Route
              render={(props) =>
                this.state.authendicated === true ? (
                  <Admin {...props} Users={this.state.admin} />
                ) : (
                  <Redirect to="/auth/login" />
                )
              }
            />
            <Route
              render={(props) =>
                this.state.authendicated === false ? (
                  <AuthLayout {...props} Users={this.state.admin} />
                ) : (
                  <Redirect to="/admin/clientpanel" />
                )
              }
            />
          </Provider>
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
