import React, { useEffect, useState, useMemo } from 'react';
import { Container,Row,Form, FormGroup,NavLink } from 'reactstrap';

import TableContainerExpand from '../../Filters/TableContainerExpand';
import TableContainerExpandTeam from '../../Filters/TableContainerExpandTeam';
import { SelectColumnFilter } from '../../Filters/filters';
import {connect} from 'react-redux';
import config from "react-global-configuration";
import '../../../../config';
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Header from '../../../../components/Headers/SimpleHeader';

const authApi = config.get('apiDomain');
function Bills(props) {
 
 const [Purchagesdata,storePurchagesData]=useState([]);
 const [q, setQ] = useState("");
 const [loading, setLoading] = useState(true);
 const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm")
 const [checked, setChecked] = useState(true);

useEffect(()=>{
const getPurchagesDetails=async()=>{
  const response = await fetch(`${authApi}purchaseinvoicebillfilter/`, {
    method: 'GET',
    headers: {
      'content-type': "application/json", 
      'Authorization': `token ${props.token}`,
    }
  })
  let body = await response.json();
  if(body.length !== 0)
  {
    let companyfulldetails=await getCompanyName();
    body.forEach(oreder => {
      companyfulldetails.forEach(company => {
        if(parseInt(oreder.company)===company.id){
          oreder.company=company.companyname;
        }
      });
    });

    body.forEach(order => {
     props.clientdetails.forEach(client => {
        if(parseInt(order.supplier)===client.id){
          order.supplier=client.company_name;
        }
      });
    });
    setLoading(false);
    storePurchagesData(body.reverse());
  }
  else{
    setLoading(false);
  }
}
getPurchagesDetails();
 },
 // eslint-disable-next-line
 []
 )

 function getFilterButton(event){
  if(event === true ){
    setChecked(false)
    setBtnClass("btn btn-success btn-sm")
  }
  else{
    setChecked(true)
    setBtnClass("btn btn-danger btn-sm")
  }
}

const  getCompanyName=async()=>{
  const response = await fetch(`${authApi}companyfilter/`, {
    method: 'GET',
    headers: {
      'content-type': "application/json",
      'Authorization': `token ${props.token}`,
    }
  })
  const body = await response.json();
  return body;
 }

function search(rows) {
  const cols = rows[0] && Object.keys(rows[0]);
  return rows.filter((r) =>
    cols.some((c) => r[c] ? (r[c].toString().toLowerCase().indexOf((q).toLowerCase()) > -1 ) : null )
  );
}

 const columnSecondLayer = useMemo(
  () => [
    {
      Header: "StockName",
      accessor: "stockname",
    },
    {
      Header: "ProductCode",
      accessor: "productcode",
    },
    {
      Header: "Quantity",
      accessor: "quantity"
    },
    {
      Header: "Rate",
      accessor: "rate",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "CGST",
      accessor: "cgstpercentage",
    },
    {
      Header: "SGST",
      accessor: "sgstpercentage",
    },
    {
      Header: "Total",
      accessor: "total",
    },
  ],
  // eslint-disable-next-line
  []
);

const renderRowSubComponent = ( row ) => {

  const rowdata=row.original.purchaseinvoice;
   let stocks=[];
    stocks=rowdata.filter(function(item){
    return (item.name !== "Total"); 
   }); 
   rowdata.forEach(item => {
    if(item.name === "Total"){
      stocks=[...stocks,item]
    }
  });


  return (
    <React.Fragment>
          <br />
            <h5 className="thead-light">Purchase Stocks Details :</h5>
              <TableContainerExpandTeam
                  columns={columnSecondLayer}
                  data={stocks}
                />
            <br />
      </React.Fragment>
  );
};

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <i className="ni ni-bold-down" /> : <i className="ni ni-bold-right" /> }
          </span>
        )
      },
      { 
        Header:"Actions",
        Cell: (row) => {
            const row_data = row.row.original;
            return (
            <React.Fragment>
              <button className = "btn btn-primary btn-sm" style = {{ width:30, height:28 }}>
                <NavLink
                  style = {{ marginTop: -5 }}
                  to={{
                    pathname:"/admin/management/view-bills",
                    state: {billsData : row_data}  
                  }}
                  tag={Link}
                >
                    <i style = {{ marginLeft: -13, marginBottom: 500 }} className="fa fa-eye text-white" />
                </NavLink>
              </button> 
            </React.Fragment>
         
            );
        }
      },
      {
        Header: "Purchase Id",
        accessor: "id",
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: "Company Name",
        accessor: "company",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: "Supplier",
        accessor: "supplier",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: "Total",
        accessor: "amount",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        }
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Header />
        <Container className="mt--7" fluid><br />
          <Row>
            <Form className="searchForm" style = {{ marginTop : 14, right:"87px"}}>
              <FormGroup className="mb-0">
                <input className="searchBox " type="search" placeholder="Search" value={q} onChange={(e) => setQ(e.target.value)} />
              </FormGroup>
            </Form>
            <div className="invoicegenerate" style = {{ position:'absolute', right:'15px', marginTop : 16 }} >
              <button className={btnClass} style = {{ width:'135%', height:40 }} onClick={(e) => getFilterButton(checked)}>
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </Row>
          { loading === true ? <div> <ConPreLoader /></div> :
          <Row style={{marginTop : 80}}>
            <div className=" col">
              <ReactTooltip place="top" type="dark" effect="float" />
                <TableContainerExpand
                  columns={columns}
                  checkedToggle = {checked}
                  data={search(Purchagesdata)}
                  renderRowSubComponent={renderRowSubComponent}
                />
            </div>
          </Row>
          }
          <br />
          <br /><br />
        </Container>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    token:state.token,
    clientdetails:state.clientdetails
  }
}
export default connect(mapStateToProps, null)(Bills);