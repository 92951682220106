import React from "react";
import {connect} from 'react-redux';
import {Row,Col} from 'reactstrap';
import axios from "axios";
import config from "react-global-configuration";
import '../../../config';
const authApi = config.get('apiDomain');

class ProjectCompleted extends React.Component{
    constructor(props){
        super(props);
        this.state={
         status_check:0,
        }
    }

componentDidMount(){
    this.statusCheck();
}

statusCheck=()=>{
    let count=0
    this.props.projectdata.taskproject.map((items)=>{
        if(items.status!=='Completed'){
         count=+1
        }
     });
    this.setState({
        status_check:count
    });
}
MovetoCompletedStatus=()=>{
    if (this.state.status_check===0){
    axios({
        method:"put",
        url:`${authApi}project/${this.props.projectdata.id}/`,
        headers:{'content-type':"application/json",
            'Authorization': `token ${this.props.token}`,},
        data:{
            clientid: this.props.projectdata.clientid,
            clientname: this.props.projectdata.clientname,
            subclient: this.props.projectdata.subclient,
            phonenumber: this.props.projectdata.phonenumber,
            status:"Completed",
            company: this.props.projectdata.company,
            projectname: this.props.projectdata.projectname
        }
    })
    .then(res=>{ 
        if(res.status===200){
            this.props.calbackfunction();
        }
    })
    .catch(err=>{
        console.log(err);
    })
}
    else{
        alert('Some Tasks are still Pending')
    }

}

notCancelPurchase=()=>{
        this.props.closebtn();
}

    render(){
        return(
            <React.Fragment>
              <button className="x" onClick={this.props.closebtn}>
            x
            </button>
            <div className="header">
             Move to Completed Status
            </div>
            <Row style={{paddingTop:10}}>
                <Col md="3">
                </Col>
                <Col md="7"><h3>Do you want to completed the Project ?</h3></Col>
                <Col md="2"></Col>
            </Row>
            <Row style={{paddingBottom:10}}>
                <Col md="4"></Col>
                <Col md="1"><button type="button" class="btn btn-outline-success" onClick={this.MovetoCompletedStatus}>Yes</button></Col>
                <Col md="2" style={{paddingLeft:50}}><button type="button" class="btn btn-outline-danger" onClick={this.notCancelPurchase}>No</button></Col>
                <Col md="5"></Col>
            </Row>
            </React.Fragment>
        );

    }
}
const mapStateToProps = state => {
    return {
      token:state.token, 
    }
  }
export default connect(mapStateToProps, null)(ProjectCompleted);