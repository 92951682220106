import React from "react";
import {
    Input,
    Row,
    Col
} from "reactstrap";
import axios from 'axios';
import { connect } from "react-redux";
import config from "react-global-configuration";
import consoleFunction from "../../../../Helpers";
import '../../../../config';

const authApi = config.get('apiDomain');

class EditUnitgrp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.EditRowSend.id,
            unitgroupname: this.props.EditRowSend.unitname,
            unitgroupnameborder: "1px solid #dee2e6",
            isButtonDisabled: false
        }
    }

    FormValidation = () => {
        if (this.state.unitgroupname !== "") {
            this.setState({
                isButtonDisabled: true,
                unitgroupnameborder: "1px solid #dee2e6"
            })
            this.editunitgrpname();
        }
        else {
            this.setState({
                unitgroupnameborder: "1px solid #fb6340",
            })
        }
    }

    editunitgrpname = () => {
        axios({
            method: "put",
            url: `${authApi}unittype/${this.state.id}/`,
            headers: {
                'content-type': "application/json",
                'Authorization': `token ${this.props.token}`,
            },
            data: {
                unitname: this.state.unitgroupname,
            }
        })
            .then(res => {
                if (res.status === 200) {
                    this.props.closebtn();
                    this.props.callbackfunction();
                }
            })
            .catch((err) => {
                consoleFunction("Unit type updation error", err.response.request.response)
                this.setState({ error: err.message });
            });
    }

    handleUnitgroupName = (event) => {
        this.setState({
            unitgroupname: event.target.value.replace(/[^\w\s]/gi, ""),
        })
    }

    render() {
        return (
            <React.Fragment>
                <button className="x" onClick={this.props.closebtn}>
                    x
                </button>
                <div className="header">
                    Edit Unit Type
                </div>
                <div className="Scroll-Content">
                    <Row>
                        <Col md="6">
                            <label className="form-control-label">
                                Name
                            </label>
                            <Input
                                type="text"
                                className="form-control-alternative"
                                defaultValue={this.state.unitgroupname}
                                style={{ border: this.state.unitgroupnameborder }}
                                onChange={this.handleUnitgroupName}
                            />
                            {this.state.unitgroupnameborder !== "1px solid #dee2e6" ? <h5 style={{ color: "#fb6340" }}>Please enter groupname</h5> : null}
                        </Col>
                        <Col md='6'>
                            <div className="button">
                                <button id="buttonsave" className="btn btn-dark" style={{ float: "right", marginRight: 15 }} onClick={this.FormValidation} disabled={this.state.isButtonDisabled}>
                                    Done
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        token: state.token,
    }
}
export default connect(mapStateToProps, null)(EditUnitgrp);