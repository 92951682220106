import React from "react";
import axios from 'axios';
import consoleFunction from "../../../Helpers";
import config from "react-global-configuration";
import ReactTooltip from "react-tooltip";
import '../../../config';
import { connect } from "react-redux";
import { Input } from "reactstrap";
const authApi = config.get('apiDomain');

class ManageUsersRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        id: this.props.id,
        username: this.props.username,
        email_id: this.props.email_id,
        IsAdmin: this.props.IsAdmin ,
        IsApproved: this.props.IsApproved,
        default_rate:this.props.default_rate,
        superuser:this.props.superuser,
        error: null,
        OnClick:0,
        profile:this.props.profile,
        Userprofile:0,
    };
}

componentDidMount=()=>{
  if(this.state.profile === null){
    this.setState({
      profile:"null",
    })
  }
}

componentWillReceiveProps(props){
  this.setState({
  id: props.id,
  username: props.username,
  email_id: props.email_id,
  default_rate : props.default_rate,
  IsAdmin: props.IsAdmin ,
  IsApproved: props.IsApproved,
})
}

ApprovedRow = () => {
  axios({
    method: "put",
    url: `${authApi}users/${this.state.id}/`,
    headers: { 'content-type': "application/json",
    'Authorization': `token ${this.props.token}`, },
    data: {
    name: this.state.username,
    mailid: this.state.email_id,
    isadmin: this.state.IsAdmin,
    isapproved: "1",
    default_rate:this.state.default_rate,
    superuser:this.state.superuser,
    }
  })
    .then(res => {
      if (res.status === 200) {
        alert(this.state.username + " has been Approved.!");
        this.props.Refreshpage();
       ;
      }})
      .catch((err) => {
        consoleFunction("User approved error", err.response.request.response)
        this.props.Refreshpage();
        this.setState({ error: err.response.request.response });
      });
};

RejectRow = () => {
  axios({
    method: "put",
    url: `${authApi}users/${this.state.id}/`,
    headers: { 'content-type': "application/json",
    'Authorization': `token ${this.props.token}`, },
    data: {
      name: this.state.username,
      mailid: this.state.email_id,
      isadmin: this.state.IsAdmin,
      isapproved: "0",
    }
    })
      .then(res => {
        if (res.status === 200) {
          alert(this.state.username + " has been Rejected.!");
          this.props.Refreshpage();
        }})
        .catch((err) => {
          consoleFunction("User rejected error", err.response.request.response)
          this.props.Refreshpage();
          this.setState({ error: err.response.request.response });
        });
  };

  IsAdminRow = () => {
    axios({
      method: "put",
      url: `${authApi}users/${this.state.id}/`,
      headers: { 'content-type': "application/json",
      'Authorization': `token ${this.props.token}`, },
      data: {
      name: this.state.username,
      mailid: this.state.email_id,
      isadmin: "1",
      isapproved: this.state.IsApproved,
      }
    })
      .then(res => {
        if (res.status === 200) {
          alert(this.state.username + " has been Made as Admin.!");
          this.props.Refreshpage();
        }})
        .catch((err) => {
          consoleFunction("Make admin error", err.response.request.response)
          this.props.Refreshpage();
          this.setState({ error: err.response.request.response });
        });
  };

  IsClientRow = () => {
    axios({
      method: "put",
      url: `${authApi}users/${this.state.id}/`,
      headers: { 'content-type': "application/json",
      'Authorization': `token ${this.props.token}`, },
      data: {
      name: this.state.username,
      mailid: this.state.email_id,
      isadmin: "0",
      isapproved: this.state.IsApproved,
      }
    })
      .then(res => {
        if (res.status === 200) {
          alert(this.state.username + " has been Made as Client.!");
          this.props.Refreshpage();
        }})
        .catch((err) => {
          consoleFunction("Make client error", err.response.request.response)
          this.props.Refreshpage();
          this.setState({ error: err.response.request.response });
        });
  };

  UpdateChangeOnclick=()=>{
   this.setState({
    OnClick:1,
   })
  }

updatedefaultrate=(event)=>{
this.setState({
  default_rate:event.target.value,
})
  }

UpdateOnclickdisable=()=>{
  this.setState({
    OnClick:0,
   },function(){
    axios({
      method: "put",
      url: `${authApi}users/${this.state.id}/`,
      headers: { 'content-type': "application/json",
      'Authorization': `token ${this.props.token}`, },
      data: {
        name: this.state.username,
        mailid: this.state.email_id,
        isadmin: this.state.IsAdmin,
        isapproved: this.state.isapproved,
        default_rate:this.state.default_rate,
        superuser:this.state.superuser,
        userprofile:this.state.profile,
      }
      })
      .then(res=>{
        if(res.status=== 200){
          this.props.Refreshpage();
        }
      })
  })
}

UpdateUserProfilevalue=()=>{
  this.setState({
    Userprofile:1,
  })
}

ChangeProfile=(event)=>{
this.setState({
  profile:event.target.value,
})
}
Updateuserprofile=(event)=>{
  this.setState({
    Userprofile:0,
  },function(){
    axios({
      method: "put",
      url: `${authApi}users/${this.state.id}/`,
      headers: { 'content-type': "application/json",
      'Authorization': `token ${this.props.token}`, },
      data: {
        name: this.state.username,
        mailid: this.state.email_id,
        isadmin: this.state.IsAdmin,
        isapproved: this.state.isapproved,
        default_rate:this.state.default_rate,
        superuser:this.state.superuser,
        userprofile:this.state.profile,
      }
      })
      .then(res=>{
        if(res.status=== 200){
          this.props.Refreshpage();
        }
      })
  })
}

showAccesspanelEdit=(Data)=>{
  this.props.showuserAccessPanelScreen(Data);
}

render() {
    return (
      <tr>
        <td>
        <ReactTooltip id="addClients-Tip" place="right" type="dark" effect="solid">
                  User Control Panel
          </ReactTooltip>
          <button className="btn btn-primary btn-sm" id="addClients-Tip"
          data-tip data-for="addClients-Tip"
          onClick={()=>this.showAccesspanelEdit(this.props.rowdata)}
          >
          <i class="fa fa-edit" />
          </button>
        </td>
        <td className="text-center">
            <label className="Todo-Details">{this.state.username}</label>
        </td>
        <td className="text-center">
        <Input
                type="checkbox"
                style={{width:20,height:35,marginTop:-15}}
                checked={this.state.IsApproved=== "1" ? true:false}
              >
              </Input>
        </td>
        <td className="text-center">
        <Input
                type="checkbox"
                style={{width:20,height:35,marginTop:-15}}
                checked={this.state.IsAdmin === "1" ? true:false}
              >
        </Input>
        </td>
      <td className="text-center">
        <Input
                type="checkbox"
                style={{width:20,height:35,marginTop:-15}}
                checked={this.props.rowdata.task === "1" ? true:false}
              >
        </Input>
        </td>
        <td className="text-center">
        <Input
                type="checkbox"
                style={{width:20,height:35,marginTop:-15}}
                checked={this.props.rowdata.admin === "1" ? true:false}
              >
        </Input>
        </td>
        <td className="text-center">
        <Input
                type="checkbox"
                style={{width:20,height:35,marginTop:-15}}
                checked={this.props.rowdata.report === "1" ? true:false}
              >
        </Input>
        </td>
        <td className="text-center">
        <Input
                type="checkbox"
                style={{width:20,height:35,marginTop:-15}}
                checked={this.props.rowdata.sales === "1" ? true:false}
              >
        </Input>
        </td>
        <td className="text-center">
        <Input
                type="checkbox"
                style={{width:20,height:35,marginTop:-15}}
                checked={this.props.rowdata.purchase === "1" ? true:false}
              >
        </Input>
        </td>
        <td className="text-center">
        <Input
                type="checkbox"
                style={{width:20,height:35,marginTop:-15}}
                checked={this.props.rowdata.voucher === "1" ? true:false}
              >
        </Input>
        </td>
       {this.state.OnClick === 0 ?
        <td className="text-center">
            <label className="Todo-Details" onDoubleClick={this.UpdateChangeOnclick}>{this.state.default_rate}</label>
        </td>:
        <td className="text-center" onDoubleClick={this.UpdateOnclickdisable}>
           <input type="text" value={this.state.default_rate} onChange={this.updatedefaultrate} size="8"/>
        </td>}
      </tr>
    );
  }
}
const mapStateToProps = state => {
  return {
    token:state.token,
    allusers:state.allusers,
  }
}
export default connect(mapStateToProps, null)(ManageUsersRow);