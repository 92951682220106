import React, { Component } from 'react'
import axios from 'axios';
import { Row, Col, Input } from "reactstrap";
import "./Addsubtask.css";
import moment from "moment";
import { connect } from "react-redux";
import config from "react-global-configuration";
import '../../../config';
import consoleFunction from "../../../Helpers";

const authApi = config.get('apiDomain');

class Editsubtask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.EditRowSend.id,
      date: this.props.EditRowSend.date,
      subtask: this.props.EditRowSend.subtask,
      assignto: this.props.EditRowSend.assignto,
      priority: this.props.EditRowSend.priority,
      status: this.props.EditRowSend.status,
      time: this.props.EditRowSend.time,
      isdeleted: this.props.EditRowSend.isdeleted,
      completed_date: this.props.EditRowSend.completed_date,
      task_starttime: this.props.EditRowSend.task_starttime,
      client_id: this.props.EditRowSend.client_id,
      tasklist: this.props.EditRowSend.tasklist,
      items: this.props.allusers,
      maintask: [],
      subtaskborder: "1px solid #dee2e6",
      alreadyasignborder: "1px solid #dee2e6",
      alreadysubtask: false,
      isButtonDisabled: false
    }
  }

  componentDidMount() {
    this.fetchManitask();
  }

  fetchManitask = () => {
    fetch(`${authApi}Tasklist/${this.state.tasklist}`, {
      method: 'GET',
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      }
    })
      .then(res => res.json())
      .then(json => {
        this.setState({
          maintask: json,
        })
      })
  }

  MaintaskBendingStatus = () => {
    axios({
      method: "put",
      url: `${authApi}Tasklist/${this.state.tasklist}/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
      data: {
        date: this.state.maintask.date,
        task: this.state.maintask.task,
        assignto: this.state.maintask.assignto,
        priority: this.state.maintask.periority,
        startdate: this.state.maintask.startdate,
        enddate: this.state.maintask.enddate,
        status: "Pending",
        time: this.state.maintask.time,
        plantype: this.state.maintask.plantype,
        isdeleted: this.state.maintask.isdeleted,
        subclient: this.state.maintask.subclient,
        task_starttime: this.state.maintask.task_starttime,
        client: this.state.maintask.client,
        file_path: this.state.maintask.file_path,
        completed_date: this.state.maintask.completed_date,
        advanceamount: this.state.maintask.advanceamount,
        phonenumber: this.state.maintask.phonenumber,
        company: this.state.maintask.company,
        companyname: this.state.maintask.companyname
      }
    })
      .catch(err => {
        consoleFunction("Task move to pending error", err.response.request.response)
        this.props.functionreload(this.props.tt_client, this.props.tt_company, this.props.tt_assignto, this.props.tt_plantype, this.props.tt_status);
      })
  }

  FormValidation = () => {
    let subtask = this.state.subtask;
    if (subtask === "") {
      this.setState({
        subtaskborder: "1px solid #fb6340",
      })
    }
    else {
      this.setState({
        isButtonDisabled: true
      })
      this.UpdateSubtask();
    }
  }


  UpdateSubtask = () => {
    if (this.state.status !== "Completed") {
      this.MaintaskBendingStatus();
    }
    const date = moment(this.state.date).format("YYYY-MM-DD");
    const subtask = this.state.subtask;
    const assignto = this.state.assignto;
    const priority = this.state.priority;
    const status = this.state.status;
    const time = this.state.time;
    const isdeleted = "0";
    const completed_date = "";
    const tasklist = this.state.tasklist;
    axios({
      method: "put",
      url: `${authApi}subtasks/${this.state.id}/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
      data: {
        date: date,
        subtask: subtask,
        assignto: assignto,
        priority: priority,
        status: status,
        time: time,
        isdeleted: isdeleted,
        completed_date: completed_date,
        tasklist: tasklist,
      }
    })
      .then(res => {
        if (res.status === 200) {
          this.props.closebtn();
          this.props.functionreload(this.props.tt_client, this.props.tt_company, this.props.tt_assignto, this.props.tt_plantype, this.props.tt_status);
        }
      })
      .catch((err) => {
        consoleFunction("Update subtask error", err.response.request.response);
        this.props.functionreload(this.props.tt_client, this.props.tt_company, this.props.tt_assignto, this.props.tt_plantype, this.props.tt_status);
        this.setState({ error: err.response.request.response });
      });
  }

  HandleChangesubtask = (event) => {
    this.setState({
      subtask: event.target.value,
    })
  }

  HandleChangeAssignTo = (event) => {
    let sameassigneedata = this.state.maintask.subtask.filter(function (subtask) {
      return (subtask.assignto === event.target.value);
    })
    if (sameassigneedata.length !== 0) {
      this.setState({
        assignto: event.target.value,
        alreadysubtask: true,
        isButtonDisabled: true,
        alreadyasignborder: "1px solid #fb6340",
      })
    }
    else {
      this.setState({
        assignto: event.target.value,
        alreadysubtask: false,
        isButtonDisabled: false,
        alreadyasignborder: "1px solid #dee2e6"
      })
    }
  }

  HandleChangeStatus = (event) => {
    this.setState({
      status: event.target.value,
    }, function () {
      if (this.state.status === "Completed") {
        let length = this.state.maintask.subtask.length, count = 1;
        this.state.maintask.subtask.forEach(element => {
          if (element.status === "Completed") {
            count = count + 1;
          }
        });
        if (count === length) {
          this.Maintaskcompleted();
        }
      }
    })
  }

  Maintaskcompleted = () => {
    axios({
      method: "put",
      url: `${authApi}Tasklist/${this.state.tasklist}/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
      data: {
        date: this.state.maintask.date,
        task: this.state.maintask.task,
        assignto: this.state.maintask.assignto,
        priority: this.state.maintask.periority,
        startdate: this.state.maintask.startdate,
        enddate: this.state.maintask.enddate,
        status: "ReadyToBill",
        time: this.state.maintask.time,
        plantype: this.state.maintask.plantype,
        isdeleted: this.state.maintask.isdeleted,
        subclient: this.state.maintask.subclient,
        task_starttime: this.state.maintask.task_starttime,
        client: this.state.maintask.client,
        file_path: this.state.maintask.file_path,
        completed_date: this.state.maintask.completed_date,
        advanceamount: this.state.maintask.advanceamount,
        phonenumber: this.state.maintask.phonenumber,
        company: this.state.maintask.company,
        companyname: this.state.maintask.companyname
      }
    })
      .catch(err => consoleFunction("Task move to readytobill error", err.response.request.response))
  }


  HandleChangePriority = (event) => {
    this.setState({
      priority: event.target.value,
    })
  }

  render() {
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">
          Edit Subtask
        </div>
        <div className="Scroll-Content">
          <Row>
            <Col md="6">
              <label className="form-control-label">
                Subtask
              </label>
              <Input
                className="form-control-alternative"
                type="text"
                name="time"
                value={this.state.subtask}
                style={{ border: this.state.subtaskborder }}
                onChange={this.HandleChangesubtask} />
              {this.state.subtaskborder !== "1px solid #dee2e6" ? <h5 style={{ color: "#fb6340" }}>Please enter subtask</h5> : null}
            </Col>
            <Col md="6">
              <label className="form-control-label">
                AssignTo
              </label>
              <Input
                type="select"
                className="form-control-alternative"
                value={this.state.assignto}
                style={{ border: this.state.alreadyasignborder }}
                onChange={this.HandleChangeAssignTo}
              >
                {this.state.alreadysubtask === true ? <h5 style={{ color: "#fb6340" }}>Please enter subtask</h5> : null}
                <option>Select</option>
                {
                  this.state.items.map((obj, assign) =>
                    (<option key={assign} value={obj.name}>{obj.name}</option>))
                }
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <label className="form-control-label">
                Status
              </label>
              <Input
                type="select"
                className="form-control-alternative"
                style={{ border: "1px solid #dee2e6" }}
                defaultValue={this.state.status}
                onChange={this.HandleChangeStatus}
              >
                <option value="0">Select</option>
                <option value="ToDo">ToDo</option>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
              </Input>
            </Col>
            <Col md="6">
              <label className="form-control-label">
                Priority
              </label>
              <Input
                type="select"
                style={{ border: "1px solid #dee2e6" }}
                className="form-control-alternative"
                defaultValue={this.state.priority}
                onChange={this.HandleChangePriority}
              >
                <option value="0">Select</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </Input>
            </Col>
          </Row>
          {this.state.alreadysubtask === true ? <h5 style={{ textAlign: "center", color: "#fb6340" }}>Instance with the same assignee already exists</h5> : null}
          <button id="buttonsave" className="btn btn-dark" style={{ float: "right", marginRight: 15 }} onClick={this.FormValidation} disabled={this.state.isButtonDisabled}> Save </button>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    tt_client: state.teamtask_client,
    tt_company: state.teamtask_company,
    tt_assignto: state.teamtask_assignto,
    tt_plantype: state.teamtask_plantype,
    tt_status: state.teamtask_status,
    allusers:state.allusers,
    username:state.username
  }
}
export default connect(mapStateToProps, null)(Editsubtask);