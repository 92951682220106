import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, NavLink, Form, Table, Col } from "reactstrap";
import { Link } from "react-router-dom";
import TableContainerExpand from "../Filters/TableContainerExpand";
import TableContainerExpandTeam from "../Filters/TableContainerExpandTeam";
import { SelectColumnFilter } from "../Filters/filters";
import moment from "moment";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import "../Teamtask/Todotask.css";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
import ReactTooltip from "react-tooltip";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Header from "../../../components/Headers/SimpleHeader";

let lastdate = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() - 16
);
let Date1 = new Date(lastdate);
let sdToday = new Date();
const authApi = config.get("apiDomain");

function Tab_Filter_CompletedTeamtask(props) {
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm");

  useEffect(
    () => {
      const doFetch = async () => {
        let PresentDay = new Date();
        let LastPresentDay = new Date(
          PresentDay.getFullYear(),
          PresentDay.getMonth(),
          PresentDay.getDate() - 16
        );
        let D1 = moment(PresentDay).format("YYYY-MM-DD");
        let D2 = moment(LastPresentDay).format("YYYY-MM-DD");
        const response = await fetch(
          `${authApi}tasklistfilter/?isdeleted=0&status=Completed&startdate__gte=${D2}&startdate__lte=${D1}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const body = await response.json();
        if (body.length === 0) {
          setData(body);
          setLoading(false);
        } else {
          CalculateTaskTime(body.reverse());
          callback(body);
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function CalculateTaskTime(body) {
    body.forEach((e) => {
      e.time = calculateSubtaskTime(e);
    });
  }

  function calculateSubtaskTime(e) {
    let timeSub = "00:00";
    let timetotal = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0;

    e.subtask.forEach((st) => {
      if (st.isdeleted === "0") {
        st.time = calculateSecondLayerSubtaskTime(st);
        if (st.time === 0) {
          timeSub = "00:00";
        } else {
          stringtime = st.time;
          times = stringtime.split(":");
          mins = times[0] * 60 + +times[1];
          timetotal += mins;
          h = (timetotal / 60) | 0;
          m = timetotal % 60;
          function z(n) {
            return (n < 10 ? "0" : "") + n;
          }
          timeSub = z(h) + ":" + z(m);
        }
      }
    });
    return timeSub;
  }

  function calculateSecondLayerSubtaskTime(st2) {
    let total = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0,
      subTime = "00:00";
    st2.subtaskTime.forEach((d) => {
      stringtime = d.time;
      times = stringtime.split(":");
      mins = times[0] * 60 + +times[1];
      total += mins;
      h = (total / 60) | 0;
      m = total % 60;
      function z(n) {
        return (n < 10 ? "0" : "") + n;
      }
      subTime = z(h) + ":" + z(m);
    });
    return subTime;
  }
  function callback(body) {
    setData(body);
    setLoading(false);
  }

  function getFilterButton(event) {
    if (event === true) {
      setChecked(false);
      setBtnClass("btn btn-success btn-sm");
    } else {
      setChecked(true);
      setBtnClass("btn btn-danger btn-sm");
    }
  }

  function handleDateRangeCallback(event, picker) {
    Date1 = picker.startDate._d.toISOString();
    sdToday = picker.endDate._d.toISOString();
    setLoading(true);
    const sd = picker.startDate;
    const ed = picker.endDate;
    let a_sd = moment(sd).format("YYYY-MM-DD");
    let b_ed = moment(ed).format("YYYY-MM-DD");
    fetch(
      `${authApi}tasklistfilter/?isdeleted=0&status=Completed&startdate__gte=${a_sd}&startdate__lte=${b_ed}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((body) => {
        if (body.length === 0) {
          alert("No Task available for the Selected Filter.!");
          setData([]);
          setLoading(false);
        } else {
          CalculateTaskTime(body.reverse());
          callback(body);
        }
      });
  }

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : null
      )
    );
  }

  function DownloadData(json) {
    const items = json;
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]).filter(
      (field) =>
        field !== "id" &&
        field !== "subtask" &&
        field !== "isdeleted" &&
        field !== "task_starttime" &&
        field !== "time"
    );
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "TeamTask.csv";
    document.body.appendChild(element);
    element.click();
  }

  const renderRowSecondSubComponent = (row) => {
    return (
      <React.Fragment>
        <br />
        <h6 className="thead-light">Working Details :</h6>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th className="text-center">Date</th>
              <th className="text-center">StartTime</th>
              <th className="text-center">EndTime</th>
              <th className="text-center">Time</th>
              <th className="text-center">Comments</th>
            </tr>
          </thead>
          {row.original.subtaskTime.map((r, id) => {
            return (
              <tbody key={id}>
                <tr>
                  <td className="text-center">{r.date}</td>
                  <td className="text-center">{r.start_time}</td>
                  <td className="text-center">{r.endtime}</td>
                  <td className="text-center">{r.time}</td>
                  <td className="text-center">{r.comments}</td>
                </tr>
              </tbody>
            );
          })}
        </Table>
        <br />
      </React.Fragment>
    );
  };

  const columnSecondLayer = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Subtask",
        accessor: "subtask",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Assignto",
        accessor: "assignto",
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Priority",
        accessor: "priority",
      },
    ],
    // eslint-disable-next-line
    []
  );

  const renderRowSubComponent = (row) => {
    const rowdata = row.original.subtask.filter(function (item) {
      return item.isdeleted !== "1";
    });
    return (
      <React.Fragment>
        <br />
        <h5 className="thead-light">Subtask Details :</h5>
        <TableContainerExpandTeam
          columns={columnSecondLayer}
          data={rowdata}
          renderRowSubComponent={renderRowSecondSubComponent}
        />
        <br />
      </React.Fragment>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Client Name",
        accessor: "clientname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Company Name",
        accessor: "companyname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Plan Type",
        accessor: "plantype",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Task",
        accessor: "task",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "AssignTo",
        accessor: "assignto",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "Completed Date",
        accessor: "completed_date",
        disableSortBy: true,
      },
      {
        Header: "Projectname",
        accessor: "projectname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <React.Fragment>
      <Header />
      <Container className="mt--7" fluid>
        <br />
        <Row>
          <Col md="0.5">
            <ReactTooltip
              id="teamTask-Tip"
              place="right"
              type="dark"
              effect="solid"
            >
              Back to Team Task
            </ReactTooltip>
            <NavLink style={{ marginTop: 10 }} to="/admin/teamtask" tag={Link}>
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                data-tip
                data-for="teamTask-Tip"
              >
                <i className="fa fa-arrow-left"></i>
              </button>
            </NavLink>
          </Col>
          <Col md="3">
            <NavLink style={{ marginTop: 10, marginLeft: -15 }}>
              <DateRangePicker
                autoUpdateInput={false}
                onApply={handleDateRangeCallback}
                initialSettings={{
                  locale: {
                    format: 'DD/MM/YYYY'
                  }
                }}
              >
                <button
                  className="form-control-alternative"
                  style={{
                    height: "40px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "2px 2px 2px",
                    backgroundColor: "white",
                    border: "none",
                  }}
                >
                  <i
                    className="fa fa-calendar-o"
                    style={{ paddingRight: 15 }}
                    aria-hidden="true"
                  ></i>
                  {moment(Date1).format("DD/MM/YYYY")} -{" "}
                  {moment(sdToday).format("DD/MM/YYYY")}
                </button>
              </DateRangePicker>
            </NavLink>
          </Col>
          <Col md="0.5"></Col>
          <Col md="6.5">
            <div
              className="invoicegenerate"
              style={{ position: "absolute", right: "20px", marginTop: 13 }}
            >
              <button
                className={btnClass}
                style={{ width: "135%", height: 40 }}
                onClick={(e) => getFilterButton(checked)}
              >
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
            <Form
              className="searchForm"
              style={{ marginTop: 12, right: "95px" }}
            >
              <input
                className="searchBox "
                type="search"
                placeholder="Search"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </Form>
          </Col>
        </Row>
        <br />
        {loading === true ? (
          <div>
            
            <ConPreLoader />
          </div>
        ) : (
          <Row>
            <div className=" col">
              <ReactTooltip place="top" type="dark" effect="float" />
              <TableContainerExpand
                columns={columns}
                data={search(data)}
                renderRowSubComponent={renderRowSubComponent}
                checkedToggle={checked}
              />
            </div>
          </Row>
        )}
        <br />
        <button
          className="Export btn-info"
          id="download"
          style={{ marginRight: "5px" }}
          onClick={() => DownloadData(data)}
        >
          Export
        </button>
        <br />
        <br />
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(Tab_Filter_CompletedTeamtask);
